<script lang="ts">
export default {
  name: 'RetailAmlModal',
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { type PropType } from 'vue';

import { modalVariant, type ModalVariant } from '@exchange/libs/modals/src';

defineProps({
  variant: { type: String as PropType<ModalVariant>, default: modalVariant.dark },
});

const emit = defineEmits<{
  (e: 'go-to-form'): void;
}>();
</script>

<template>
  <x-modal
    class="retail-aml-modal"
    :can-close="false"
    :wide="true"
    :variant="variant"
    :title="$t('ui.retailAmlModal.title')"
  >
    <template #content>
      <p class="retail-aml-modal__message">{{ $t('ui.retailAmlModal.message') }}</p>
    </template>
    <template #buttons>
      <x-button
        variant="primary"
        @click="emit('go-to-form')"
      >
        {{ $t('ui.retailAmlModal.goToForm') }}
      </x-button>
    </template>
  </x-modal>
</template>

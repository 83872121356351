<script lang="ts" setup>
import InfoModal from '@exchange/libs/modals/src/lib/common/InfoModal.vue';

const emit = defineEmits<{
  (e: 'contact-support'): void;
}>();
</script>

<template>
  <info-modal
    testid="uk-certification-not-eligible-modal"
    variant="dark"
    :no-icon="true"
    action="ui.ukSelfCertificationNotEligible.primaryAction"
    action-test-id="contact-support"
    content-test-id="uk-certification-not-eligible-modal-content"
    title="ui.ukSelfCertificationNotEligible.title"
    :message="$t('ui.ukSelfCertificationNotEligible.message')"
    @action-clicked="emit('contact-support')"
  />
</template>

import { jws } from 'jsrsasign';

import { logger } from '@exchange/libs/utils/simple-logger/src';

// JWT name reference https://tools.ietf.org/html/rfc7519#section-4.1
export interface AccessToken {
  aud: string[];
  sub: string;
  scp: string[];
  nbf: number;
  azp: string;
  iss: string;
  exp: number;
  iat: number;
  jti: string;
  did: string;
}

export interface IdToken {
  // eslint-disable-next-line camelcase
  at_hash: string;
  aud: string;
  azp: string;
  // eslint-disable-next-line camelcase
  bitpanda_id: string;
  currency: string;
  // eslint-disable-next-line camelcase
  early_adopter: boolean;
  // eslint-disable-next-line camelcase
  first_login: number;
  exp: number;
  iat: number;
  iss: string;
  language: string;
  nbf: number;
  sub: string;
}

export function parse(jwt: string) {
  const token = jws.JWS.parse(jwt);

  return {
    header: token.headerObj,
    payload: token.payloadObj,
  };
}

export function parseJwt<T extends object = { [k: string]: string | number }>(jwt: string): T {
  try {
    const { payload } = parse(jwt);

    return payload;
  } catch (e) {
    logger.error('Parsing JWT failed', e);
    throw e;
  }
}

export const getJWTPayloadExpirationTime = (jwt: string) => {
  const payload = parseJwt<AccessToken>(jwt);

  return payload.exp * 1000;
};

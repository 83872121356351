import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';

import { logger, nonProdConsoleInfo } from '@exchange/libs/utils/simple-logger/src';

export const secureStorageGet: <R>(key: string, osp?: (item: { value: string }) => R | string, ofp?: (e) => R | string) => Promise<R | string> = async (
  key: string,
  onSuccessProcess = (item) => item.value,
  onFailureProcess = (error) => {
    nonProdConsoleInfo(`Failed to get ${key}: `, error);

    return '';
  },
) => {
  try {
    const v = await SecureStoragePlugin.get({ key });

    return onSuccessProcess(v);
  } catch (error) {
    return onFailureProcess(error);
  }
};

export const secureStorageReset: (key: string) => Promise<{ value: boolean } | void> = async (key) => {
  try {
    await SecureStoragePlugin.remove({ key });
  } catch (error) {
    nonProdConsoleInfo(`Failed to remove ${key}: `, error);
  }
};

export const secureStorageSet: (key: string, value: string) => Promise<{ value: boolean } | void> = async (key, value) => {
  try {
    await SecureStoragePlugin.set({ key, value });
  } catch (error) {
    logger.warn(`Failed to set ${key}-${value}: ${error}, Resetting and retrying...`);
    /** https://github.com/martinkasa/capacitor-secure-storage-plugin/issues/54#issuecomment-1185446767 */
    await secureStorageReset(key);
    await secureStorageSet(key, value);
  }
};

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import transform from 'lodash/transform';

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object that represents the diff
 */
export const difference = (object, base) => {
  function changes(obj, bs) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return transform(obj, (result: any, value, key) => {
      if (!isEqual(value, bs[key])) {
        // eslint-disable-next-line no-param-reassign
        result[key] = isObject(value) && isObject(bs[key]) ? changes(value, bs[key]) : value;
      }
    });
  }

  return changes(object, base);
};

export const noDifference = (object, base) => {
  if (!object || !base) {
    return false;
  }

  return isEmpty(difference(object, base)) && isEmpty(difference(base, object));
};

export default difference;

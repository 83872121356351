import { useTheme } from '@1trading/design-system';
import { ref, watch } from 'vue';

import persistanceHelper from '@exchange/helpers/persistance-helper';
import { themingInstance } from '@exchange/libs/utils/theming/src';

const localeLocalStorageKey = 'theme';
const supportedValues = ['dark', 'light'];
const defaultValue = supportedValues[0] as string;

const useUiTheme = () => {
  const theme = useTheme();

  const currentTheme = ref(persistanceHelper.getObjFromJSON(localeLocalStorageKey, defaultValue, supportedValues) ?? defaultValue);

  watch(
    currentTheme,
    (value) => {
      if (value !== theme.global.name.value) {
        theme.global.name.value = value;
        persistanceHelper.localstorageSet(localeLocalStorageKey, value);
      }
    },
    {
      immediate: true,
    },
  );

  const toggleTheme = () => {
    const newTheme = currentTheme.value === 'light' ? 'dark' : 'light';
    currentTheme.value = newTheme;
    themingInstance.resetCache();
    window.location.reload();
  };

  // @ts-ignore let it be
  window.toggleTheme = toggleTheme;

  return {
    toggleTheme,
    currentTheme,
  };
};

export default useUiTheme;

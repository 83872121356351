import { difference } from 'lodash/fp';
import { computed, reactive } from 'vue';

import getBalance from '@exchange/helpers/balance';
import BigNumber from '@exchange/helpers/bignumber';
import persistanceHelper from '@exchange/helpers/persistance-helper';
import type { BalancesSnapshot } from '@exchange/libs/account/service/src';
import { FundingUpdateType, type WSSFundingUpdate } from '@exchange/libs/account/service/src/lib/wss-account-messages';
import { authService } from '@exchange/libs/auth/service/src';
import { marketService } from '@exchange/libs/market/service/src';
import PublicRest from '@exchange/libs/rest-api/public-api';
import { toastManagerInstance, DepositConfirmedToast } from '@exchange/libs/toasts/src';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';
import { CurrencyModel, currencyService } from '@exchange/libs/utils/currency/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

import BalanceModel, { BalanceUpdate } from './balance-model';
import { getSum } from './helpers';

const hideLowCryptoValuesStorageKey = 'HIDE_LOW_BALANCES';
const balanceDisplayTypeStorageKey = 'BALANCE_DISPLAY_TIPE';

export enum BETransactionTypes {
  TRANSFER = 'transfer', // Withdraw to / Deposit from Bitpanda
  WITHDRAWAL = 'withdrawal', // Withdrawal outside
  DEPOSIT = 'deposit', // Deposit from outside
}

export enum BalanceDisplayType {
  HIDDEN = 'hidden',
  FIAT = 'fiat',
  CRYPTO = 'crypto',
}

interface BalanceState {
  balances: KeyMap<BalanceModel>;
  balanceSnapshotReceived: boolean;
  balanceDisplayType: BalanceDisplayType;
  hideLowCryptoValues: boolean;
}

export class Balance {
  private readonly logPrefix = 'BalanceService:';

  private getDefaultState = () => ({
    balances: {},
    balanceSnapshotReceived: false,
    balanceDisplayType: persistanceHelper.getObjFromJSON(balanceDisplayTypeStorageKey, BalanceDisplayType.FIAT),
    hideLowCryptoValues: persistanceHelper.getObjFromJSON(hideLowCryptoValuesStorageKey, false),
  });

  private readonly state = reactive<BalanceState>(this.getDefaultState());

  public balances = computed(() => this.state.balances);

  public balanceSnapshotReceived = computed(() => this.state.balanceSnapshotReceived);

  public shouldMaskBalance = computed(() => this.state.balanceDisplayType === BalanceDisplayType.HIDDEN);

  public hideLowCryptoValues = computed(() => this.state.hideLowCryptoValues);

  public list = computed<Array<BalanceModel>>(() => {
    const currencies = currencyService.currencies.value;

    Object.values(this.state.balances).forEach((balance) => {
      balance.calculateAndUpdateTotalValues(currencies?.[balance.currencyCode]);
    });

    const tvc = Object.values(this.state.balances).reduce((acc, balance) => getSum(balance.totalCryptoValue, acc), new BigNumber(0));

    const extendedList = Object.values(this.state.balances).filter((balance) => {
      const portfolioSize = balance.getPortfolioSize(tvc, balance.totalCryptoValue);

      balance.updatePortfolioSize(portfolioSize);

      return !balance.total.eq(0) || marketService.doesMarketExistForCurrency(balance.currencyCode);
    });

    return extendedList;
  });

  public totalValues = computed(() => {
    const startPoint = new BigNumber(0);

    return this.list.value.reduce(
      (acc, balance) => {
        const totalCryptoValue = getSum(balance.totalCryptoValue, acc.totalCryptoValue);
        const totalFiatValue = getSum(balance.totalFiatValue, acc.totalFiatValue);
        const lockedFiatValue = getSum(balance.lockedFiatValue, acc.lockedFiatValue);

        return {
          totalCryptoValue,
          totalFiatValue,
          lockedFiatValue,
        };
      },
      {
        totalCryptoValue: startPoint,
        totalFiatValue: startPoint,
        lockedFiatValue: startPoint,
      },
    );
  });

  public totalCryptoValue = computed(() => getBalance({ v: this.totalValues.value.totalCryptoValue, precision: CONSTANTS.PRECISION.DEFAULT_CRYPTO }));

  public totalFiatValue = computed(() => getBalance({ v: this.totalValues.value.totalFiatValue, precision: CONSTANTS.PRECISION.DEFAULT_FIAT }));

  public totalLockedFiatValue = computed(() => this.totalValues.value.lockedFiatValue);

  public balanceLoadingUntil = computed<boolean>(() => {
    if (!authService.hasBeenAuthenticated) {
      return false;
    }

    return !this.balanceSnapshotReceived.value;
  });

  public static getHasFunds = (totalFiatValue: BigNumber, totalCryptoValue: BigNumber) => {
    const fiat = totalFiatValue.gt(0);
    const crypto = totalCryptoValue.gt(0);

    return fiat || crypto;
  };

  public setBalance = ({ balance }: { balance: BalanceModel }) => {
    this.state.balances[balance.currencyCode] = balance;
  };

  public updateBalancesSnapshotReceived = (value: boolean) => {
    this.state.balanceSnapshotReceived = value;
  };

  public setSnapshot = ({ balances }: BalancesSnapshot) => {
    this.reset();

    balances.forEach((balance) => {
      this.setBalance({ balance: new BalanceModel(balance) });
    });

    this.updateBalancesSnapshotReceived(true);
  };

  public addBalanceCurrencies = (currencies: Dictionary<CurrencyModel>) => {
    const currencyCodesToAdd = difference(Object.keys(currencies), Object.keys(this.balances.value));

    Object.keys(currencies).forEach((currencyCode) => {
      if (currencyCodesToAdd.includes(currencyCode)) {
        this.setBalance({ balance: new BalanceModel(currencyCode) });
      }
    });
  };

  public updateBalance = ({ update, sequence }: { update: Array<BalanceUpdate>; sequence: number }) => {
    if (sequence === undefined) {
      logger.error(`${this.logPrefix} got no sequence on balance update ${update.map((e) => e.currency_code)}`);
    }

    update.forEach((balance: BalanceUpdate) => {
      const balanceToUpdate = this.balances.value[balance.currency_code];

      if (!balanceToUpdate) {
        logger.error(`${this.logPrefix} received update for unknown balance ${balance.currency_code}`);

        return;
      }

      const b = new BalanceModel({
        available: balance.amount_available,
        locked: balance.amount_locked,
        currency_code: balanceToUpdate.currencyCode,
        sequence: balanceToUpdate.sequence,
      });

      this.setBalance({ balance: b });
    });
  };

  public setBalanceDisplayType = (value: BalanceDisplayType) => {
    this.state.balanceDisplayType = value;
    persistanceHelper.localstorageSet(balanceDisplayTypeStorageKey, value);
  };

  public setHideLowCryptoValues = (value: boolean) => {
    this.state.hideLowCryptoValues = value;
    persistanceHelper.localstorageSet(hideLowCryptoValuesStorageKey, value);
  };

  public reset = () => {
    const defaults = this.getDefaultState();

    Object.keys(this.state.balances).forEach((balance) => {
      this.setBalance({ balance: new BalanceModel(balance) });
    });

    this.state.balanceSnapshotReceived = defaults.balanceSnapshotReceived;
  };

  public processFundingUpdate = async (event: WSSFundingUpdate) => {
    switch (event.type) {
      case FundingUpdateType.FUNDS_DEPOSITED: {
        this.updateBalance({ update: event.balances, sequence: 0 });

        const currencyCode = event.currency_code;
        const precision = currencyService.currencies.value?.[currencyCode]?.precision ?? CONSTANTS.PRECISION.DEFAULT_CRYPTO;

        toastManagerInstance.addToast({
          content: DepositConfirmedToast,
          props: {
            amount: event.amount,
            currencyCode,
            precision,
          },
        });
        break;
      }
      case FundingUpdateType.FUNDS_WITHDRAWN: {
        this.updateBalance({ update: event.balances, sequence: 0 });
        break;
      }
      default: {
        logger.error(`${this.logPrefix} Unknown Funding update: ${event.type}`);
        break;
      }
    }
  };

  public getBalancesStat = async (period: 'HOUR' | 'DAY' | 'WEEK' | 'MONTH' | 'YEAR') => {
    const currency = currencyService.defaultFiatCurrency.value;

    try {
      return await PublicRest.Balances.get(period, currency);
    } catch (e) {
      logger.error(`${this.logPrefix} fetching balances stat data for ${period}  ${currency} failed`, e);
      return undefined;
    }
  };
}

const balanceService = new Balance();

export default balanceService;

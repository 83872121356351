import { CONSTANTS } from '@exchange/libs/utils/constants/src';

export default function numberFormat({
  number,
  precision,
  locale = 'en',
  grouping = false,
  style = 'decimal',
  currency = CONSTANTS.DEFAULT_FOR_CURRENCY_FORMATTING,
  currencyDisplay = 'code',
}: {
  number: number;
  precision: number;
  locale: string;
  grouping?: boolean;
  style?: string;
  currency?: string;
  currencyDisplay?: string;
}): string {
  // TODO When formatting large numbers of numbers, it is better to create a NumberFormat
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString#Performance
  return number.toLocaleString(CONSTANTS.LOCALE_MAP[locale] ?? CONSTANTS.LOCALE_MAP.en, {
    style,
    currency,
    currencyDisplay,
    useGrouping: grouping,
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });
}

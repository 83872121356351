/* eslint-disable camelcase */
export enum GranularityUnits {
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
}

export interface Granularity {
  unit: GranularityUnits;
  period: number;
}

export interface BECandlestick {
  last_sequence: number;
  instrument_code: string;
  granularity: Granularity;
  high: string;
  low: string;
  open: string;
  close: string;
  volume: string;
  time: string;
  start_time: string;
}

export interface BECandlesticksResponse {
  status: string;
  next_bar: string;
  candlesticks: Array<BECandlestick>;
}

export const GranularityUnitsToSecondsMap = {
  MINUTES: 60,
  HOURS: 60 * 60,
  DAYS: 60 * 60 * 24,
  WEEKS: 60 * 60 * 24 * 7,
  MONTHS: 60 * 60 * 24 * 7 * 31, // its for worst case months
};

export function granularityToMilliseconds(granularity: Granularity) {
  const minute = 1000 * 60;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;
  const month = day * 30;

  switch (granularity.unit) {
    case GranularityUnits.MINUTES:
      return granularity.period * minute;
    case GranularityUnits.HOURS:
      return granularity.period * hour;
    case GranularityUnits.DAYS:
      return granularity.period * day;
    case GranularityUnits.WEEKS:
      return granularity.period * week;
    case GranularityUnits.MONTHS:
      return granularity.period * month;
    default:
      throw new Error('unknown timeframe');
  }
}

/**
 * Maps a TradingView Resolution to the Exchange APIs Granularity-Format
 *
 * Tradingview uses Seconds, Minutes, Days, Weeks and Mouths as units
 * https://github.com/tradingview/charting_library/wiki/Resolution
 * 1S => 1 Second
 * 2S => 2 Seconds
 * 100S => 1000 Seconds
 *
 * 1 => 1 Minute
 * 2 => 2 Minutes
 * 100 => 100 Minutes
 * Minutes dont use a unit in Tradingview!
 *
 * 1D => 1 Day
 * 1W => 1 Week
 * 1M => 1 Month
 * 12M => 1 Year
 *
 * The Exchange API uses the following format:
 *  {
 *     unit: 'MINUTES' || 'HOURS' || 'DAYS' || 'WEEKS' || 'MONTHS',
 *     period: number
 *  }
 * @param {*} resolution a Tradingview Resolution
 */
export function mapResolution(resolution: string): Granularity {
  let tvPeriod: number;
  let tvUnit: string;
  const lastChar = resolution.substr(-1);

  if (Number.isNaN(+lastChar)) {
    tvPeriod = +resolution.substr(0, 1);
    tvUnit = lastChar;
  } else {
    tvPeriod = +resolution;
    tvUnit = 'Min';
  }

  const getGranularity = (unit: string, period: number) => {
    switch (unit) {
      case 'S':
        return {
          unit: GranularityUnits.MINUTES,
          period: period / 60,
        };
      case 'Min':
        return period >= 60
          ? {
              unit: GranularityUnits.HOURS,
              period: period / 60,
            }
          : {
              unit: GranularityUnits.MINUTES,
              period,
            };
      case 'D':
        return {
          unit: GranularityUnits.DAYS,
          period,
        };
      case 'W':
        return {
          unit: GranularityUnits.WEEKS,
          period,
        };
      case 'M':
        return {
          unit: GranularityUnits.MONTHS,
          period,
        };
      default:
        return {
          unit: GranularityUnits.MINUTES,
          period: period / 60,
        };
    }
  };

  return getGranularity(tvUnit, tvPeriod);
}

export type ProSubsIdentifier = string;
export interface CandlestickSubscriber {
  instrument_code: string;
  time_granularity: Granularity;
}

export const candlesticksSubsIdentifier = {
  separator: '/',
  getIdentifier: (subscriber: CandlestickSubscriber): ProSubsIdentifier =>
    // eslint-disable-next-line max-len
    `${subscriber.instrument_code}${candlesticksSubsIdentifier.separator}${subscriber.time_granularity.unit}${candlesticksSubsIdentifier.separator}${subscriber.time_granularity.period}`,
};

export default class Candlestick implements TradingView.Bar {
  last_sequence!: number;

  instrument_code!: string;

  granularity!: Granularity;

  high!: number;

  low!: number;

  open!: number;

  close!: number;

  volume!: number;

  time!: number;

  constructor(fields: BECandlestick) {
    this.last_sequence = fields.last_sequence;
    this.instrument_code = fields.instrument_code;
    this.granularity = fields.granularity;
    this.high = Number(fields.high);
    this.low = Number(fields.low);
    this.open = Number(fields.open);
    this.close = Number(fields.close);
    this.volume = Number(fields.volume);
    this.time = new Date(fields.start_time).getTime();
  }
}

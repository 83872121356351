import type { OAuth2AuthenticateOptions } from '@byteowls/capacitor-oauth2';

import { getAppId, platformOptions, getLogoutUrl } from './client-config';

const settings: OAuth2AuthenticateOptions = {
  appId: getAppId(),
  authorizationBaseUrl: `${process.env.VUE_APP_OAUTH_URL}/authorize`,
  scope: 'openid email',
  responseType: 'code',
  accessTokenEndpoint: `${process.env.VUE_APP_OAUTH_URL}/token`,
  logsEnabled: true,
  android: {
    pkceEnabled: true,
    logsEnabled: true,
    redirectUrl: platformOptions.android.redirectUrl,
  },
  ios: {
    pkceEnabled: true,
    logsEnabled: true,
    redirectUrl: platformOptions.ios.redirectUrl,
  },
};

export default settings;

export { getLogoutUrl };

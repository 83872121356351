import { shallowRef } from 'vue';

import UkSelfCertificationModal from '@exchange/libs/account/ui/src/libs/UkSelfCertificationModal.vue';
import UkSelfCertificationNotEligibleModal from '@exchange/libs/account/ui/src/libs/UkSelfCertificationNotEligibleModal.vue';
import { modalService } from '@exchange/libs/modals/src';
import PublicRest from '@exchange/libs/rest-api/public-api';
import { capIsNativePlatform } from '@exchange/libs/utils/capacitor/src';
import handleNativeGoTo from '@exchange/libs/utils/capacitor/src/lib/handle-native-go-to';
import { launchdarkly } from '@exchange/libs/utils/launchdarkly/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

export type SelfCertificationStatus = 'NOT_REQUIRED' | 'INCOMPLETE' | 'NOT_ELIGIBLE' | 'ELIGIBLE';

const selfCertificationStatusesActionRequired: SelfCertificationStatus[] = ['INCOMPLETE', 'NOT_ELIGIBLE'];

export const isSelfCertificationActionRequired = (status: SelfCertificationStatus) => selfCertificationStatusesActionRequired.includes(status);

export const getPhase = (start: string) => {
  const dayX = new Date(start).getTime();
  const currentDay = new Date().getTime();

  return currentDay < dayX ? 0 : 1;
};

const getUserQuestionnaireInfo = async (questionnaireKey: string) => {
  try {
    const data = await PublicRest.Customer.Questionnaire.get(questionnaireKey);

    return data;
  } catch (e) {
    if ((e as { status: number }).status === 404) {
      return undefined;
    }

    throw e;
  }
};

const handleUKCustomer = async ({ residency, isoCode }: { isoCode: string; residency: string }, pid: string, nativeCallback: () => void): Promise<SelfCertificationStatus> => {
  logger.info(`Residency: ${residency}; geolocation: ${isoCode}`);

  if (residency !== 'GB') {
    return 'NOT_REQUIRED';
  }

  const data = await getUserQuestionnaireInfo('uk-fca');
  const phase = getPhase(launchdarkly.flags['uk-fca'].value.start);

  if (!data) {
    const mKey = modalService.show(
      shallowRef(UkSelfCertificationModal),
      {
        phase,
      },
      {
        close: () => modalService.hide(mKey),
        'go-to-form': async () => {
          const fcaQuestionsUrl = `${process.env.VUE_APP_ACCOUNTS_URL}/self-certification/${pid}`;

          if (capIsNativePlatform()) {
            handleNativeGoTo(fcaQuestionsUrl, nativeCallback);
            modalService.hide(mKey);
          } else {
            window.location.href = fcaQuestionsUrl;
          }
        },
      },
      { canClose: false, centered: true, variant: 'dark' },
    );

    return 'INCOMPLETE';
  }

  const [firstAnswer] = data.answers || [];

  if (phase === 1 && firstAnswer?.answer === 'none') {
    const mKey = modalService.show(
      shallowRef(UkSelfCertificationNotEligibleModal),
      {},
      {
        'contact-support': () => {
          const supportUrl = 'https://support.onetrading.com/requests/new';

          if (capIsNativePlatform()) {
            handleNativeGoTo(supportUrl, nativeCallback);
            modalService.hide(mKey);
          } else {
            window.location.href = supportUrl;
          }
        },
      },
      { canClose: false, centered: true, variant: 'light' },
    );

    return 'NOT_ELIGIBLE';
  }

  return 'ELIGIBLE';
};

export default handleUKCustomer;

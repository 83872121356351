import { shallowRef } from 'vue';

import { modalService } from '@exchange/libs/modals/src';
import { toastManagerInstance, SimpleToast } from '@exchange/libs/toasts/src';
import { capIsNativePlatform } from '@exchange/libs/utils/capacitor/src';
import handleNativeGoTo from '@exchange/libs/utils/capacitor/src/lib/handle-native-go-to';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';

import RetailAmlModal from '../../../ui/src/libs/RetailAmlModal.vue';

export type AmlStatus = 'COMPLETE' | 'INCOMPLETE';

export const isAmlStatusActionRequired = (status: AmlStatus) => status === 'INCOMPLETE';

const handleMissingRetailAml = (amlDate: string | undefined | null, nativeCallback: () => void): AmlStatus => {
  if (amlDate) {
    return 'COMPLETE';
  }

  const mKey = modalService.show(
    shallowRef(RetailAmlModal),
    {},
    {
      'go-to-form': () => {
        const amlUrl = `${process.env.VUE_APP_ACCOUNTS_URL}${CONSTANTS.AML_PATHNAME}`;

        if (capIsNativePlatform()) {
          handleNativeGoTo(amlUrl, nativeCallback, () => {
            toastManagerInstance.addToast({
              content: SimpleToast,
              props: {
                variant: 'failed',
                title: 'fundamentals.toasts.failed',
                message: 'fundamentals.error.text',
              },
            });
          });
          modalService.hide(mKey);
        } else {
          window.location.href = amlUrl;
        }
      },
    },
    { canClose: false, variant: 'dark' },
  );

  return 'INCOMPLETE';
};

export default handleMissingRetailAml;

<script lang="ts" setup>
import { toastManagerInstance, SimpleToast } from '@exchange/libs/toasts/src';

const props = defineProps({
  altMessage: { type: String, required: false },
  text: { type: [String, Number], required: true },
});

const doOnSuccess = () => {
  toastManagerInstance.addToast({
    content: SimpleToast,
    props: {
      variant: 'default',
      title: 'fundamentals.copy.title',
      message: props.altMessage ?? 'fundamentals.copy.message.pid',
    },
  });
};

const doOnError = () => {
  toastManagerInstance.addToast({
    content: SimpleToast,
    props: {
      variant: 'failed',
      title: 'fundamentals.copy.title',
      message: 'fundamentals.copy.failed',
    },
  });
};
</script>

<template>
  <ot-pid
    class="x-pid"
    :text="text"
    :on-success="doOnSuccess"
    :on-error="doOnError"
  />
</template>

export const appEnv = process.env.VUE_APP_ENV?.toLowerCase();
export const isProduction = process.env.VUE_APP_ENV === 'Production';
export const isStaging = process.env.VUE_APP_ENV === 'Staging';
export const isFeatureBranch = process.env.VUE_APP_IS_FEATURE_BRANCH;

export const appVersion = process.env.VUE_APP_RELEASE as string;
export const appRevision = process.env.VUE_APP_REVISION as string;

export const getAppVersion = () => {
  if (isFeatureBranch) return appRevision;

  return appVersion;
};

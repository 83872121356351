<script lang="ts" setup>
import { VThemeProvider } from '@1trading/design-system';
import { useEventListener } from '@vueuse/core';
import { computed, onBeforeUnmount, onMounted, provide, reactive, readonly, ref, type Ref, toRefs, watch } from 'vue';
import { useRoute } from 'vue-router';

import isTouch from '@exchange/helpers/is-touch';
import { balanceService } from '@exchange/libs/balances/service/src';
import useGlobalConfirmation from '@exchange/libs/composables/shared/src/lib/global-confirmation-modals/useGlobalConfirmationModals';
import useAppTitle from '@exchange/libs/composables/shared/src/lib/useAppTitle';
import useMQBreakpoints from '@exchange/libs/composables/shared/src/lib/useMQBreakpoints';
import useResize from '@exchange/libs/composables/shared/src/lib/useResize';
import useColorTheme from '@exchange/libs/composables/shared/src/lib/useTheme';
import { marketService } from '@exchange/libs/market/service/src';
import { modalService } from '@exchange/libs/modals/src';
import ModalsContainer from '@exchange/libs/modals/src/lib/ModalsContainer.vue';
import RibbonSection from '@exchange/libs/ribbons/RibbonSection.vue';
import { toastManagerInstance } from '@exchange/libs/toasts/src';
import ToastsContainer from '@exchange/libs/toasts/src/lib/ToastsContainer.vue';
import { capGetPlatform, capIsNativePlatform } from '@exchange/libs/utils/capacitor/src';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';
import { cookieService } from '@exchange/libs/utils/cookie/src';
import { utcToLocal, langsInfoService } from '@exchange/libs/utils/langs-info/src';
import { SWAppUpdateChecker } from '@exchange/libs/utils/service-worker-update-checker/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

provide('utcToLocal', utcToLocal);

const route = useRoute();
const { currentMQName, isXS } = useMQBreakpoints();
const { currentTheme } = useColorTheme();

const app = ref<HTMLElement>();
const modalsContainer = ref();
const toastsContainer = ref();
const cookieBanner = ref<HTMLElement>();

const { language } = langsInfoService;
const { shouldMaskBalance } = balanceService;
const { selectedMarketIdSpot } = marketService;

useGlobalConfirmation(route);

const useRouteTransition = (refElem: Ref<HTMLElement | undefined>) => {
  const getRunningTransitionEvent = (v: boolean) => {
    const event = new CustomEvent(CONSTANTS.ROOT_ROUTE_TRANSITION_EVENT_NAME, {
      detail: {
        doing: v,
      },
    });

    return event;
  };

  const onRouteTransitionEnterStarted = () => {
    refElem.value?.dispatchEvent(getRunningTransitionEvent(true));
  };

  const onRouteTransitionEnterFinished = () => {
    refElem.value?.dispatchEvent(getRunningTransitionEvent(false));
  };

  return {
    onRouteTransitionEnterStarted,
    onRouteTransitionEnterFinished,
  };
};

const useBackButton = () => {
  const backButtonTriggered = ref(false);
  const toggleBackBtn = (e) => {
    backButtonTriggered.value = e.detail.goBack;
  };

  const startListeningForBackButtonTrigger = () => {
    window.addEventListener('back-button-triggered', toggleBackBtn, true);
  };

  const stopListeningForBackButtonTrigger = () => {
    window.removeEventListener('back-button-triggered', toggleBackBtn, true);
  };

  return {
    backButtonTriggered,
    toggleBackBtn,
    startListeningForBackButtonTrigger,
    stopListeningForBackButtonTrigger,
  };
};

const setPlatformClass = () => {
  document.getElementsByTagName('html')[0]?.setAttribute('class', capIsNativePlatform() ? 'is-native' : 'is-web');
};

useAppTitle(selectedMarketIdSpot);

const { appUpdateRequested, startListeningForAppUpdate, stopListeningForAppUpdate } = SWAppUpdateChecker;
const { debouncedSetCssCustomVarVh, startListeningForResize, stopListeningForResize } = useResize();
const { backButtonTriggered, toggleBackBtn, startListeningForBackButtonTrigger, stopListeningForBackButtonTrigger } = useBackButton();
const { onRouteTransitionEnterStarted, onRouteTransitionEnterFinished } = useRouteTransition(app);

provide('balanceMasked', readonly(shouldMaskBalance));

const state = reactive({
  name: route.name,
  moveBack: false,
  routeAnimationDisabled: false,
  isNative: capIsNativePlatform(),
  isTouch: isTouch(),
});

const { moveBack, routeAnimationDisabled, isNative } = toRefs(state);

const showRibbonSection = computed(() => {
  const okForSpot = state.name === 'spot' && !isXS.value;
  const okForPortfolio = state.name === 'portfolio';

  return okForSpot || okForPortfolio;
});
const appClasses = computed(() => [
  currentMQName.value,
  state.isTouch ? 'touch-screen' : 'not-touch-screen',
  {
    'is-native-app': state.isNative,
  },
]);

const handleNativeTracking = () => {
  const platform = capGetPlatform();

  cookieService.handleNativeTracking(platform);
};

watch(route, (to, from) => {
  state.name = to?.name;
  state.moveBack = Number(to?.meta?.level) < Number(from?.meta?.level);
  // disable slide animation for unlock and setup pages
  state.routeAnimationDisabled = Boolean(to?.meta?.routeAnimationDisabled);
  // when rotating device to horizontal and then back to portrait - the bottom nav bar disappears on safari ios,
  // and then after first iteration with webapp - it appears again, but 'resize' event doesn't fire,
  // that is why I thought it would be a good idea to check the size on route change
  debouncedSetCssCustomVarVh();
});

useEventListener(cookieBanner, 'acceptCookies', cookieService.cookieBanner.onAcceptCookies.bind(cookieService));

onMounted(() => {
  startListeningForAppUpdate();
  startListeningForBackButtonTrigger();

  if (!modalsContainer.value) {
    logger.error('Modals container was not mounted!');
  } else {
    modalService.initialize(modalsContainer.value);
  }

  if (!toastsContainer.value) {
    logger.error('Toasts container was not mounted!');
  } else {
    toastManagerInstance.initialize(toastsContainer.value);
  }
});

onBeforeUnmount(() => {
  stopListeningForAppUpdate();
  stopListeningForBackButtonTrigger();
  stopListeningForResize();
});

handleNativeTracking();
setPlatformClass();
startListeningForResize();
</script>

<template>
  <div
    id="app"
    ref="app"
    :class="appClasses"
  >
    <v-theme-provider :theme="currentTheme">
      <modals-container ref="modalsContainer" />
      <toasts-container ref="toastsContainer" />
      <bpc-cookie-banner
        v-if="!isNative"
        ref="cookieBanner"
        class="cookie-banner"
        :language="language"
        position-desktop="middle-center"
        position-mobile="mobile-middle"
      />
      <div class="router-outlet">
        <ot-loading-overlay
          v-if="appUpdateRequested"
          :on-top-of-all="true"
          :not-rounded="true"
          :loading-no-opacity="true"
        />
        <ribbon-section
          v-if="showRibbonSection"
          :market-id="selectedMarketIdSpot"
        />
        <router-view v-slot="{ Component }">
          <component
            :is="Component"
            v-if="!isXS"
          />
          <x-transition-slide
            v-if="isXS"
            :is-xs="isXS"
            :move-back="moveBack"
            :push="true"
            :triggered-by-back-btn="backButtonTriggered"
            :is-route-animation="true"
            :disable-animation="routeAnimationDisabled"
            @transition-enter-started="onRouteTransitionEnterStarted"
            @transition-enter-finished="onRouteTransitionEnterFinished"
            @transition-finished="toggleBackBtn"
          >
            <component :is="Component" />
          </x-transition-slide>
        </router-view>
      </div>
    </v-theme-provider>
  </div>
</template>

<style lang="scss">
#app {
  overflow: hidden auto;
  width: 100vw;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .router-outlet {
    position: relative;
    display: flex;
    overflow: hidden;
    height: 100%;
    flex-direction: column;
    transition: filter 0.2s ease-in-out;
  }

  .cookie-banner {
    --bpc-custom-primary-color: rgb(var(--v-theme-primary));
    --bpc-custom-font: var(--app-font-family-name);
    --bpc-custom-font-size-xs: var(--font-size-md);
    --bpc-custom-font-size-sm: var(--font-size-md);
    --bpc-custom-font-size-md: var(--font-size-lg);
    --bpc-custom-font-size-lg: var(--font-size-xl);
    --bpc-custom-font-size-xl: var(--font-size-xxl);
  }
}

#app.is-native-app {
  /* ios "safe area" - will not affect anything else */
  padding-top: var(--safe-area-top);
}

/* ios "safe area" for zendesk - will not affect anything else */
@media (width <=768px) {
  // stylelint-disable-next-line selector-id-pattern
  #webWidget {
    /* !important as the only way to override inline styles for iframe */
    top: var(--safe-area-top, 0) !important;
  }
}

@media (width <=767px) {
  html {
    min-height: calc(var(--vh, 1vh) * 100);
  }

  html,
  body,
  #app,
  .modals-container,
  .router-outlet {
    position: relative;
    overflow: hidden;
    height: 100%;
    max-height: 100%;
  }

  .modals-container {
    position: absolute;
  }
}
</style>

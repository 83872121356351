import { ref } from 'vue';

const useOpenItem = () => {
  const openItemId = ref('');

  const setOpenItemId = (id: string) => {
    if (openItemId.value === id) {
      openItemId.value = '';
    } else {
      openItemId.value = id;
    }
  };

  return {
    openItemId,
    setOpenItemId,
  };
};

export const useOpenMultiItems = () => {
  const openItems = ref<Array<string>>([]);

  const isOpen = (itemId: string) => openItems.value.some((id) => id === itemId);

  const toggleItem = (itemId: string) => {
    if (isOpen(itemId)) {
      openItems.value = openItems.value.filter((id) => itemId !== id);
    } else {
      openItems.value.push(itemId);
    }
  };

  const resetOpened = () => {
    openItems.value = [];
  };

  return {
    isOpen,
    toggleItem,
    resetOpened,
  };
};

export default useOpenItem;

<script lang="ts" setup>
import { toastManagerInstance, SimpleToast } from '@exchange/libs/toasts/src';

const props = defineProps({
  onError: { type: Function, default: () => {} },
  onSuccess: { type: Function, default: () => {} },
  successToastMessage: { type: [String, Number], required: true },
});

const doOnSuccess = () => {
  props.onSuccess?.();
  toastManagerInstance.addToast({
    content: SimpleToast,
    props: {
      variant: 'default',
      title: 'fundamentals.copy.title',
      translatedMessage: props.successToastMessage,
    },
  });
};

const doOnError = () => {
  props.onError?.();
  toastManagerInstance.addToast({
    content: SimpleToast,
    props: {
      variant: 'failed',
      title: 'fundamentals.copy.title',
      message: 'fundamentals.copy.failed',
    },
  });
};
</script>

<template>
  <ot-copy-textbox
    v-bind="$attrs"
    :on-error="doOnError"
    :on-success="doOnSuccess"
    class="x-copy-textbox"
  />
</template>

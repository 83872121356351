import {
  filter as filterCapping,
  forEach as forEachCapping,
  map as mapCapping,
  reduce as reduceCapping,
  throttle as throttleCapping,
  transform as transformCapping,
} from 'lodash/fp';

/** https://github.com/lodash/lodash/wiki/FP-Guide#convert */

// @ts-ignore lodash types 😔
export const filter = filterCapping.convert({ cap: false });
// @ts-ignore lodash types 😔
export const forEach = forEachCapping.convert({ cap: false });
// @ts-ignore lodash types 😔
export const map = mapCapping.convert({ cap: false });
// @ts-ignore lodash types 😔
export const reduce = reduceCapping.convert({ cap: false });
// @ts-ignore lodash types 😔
export const throttleWithOptions = throttleCapping.convert({ fixed: false });
// @ts-ignore lodash types 😔
export const transform = transformCapping.convert({ cap: false });

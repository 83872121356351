import { isProduction } from '@exchange/helpers/environment';
import WebRest from '@exchange/libs/rest-api/web-api';
import { logger } from '@exchange/libs/utils/simple-logger/src';

import Candlestick, { Granularity, granularityToMilliseconds, GranularityUnitsToSecondsMap } from './candlesticks-model';

export type CurrentCandle = Candlestick & { endAt: number };

const determineStartTime = (granularityMs: number, tzTime: number, previousCandleStart: number): number => {
  let newStartTime = previousCandleStart + granularityMs;

  while (newStartTime + granularityMs <= tzTime) {
    // if there are gaps in the chart we need to skip more than 1 timeframe
    newStartTime += granularityMs;
  }

  return newStartTime;
};

const determineEndTime = (granularityMs: number, tzTime: number, previousCandleEnd: number): number => {
  let newEndTime = previousCandleEnd + granularityMs;

  while (newEndTime < tzTime) {
    // if there are gaps in the chart we need to skip more than 1 timeframe
    newEndTime += granularityMs;
  }

  return newEndTime;
};

export function createCandle(
  state: {
    currentCandle: CurrentCandle;
    timeGranularity: Granularity;
    instrumentCode: string;
  },
  tzTime: number,
  price: number,
  amount: number,
  sequence: number,
): CurrentCandle {
  const partialCandle = {
    last_sequence: sequence,
    instrument_code: state.instrumentCode,
    granularity: state.timeGranularity,
    high: price,
    low: price,
    open: state.currentCandle.close,
    close: price,
    volume: amount * price,
  };

  const granularityInMs = granularityToMilliseconds(state.timeGranularity);
  const time = determineStartTime(granularityInMs, tzTime, state.currentCandle.time);
  const endAt = determineEndTime(granularityInMs, tzTime, state.currentCandle.endAt);

  return {
    ...partialCandle,
    time,
    endAt,
  };
}

export function updateCandle(
  price: number,
  state: {
    currentCandle: CurrentCandle;
    timeGranularity: Granularity;
  },
  amount: number,
  sequence: number,
): CurrentCandle {
  return {
    ...state.currentCandle,
    close: price,
    high: Math.max(state.currentCandle.high, price),
    low: Math.min(state.currentCandle.low, price),
    volume: (state.currentCandle.volume || 0) + amount * price,
    last_sequence: sequence,
  };
}

const getAllBars = async ({
  /** https://tradingview.slack.com/archives/CNA7LSS3H/p1666169824421679?thread_ts=1666021575.573269&cid=CNA7LSS3H */ marketId,
  from,
  to,
  countBack,
  granularity,
  firstDataRequest,
  bars = [],
}: {
  marketId: string;
  from: number;
  to: number;
  countBack: number;
  granularity: Granularity;
  firstDataRequest: boolean;
  bars?: Array<Candlestick>;
}): Promise<{ bars: Array<Candlestick>; nextTime: number | undefined }> => {
  const aYear = 60 * 60 * 24 * 365; // this is in seconds - not milliseconds!
  const backendCountBackLimit = 5000;

  const limitedCountBack = Math.min(backendCountBackLimit, countBack);
  const limitedRequest = countBack - limitedCountBack;

  const partialBars = await WebRest.Candlesticks.get(
    {
      from,
      to: firstDataRequest ? to + aYear : to, // lets add a year on the first request to make sure we have the most recent candle
      countBack: limitedCountBack,
      ...granularity,
    },
    marketId,
  );

  if (limitedRequest && partialBars.candlesticks.length) {
    const { candlesticks } = partialBars;
    const previousCandle = candlesticks[candlesticks.length - 1];

    if (previousCandle) {
      const newTo = Math.floor(new Date(previousCandle.time).getTime() / 1000);
      const newFrom = newTo - granularity.period * GranularityUnitsToSecondsMap[granularity.unit] * limitedRequest;

      return getAllBars({
        marketId,
        from: newFrom,
        to: newTo,
        countBack: limitedRequest,
        granularity,
        firstDataRequest: false,
        bars: bars.concat(partialBars.candlesticks),
      });
    }
  }

  return {
    bars: bars.concat(partialBars.candlesticks),
    nextTime: partialBars.nextTime,
  };
};

export const getCandles = async ({
  marketId,
  from,
  to,
  countBack,
  granularity,
  firstDataRequest,
}: {
  marketId: string;
  from: number;
  to: number;
  countBack: number;
  granularity: Granularity;
  firstDataRequest: boolean;
}) => {
  const { bars, nextTime } = await getAllBars({
    marketId,
    from,
    to,
    countBack,
    granularity,
    firstDataRequest,
  });

  const candles = bars.reverse();

  if (!isProduction) {
    const areSorted = candles.every((b, i, { [i - 1]: a }) => !a || a.time < b.time);

    logger.info(
      `Candles from REST are in order: ${areSorted};`,
      candles.length ? `Candles number is ${candles.length}, the latest candle is ${JSON.stringify(candles[candles.length - 1])}` : '',
    );
  }

  return {
    candles,
    nextTime,
    noData: !candles.length,
  };
};

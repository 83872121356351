/**
 * oidc-client configuration
 * https://github.com/IdentityModel/oidc-client-js/wiki#configuration
 */
import { CONSTANTS } from '@exchange/libs/utils/constants/src';

import { getAppId, platformOptions } from '../client-config';

const oidcSettings = {
  authority: process.env.VUE_APP_OAUTH_URL,
  client_id: getAppId(),
  redirect_uri: platformOptions.web.redirectUrl,
  silent_redirect_uri: platformOptions.web.silentRedirectUrl,
  post_logout_redirect_uri: platformOptions.web.postLogoutRedirectUrl,
  response_type: 'code',
  /**
   * https://openid.net/specs/openid-connect-core-1_0.html#ScopeClaims
   * offline_access https://openid.net/specs/openid-connect-core-1_0.html#OfflineAccess
   */
  scope: 'openid email',
  response_mode: 'fragment',
  /** Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile */
  loadUserInfo: false,
  /** Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration */
  automaticSilentRenew: false,
  /** Should OIDC protocol claims be removed from profile */
  filterProtocolClaims: true,
  /** Will invoke the revocation endpoint on signout if there is an access token for the user */
  revokeAccessTokenOnSignout: true,
  /** The number of seconds before an access token is to expire to raise the accessTokenExpiring event */
  accessTokenExpiringNotificationTime: 60 * 2,
  /** Number of milliseconds to wait for the silent renew to return before assuming it has failed or timed out */
  silentRequestTimeout: 10000,
  /** The window of time (in seconds) to allow the current time to deviate when validating id_token's iat, nbf, and exp values. */
  clockSkew: CONSTANTS.CLOCK_SKEW_MS / 1000,
};

export default oidcSettings;

<script lang="ts" setup>
import {
  otButtonVariants,
  otButtonSizes,
  otButtonRounded,
  type OtButtonVariant,
  type OtButtonSize,
  type OtButtonRounded,
  type OtButtonHrefCustomIconAttrs,
} from '@1trading/design-system';
import { type PropType } from 'vue';

import { capIsNativePlatform } from '@exchange/libs/utils/capacitor/src';

import { nativeOpenLink } from './link-external-helpers';

const props = defineProps({
  activeNav: { type: Boolean, default: false },
  block: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  href: { type: String, required: false },
  hrefCustomIcon: { type: Object as PropType<OtButtonHrefCustomIconAttrs>, required: false },
  loading: { type: Boolean, default: false },
  round: { type: Boolean, default: false },
  rounded: { type: String as PropType<OtButtonRounded>, default: otButtonRounded.null },
  size: { type: String as PropType<OtButtonSize>, default: otButtonSizes.md },
  text: { type: String, required: false },
  to: { type: [String, Object], required: false },
  useSystemBrowser: { type: Boolean, default: false } /** Native only: Use in conjunction with href to determine whether system or in-app browser is used. */,
  variant: { type: String as PropType<OtButtonVariant>, default: otButtonVariants.base },
});
const emit = defineEmits<{
  (e: 'click', value: Event): void;
  (e: 'native-open-link'): void;
}>();

const isNative = capIsNativePlatform();

const onNativeOpenLink = () => {
  if (props.href && isNative) {
    nativeOpenLink(props.href, props.useSystemBrowser);
  }
};
const onClick = (e: Event) => {
  emit('click', e);
};
</script>

<template>
  <ot-button
    class="x-button"
    :active-nav="activeNav"
    :block="block"
    :disabled="disabled"
    :href-custom-icon="hrefCustomIcon"
    :href="href"
    :is-native="isNative"
    :loading="loading"
    :round="round"
    :rounded="rounded"
    :size="size"
    :text="text"
    :to="to"
    :variant="variant"
    @click="onClick"
    @native-open-link="onNativeOpenLink"
  >
    <template
      v-for="(_, slot) of $slots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
  </ot-button>
</template>

<script lang="ts" setup>
import { otListItemVariant, type OtListItemVariant } from '@1trading/design-system';
import { type PropType } from 'vue';

import { capIsNativePlatform } from '@exchange/libs/utils/capacitor/src';

import { nativeOpenLink } from './link-external-helpers';

const props = defineProps({
  active: { type: Boolean, default: false } /** Whether the list item is active */,
  emitId: { type: String, required: false } /** custom identifier of the element; if provided, will be used when emitting click event */,
  href: { type: String, required: false } /** External link to navigate to on click */,
  id: { type: String, default: () => `x-list-item-${crypto.randomUUID()}` } /** element id */,
  leftIconInfo: { type: Boolean, required: false } /** Whether to show info on the left icon */,
  leftIconInheritFill: { type: Boolean, default: false } /** Allow using font color as fill color for left icon */,
  leftIconInheritStroke: { type: Boolean, default: false } /** Allow using font color as stroke color for left icon */,
  leftIconName: { type: String, required: false } /** Name of the left icon */,
  leftIconVariant: { type: String as PropType<'default' | 'flag' | 'logo' | 'error' | 'warning'>, default: 'default' } /** Variant of the left icon */,
  leftIconWarning: { type: Boolean, default: false } /** Whether to show warning on the left icon */,
  routerLink: { type: [String, Object], required: false } /** Router link to navigate to on click */,
  subtitle: { type: String, required: false } /** Subtitle */,
  title: { type: String, required: false } /** Title */,
  useSystemBrowser: { type: Boolean, default: false } /**  Native only: Use in conjunction with href to determine whether system or in-app browser is used. Ignored on web. */,
  variant: { type: String as PropType<OtListItemVariant>, default: otListItemVariant.default } /** Variant of the component */,
});
const emit = defineEmits<{
  (e: 'click', value: string | undefined | PointerEvent): void;
}>();

const isNative = capIsNativePlatform();

const cdn = process.env.VUE_APP_CDN;

const onNativeOpenLink = () => {
  if (props.href && isNative) {
    nativeOpenLink(props.href, props.useSystemBrowser);
  }
};
const onClick = (e: string | undefined | PointerEvent) => {
  emit('click', e);
};
</script>

<template>
  <ot-list-item
    :id="id"
    class="x-list-item"
    :is-native="isNative"
    :active="active"
    :emit-id="emitId"
    :href="href"
    :left-icon-info="leftIconInfo"
    :left-icon-inherit-fill="leftIconInheritFill"
    :left-icon-name="leftIconName"
    :left-icon-variant="leftIconVariant"
    :left-icon-warning="leftIconWarning"
    :cdn="cdn"
    :router-link="routerLink"
    :subtitle="subtitle"
    :title="title"
    :variant="variant"
    @click="onClick"
    @native-open-link="onNativeOpenLink"
  >
    <template
      v-for="(_, slot) of $slots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
  </ot-list-item>
</template>

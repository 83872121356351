import BigNumber from '@exchange/helpers/bignumber';

const getPrecision = (value?: string | number | BigNumber, precision?: number, forcePrecision = false) => {
  const valuePrecision = value ? (new BigNumber(value).decimalPlaces() ?? 0) : 0;

  if (forcePrecision && precision !== undefined) {
    return precision;
  }

  return precision ? Math.max(valuePrecision, precision) : valuePrecision;
};

export default getPrecision;

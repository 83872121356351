import { OAuth2Client } from '@byteowls/capacitor-oauth2';

import { isProduction } from '@exchange/helpers/environment';
import { secureStorageGet, secureStorageReset, secureStorageSet } from '@exchange/helpers/secure-storage-helpers';
import oAuthRest from '@exchange/libs/rest-api/oauth2';
import { serverClientTimeService } from '@exchange/libs/utils/server-client-time/src';
import { logger, nonProdConsoleInfo } from '@exchange/libs/utils/simple-logger/src';

import { AuthError, AuthErrorNeedsLogin } from './auth-errors';
import { AuthService, type SignAdditionalParameters } from './auth.service.common';
import oauth2Settings from './oauth2.config';
// eslint-disable-next-line import/no-cycle
import appPlatformAuthenticator from './platform-authenticator';
import { getJWTPayloadExpirationTime } from './token-helpers';

/* eslint-disable camelcase */
interface TokenEndpointResponse {
  access_token: string;
  refresh_token: string;
  id_token: string;
  refresh_token_expires_in?: number /** ios */;
  additionalParameters?: {
    /** android */ refresh_token_expires_in: number;
  };
}
/* eslint-enable camelcase */

export default class AuthServiceApps extends AuthService {
  private refreshToken = {
    get: () => secureStorageGet<string>('refreshToken', (info) => JSON.parse(info.value)),
    set: (value: string) => secureStorageSet('refreshToken', JSON.stringify(value)),
    clear: () => secureStorageReset('refreshToken'),
  };

  private refreshTokenExpires = {
    get: () => secureStorageGet<number>('refreshTokenExpires', (info) => Number(JSON.parse(info.value))).then((r) => Number(r)),
    set: (value: number) => secureStorageSet('refreshTokenExpires', JSON.stringify(value)),
    clear: () => secureStorageReset('refreshTokenExpires'),
  };

  private clearRefreshTokenInfo = async () => {
    await Promise.all([this.refreshToken.clear(), this.refreshTokenExpires.clear()]);
  };

  public resetAuthInternal = async () => {
    await Promise.all([appPlatformAuthenticator.resetEverything(), this.clearRefreshTokenInfo()]);
  };

  signInCallbackInternal = () => Promise.resolve();

  public signInInternal = async (additionalParameters?: SignAdditionalParameters): Promise<void> => {
    try {
      // eslint-disable-next-line camelcase
      const res: { access_token_response: TokenEndpointResponse } = await OAuth2Client.authenticate({
        ...oauth2Settings,
        ...(additionalParameters ? { additionalParameters } : {}),
      });

      await this.processUserInternal(res.access_token_response);
      this.isLoginProcess.value = false;
      await appPlatformAuthenticator.wentToInactiveAt.set(0);
    } catch (e) {
      const message = (e as AuthError)?.message;
      const code = (e as AuthError)?.code;

      logger.error(`${this.logPrefix}Sign in internal failed with message "${message}", code "${code}"`, e, new Date());
      const isAbnormalClientTime = await serverClientTimeService.checkClientAbnormalTime();

      if (isAbnormalClientTime) {
        serverClientTimeService.showWrongClientTimeModal();
      }

      throw e;
    }
  };

  public triggerSilentAuthorizationInternal = async () => {
    if (await appPlatformAuthenticator.shouldAppBeLocked('silent-auth')) {
      return;
    }

    const user = await this.nativeRefresh();

    await this.processUserInternal(user);
  };

  public signOutInternal = async () => {
    const idToken = await this.idTokenData.token.get();

    if (!idToken) {
      throw new Error(`${this.logPrefix}id token is missing`);
    }

    await oAuthRest.Logout.send(idToken);
    await Promise.all([this.logoutFromFirebase(), OAuth2Client.logout(oauth2Settings)]);
    await this.resetAuth({ isUserInteraction: true, unsubscribeAccountHistory: true });
  };

  public isRefreshTokeExpired = async () => {
    const [refreshTokenExpires, serverTime] = await Promise.all([this.refreshTokenExpires.get(), serverClientTimeService.getReputedlyServerTime()]);

    return !refreshTokenExpires || refreshTokenExpires <= serverTime;
  };

  private nativeRefresh = async (): Promise<TokenEndpointResponse | undefined> => {
    const shouldAppBeLocked = await appPlatformAuthenticator.shouldAppBeLocked('native-refresh');

    if (shouldAppBeLocked) {
      nonProdConsoleInfo(`${this.logPrefix}App is locked, network should not send sensitive data.`);
      return undefined;
    }

    try {
      const [refreshToken, isRefreshTokeExpired] = await Promise.all([this.refreshToken.get(), this.isRefreshTokeExpired()]);

      if (isRefreshTokeExpired) {
        logger.info(`${this.logPrefix}refresh token is already expired`, new Date());
        return undefined;
      }

      if (!refreshToken) {
        logger.info(`${this.logPrefix}there is no refresh token to refresh the session`, new Date());
        return undefined;
      }

      const user = await OAuth2Client.refreshToken({
        accessTokenEndpoint: oauth2Settings.accessTokenEndpoint ?? '',
        appId: oauth2Settings.appId ?? '',
        refreshToken,
      });

      return user;
    } catch (e) {
      const message = (e as AuthError)?.message;
      const error = (e as AuthError)?.error;
      const errorDescription = (e as AuthError)?.error_description;
      const code = (e as AuthError)?.code;

      const refreshTokenExpires = await this.refreshTokenExpires.get();

      logger.error(
        `${this.logPrefix}Native refresh failed with message "${message}", error "${error}", errorDescription "${errorDescription}, code "${code}", exp "${refreshTokenExpires}"`,
        e,
        new Date(),
      );

      if (message && AuthErrorNeedsLogin.includes(message)) {
        this.clearRefreshTokenInfo();
      }

      throw e;
    }
  };

  private runTokenRefreshLoop = async (accessToken: string) => {
    // reset previous timers
    if (this.accessTokenHolderData.expiredTimeout) clearTimeout(this.accessTokenHolderData.expiredTimeout);

    if (this.accessTokenHolderData.expiringTimeout) clearTimeout(this.accessTokenHolderData.expiringTimeout);

    const expireDate = getJWTPayloadExpirationTime(accessToken);
    // TODO consider as param
    const serverTime = await serverClientTimeService.getReputedlyServerTime();

    if (!expireDate || expireDate <= serverTime) {
      throw new Error(`${this.logPrefix}new token is already expired!`);
    }

    const timeToExpire = expireDate - serverTime;

    // @ts-ignore TODO fix it
    this.accessTokenHolderData.expiredTimeout = setTimeout(async () => {
      logger.info(`${this.logPrefix}Session has run out, attempting to get new token.`, new Date());

      const shouldAppBeLocked = await appPlatformAuthenticator.shouldAppBeLocked('refresh-loop');

      if (shouldAppBeLocked) {
        nonProdConsoleInfo(`${this.logPrefix}App is locked, wont do silent auth.`);
        return;
      }

      try {
        await this.triggerSilentAuthorization();
      } catch (e) {
        this.resetAuth({
          isUserInteraction: false,
          unsubscribeAccountHistory: false,
        });
      }
    }, timeToExpire);

    // @ts-ignore TODO fix it
    this.accessTokenHolderData.expiringTimeout = setTimeout(
      async () => {
        if (expireDate - serverTime < 5000) {
          logger.info(`${this.logPrefix}skipping token expiring handler, time diff`, expireDate - serverTime);
          return;
        }

        logger.info(`${this.logPrefix}Try to get a new token silently before the session runs out; expireDate "${expireDate}"`, new Date());
        try {
          await this.triggerSilentAuthorization();
        } catch (e) {
          // ignore
        }
      },
      timeToExpire - 60 * 1000,
    );
  };

  private processUserInternal = async (user: TokenEndpointResponse | undefined) => {
    if (!user) {
      return;
    }

    const getServerTime = await serverClientTimeService.getReputedlyServerTime();
    const refreshTokenExpiresIn = (user.refresh_token_expires_in ?? user.additionalParameters?.refresh_token_expires_in) as number;
    const refreshTokenExpires = refreshTokenExpiresIn * 1000 + getServerTime;

    if (!isProduction) {
      logger.info(
        `%cRefresh token:%c ${user.refresh_token}`,
        'background: #67A7EF; padding: 1px; border-radius: 3px 0 0 3px; color: #fff;',
        'background:#ffffff ; padding: 1px; color: #67A7EF',
      );
      logger.info(
        `%cRefresh token exp in:%c ${refreshTokenExpiresIn}`,
        'background: #67A7EF; padding: 1px; border-radius: 3px 0 0 3px; color: #fff;',
        'background:#ffffff ; padding: 1px; color: #67A7EF',
      );
      logger.info(
        `%cRefresh token exp:%c ${refreshTokenExpires}`,
        'background: #67A7EF; padding: 1px; border-radius: 3px 0 0 3px; color: #fff;',
        'background:#ffffff ; padding: 1px; color: #67A7EF',
      );
      logger.info(
        `%cRefresh token exp date:%c ${new Date(refreshTokenExpires)}`,
        'background: #67A7EF; padding: 1px; border-radius: 3px 0 0 3px; color: #fff;',
        'background:#ffffff ; padding: 1px; color: #67A7EF',
      );
    }

    await Promise.all([
      this.refreshToken.set(user.refresh_token),
      this.refreshTokenExpires.set(refreshTokenExpires),
      this.processAuthenticatedHolder({ idToken: user.id_token, accessToken: user.access_token }),
    ]);

    this.runTokenRefreshLoop(user.access_token);
  };
}

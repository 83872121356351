<script lang="ts">
export const colorVariants = {
  light: 'light',
  dark: 'dark',
} as const;
export type ColorVariant = (typeof colorVariants)[keyof typeof colorVariants];
</script>

<script lang="ts" setup>
import VueDatePicker from '@vuepic/vue-datepicker';
import { endOfDay, format, isBefore, startOfDay } from 'date-fns';
import { nanoid } from 'nanoid';
import { computed, type PropType, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { addNumberOfMonths, endOfCurrentDay, subNumberOfMonths } from '@exchange/helpers/time-offset';
import useMQBreakpoints from '@exchange/libs/composables/shared/src/lib/useMQBreakpoints';
import { ModalFooter } from '@exchange/libs/modals/src';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';
import { langsInfoService } from '@exchange/libs/utils/langs-info/src';

export interface DateFilterModel {
  from?: Date | string;
  to?: Date | string;
}

const props = defineProps({
  value: { type: Object as PropType<DateFilterModel>, default: () => ({ from: undefined, to: undefined }) },
  monthsToSelect: { type: Number, default: CONSTANTS.HISTORY_DROPDOWN_NUMBER_OF_MONTHS_SELECTED },
  /**
   * Position of the datepicker relative to the trigger element,
   * except for `viewport-center` which will center the datepicker within the viewport
   */
  position: { type: String as PropType<'left' | 'center' | 'viewport-center' | 'bottom-center' | 'right'>, default: 'center' },
  onModal: { type: Boolean, default: false },
  filledTrigger: { type: Boolean, default: true },
  variant: { type: String as PropType<ColorVariant>, default: colorVariants.dark },
});
const emit = defineEmits<{
  (e: 'update:value', value: { from: string | undefined; to: string | undefined }): void;
}>();

const { t } = useI18n({ useScope: 'global' });
const { isXS } = useMQBreakpoints();

const historyRangePickerMobileWrapperId = `range-picker-${nanoid()}`;

const datepicker = ref<HTMLElement>();
const pickerIsShown = ref(false);

const datePickerPosition = computed(() => (props.position.includes('-center') ? 'center' : props.position));
const teleportCenter = computed(() => props.position === 'viewport-center');
const altPosition = computed(() =>
  props.position === 'bottom-center'
    ? (el: HTMLElement) => {
        const datePickerRect = el.getBoundingClientRect();
        const top = datePickerRect.bottom + 8;
        const left = document.body.clientWidth / 2;
        const transform = 'translateX(-50%)';

        return { top, left, transform };
      }
    : null,
);

const displayedDateFormat = computed(() => CONSTANTS.HISTORY_DROPDOWN_DISPLAYED_DATE_FORMAT);
const formatDate = (d: Date) => format(d, displayedDateFormat.value);

const getDateValue = (v: string | Date | undefined) => {
  if (v === undefined) {
    return v;
  }

  return typeof v === 'string' ? new Date(v) : v;
};

const dateOne = ref(getDateValue(props.value.from));
const dateTwo = ref(getDateValue(props.value.to));
const maxDate = ref<Date>(new Date(endOfCurrentDay()));
const minDate = ref<Date | undefined>(undefined);

const dateRange = computed(() => (dateOne.value && dateTwo.value ? [dateOne.value, dateTwo.value] : null));

watch(
  () => props.value,
  () => {
    dateOne.value = getDateValue(props.value.from);
    dateTwo.value = getDateValue(props.value.to);
    maxDate.value = new Date(endOfCurrentDay());
  },
);

const getFormattedDates = (dates: Array<Date> | null) => {
  let fDates = '';

  if (dates && dates[0] && dates[1]) {
    fDates = `${formatDate(dates[0])} - ${formatDate(dates[1])}`;
  }

  return fDates;
};

const formattedDates = computed(() => getFormattedDates(dateRange.value));

const multiCalendars = computed(() => {
  if (isXS.value) return false;

  return 2;
});

const pickerHeader = computed(() => {
  const withSelectVersion = t('modules.datePicker.title');
  const justTitleVersion = t('modules.datePicker.titleShort');

  if (props.onModal) {
    return withSelectVersion;
  }

  return isXS.value ? `${justTitleVersion}:` : justTitleVersion;
});

const localeName = computed(() => langsInfoService.language.value);
const fullscreen = computed(() => isXS.value);

const onClosed = () => {
  pickerIsShown.value = false;
  emitInput();
};

const onOpened = () => {
  maxDate.value = new Date(endOfCurrentDay());
  pickerIsShown.value = true;
};

const onUpdate = (value: [Date, Date]) => {
  [dateOne.value, dateTwo.value] = value;
};

const emitInput = () => {
  if (dateOne.value && !dateTwo.value) {
    dateTwo.value = dateOne.value;
  }

  emit('update:value', {
    from: dateOne.value ? startOfDay(dateOne.value).toISOString() : undefined,
    to: dateTwo.value ? endOfDay(dateTwo.value).toISOString() : undefined,
  });
};

const internalModelChange = (value: Date[] | null) => {
  if (!value) return;

  if (value.length === 1 && value[0]) {
    const possibleMinDate = subNumberOfMonths(props.monthsToSelect, value[0]);
    const possibleMaxDate = addNumberOfMonths(props.monthsToSelect, value[0]);

    minDate.value = possibleMinDate;

    if (isBefore(possibleMaxDate, new Date(maxDate.value))) {
      maxDate.value = possibleMaxDate;
    }
  } else {
    minDate.value = undefined;
    maxDate.value = new Date();
  }
};

const selectDate = () => {
  datepicker.value.selectDate();
};
const closeMenu = () => {
  datepicker.value.closeMenu();
};
</script>

<template>
  <div
    class="history-range-picker"
    :class="[`history-range-picker--${variant}`, { 'history-range-picker--full-width': onModal }]"
  >
    <div class="history-range-picker__title">{{ pickerHeader }}</div>
    <teleport
      v-if="fullscreen"
      to="body"
    >
      <div
        v-show="pickerIsShown"
        :id="historyRangePickerMobileWrapperId"
        :class="['history-range-picker-fullscreen-container', `history-range-picker-fullscreen-container--${variant}`]"
      />
    </teleport>
    <vue-date-picker
      ref="datepicker"
      :range="true"
      :model-value="dateRange"
      :start-date="dateTwo"
      offset="6"
      six-weeks="center"
      month-name-format="long"
      :dark="variant !== 'light'"
      :hide-offset-dates="false"
      :month-change-on-scroll="false"
      :clearable="false"
      :enable-time-picker="false"
      :locale="localeName"
      :min-date="minDate"
      :max-date="maxDate"
      :multi-calendars="multiCalendars"
      :transitions="{ menuAppear: 'ot-transition-fade' }"
      :position="datePickerPosition"
      :alt-position="altPosition"
      :teleport-center="teleportCenter"
      :menu-class-name="`${fullscreen ? 'history-range-picker__menu history-range-picker__menu--fullscreen' : 'history-range-picker__menu'}`"
      calendar-class-name="'history-range-picker__calendar"
      :teleport="fullscreen ? `#${historyRangePickerMobileWrapperId}` : true"
      @open="onOpened"
      @closed="onClosed"
      @update:model-value="onUpdate"
      @internal-model-change="internalModelChange"
    >
      <template #calendar-icon>
        <ot-icon
          class="history-range-picker__calendar-close-icon"
          name="calendar-tick"
          inherit-stroke="inherit-stroke"
        />
      </template>
      <template #action-row="menu">
        <modal-footer class="history-range-picker__footer-actions">
          <x-button
            class="history-range-picker__footer-actions-button"
            variant="outline"
            :text="$t('modules.datePicker.cancel')"
            @click="closeMenu()"
          />
          <x-button
            class="history-range-picker__footer-actions-button"
            variant="primary"
            :disabled="!menu.internalModelValue || menu.internalModelValue.length < 2"
            :text="$t('modules.datePicker.select')"
            @click="selectDate()"
          />
        </modal-footer>
      </template>
      <template #trigger>
        <div class="history-range-picker__trigger-container">
          <div
            id="datepicker-trigger"
            class="history-range-picker__trigger"
          >
            {{ formattedDates || $t('modules.accountHistory.export.fullHistory') }}
          </div>
          <div
            class="history-range-picker__trigger-arrow"
            :class="{ 'history-range-picker__trigger-arrow--opened': pickerIsShown }"
          >
            <ot-icon
              class="history-range-picker__trigger-arrow-icon"
              name="arrow-down"
              inherit-stroke="inherit-stroke"
            />
          </div>
        </div>
      </template>
      <template #arrow-left>
        <ot-icon
          class="history-range-picker__month-icon"
          name="previous"
          inherit-stroke="inherit-stroke"
        />
      </template>
      <template #arrow-right>
        <ot-icon
          class="history-range-picker__month-icon"
          name="next"
          inherit-stroke="inherit-stroke"
        />
      </template>
    </vue-date-picker>
  </div>
</template>

<style lang="scss">
/* stylelint-disable */
/** TODO fix styles and enable stylelint */
@import 'node_modules/@vuepic/vue-datepicker/src/VueDatePicker/style/main';

.dp__theme_dark {
  --dp-background-color: rgb(var(--v-theme-elevation-2));
  --dp-text-color: rgb(var(--v-theme-text-primary));
  --dp-hover-color: rgb(var(--v-theme-primary-hover));
  --dp-hover-text-color: rgb(var(--v-theme-text-primary));
  --dp-hover-icon-color: rgb(var(--v-theme-text-primary));
  --dp-primary-color: rgb(var(--v-theme-primary));
  --dp-primary-disabled-color: rgb(var(--v-theme-primary-disabled));
  --dp-primary-text-color: rgb(var(--v-theme-text-primary));
  --dp-secondary-color: rgb(var(--v-theme-text-secondary));
  --dp-border-color: rgb(var(--v-theme-elevation-2));
  --dp-menu-border-color: rgb(var(--v-theme-elevation-2));
  --dp-border-color-hover: rgb(var(--v-theme-primary));
  --dp-disabled-color: rgb(var(--v-theme-elevation-2));
  --dp-disabled-color-text: rgb(var(--v-theme-text-primary));
  --dp-scroll-bar-background: transparent;
  --dp-scroll-bar-color: transparent;
  --dp-success-color: rgb(var(--v-theme-primary));
  --dp-success-color-disabled: rgb(var(--v-theme-primary-disabled));
  --dp-icon-color: rgb(var(--v-theme-text-secondary));
  --dp-danger-color: rgb(var(--v-theme-error));
  --dp-marker-color: rgb(var(--v-theme-secondary));
  --dp-tooltip-color: rgb(var(--v-theme-elevation-2));
  --dp-highlight-color: rgb(0 92 178 / 20%);

  /** local vars */
  --hrp-overlay-background-color: rgb(var(--v-theme-elevation-3));
}

.dp__theme_light {
  --dp-background-color: var(--elevation-light-0);
  --dp-text-color: var(--text-0);
  --dp-hover-color: rgb(var(--v-theme-primary-hover));
  --dp-hover-text-color: var(--text-0);
  --dp-hover-icon-color: rgb(var(--v-theme-text-tertiary));
  --dp-primary-color: rgb(var(--v-theme-primary));
  --dp-primary-disabled-color: rgb(var(--v-theme-primary-disabled));
  --dp-primary-text-color: var(--text-0);
  --dp-secondary-color: rgb(var(--v-theme-text-secondary));
  --dp-border-color: var(--elevation-light-0);
  --dp-menu-border-color: var(--elevation-light-0);
  --dp-border-color-hover: rgb(var(--v-theme-primary));
  --dp-disabled-color: rgb(var(--v-theme-text-primary));
  --dp-disabled-color-text: rgb(var(--v-theme-text-primary));
  --dp-scroll-bar-background: transparent;
  --dp-scroll-bar-color: transparent;
  --dp-success-color: rgb(var(--v-theme-primary));
  --dp-success-color-disabled: rgb(var(--v-theme-primary-disabled));
  --dp-icon-color: rgb(var(--v-theme-text-secondary));
  --dp-danger-color: rgb(var(--v-theme-error));
  --dp-marker-color: rgb(var(--v-theme-secondary));
  --dp-tooltip-color: var(--elevation-light-0);
  --dp-highlight-color: rgb(25 118 210 / 10%);

  /** local vars */
  --hrp-overlay-background-color: var(--elevation-light-2);
}

.history-range-picker,
.history-range-picker__menu {
  --dp-cell-padding: 4px;
  --dp-cell-size: 40px;
  --dp-common-padding: 16px;
  --dp-disabled-background: transparent;
  --dp-menu-padding: 16px;
  --dp-row-maring: 2px;
  --dp-cell-border-radius: var(--app-border-radius);
  --dp-border-radius: var(--app-border-radius);

  /** local vars */
  --hrp-main-width: auto;
}

/* VueDatePicker Overrides
// -------------------------------------------------- */
.dp__main {
  width: var(--hrp-main-width);
}

/** Div style that is displayed on the bottom of the menu for switching layouts */
.dp__button {
  --dp-common-padding: 8px;

  &.dp__overlay_action {
    --dp-hover-color: transparent;

    border-radius: 0 0 var(--dp-border-radius) var(--dp-border-radius);
  }
}

/** DatepickerMenu */
.dp__arrow_top {
  display: none;
}

/** Calendar */
.dp__calendar_header {
  padding: 8px 0 4px 0;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-sm);
}

.dp__calendar_header_item {
  height: auto;
  flex-grow: 0;
}

.dp__calendar_item {
  flex-grow: 0;
}

.dp__range_start.dp__range_end {
  border-radius: var(--dp-border-radius);
}

/** MonthYearInput */
.dp__month_year_wrap {
  justify-content: space-around;
}

.dp__year_disable_select {
  flex-basis: 45%;
  border: 1px solid rgb(var(--v-theme-text-primary));
}

/** SelectionGrid */
.dp__overlay {
  --dp-background-color: var(--hrp-overlay-background-color);

  border-radius: var(--dp-border-radius);
}

.dp__overlay_container {
  padding: 4px;
  border-bottom: 1px solid var(--dp-border-color);
}

.dp__overlay_cell_pad {
  --dp-common-padding: 8px;

  font-size: var(--font-size-sm);
}

/** ActionRow */
.dp__action_row {
  --dp-common-padding: 0;
}

/* ---------------------------------------------------------------------------------------------------- */

.history-range-picker {
  --fullscreen-menu-padding-top: 60px;

  display: flex;
  flex-direction: column;
  letter-spacing: normal;
  font-size: var(--font-size-sm);

  .xs & {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    flex-shrink: 0;
    font-weight: var(--font-weight-medium);

    .xs & {
      color: rgb(var(--v-theme-text-secondary));
    }
  }

  &__menu {
    font-size: var(--font-size-md);
    border-radius: var(--app-border-radius);
    border: 0;
    box-shadow:
      rgb(15 18 23 / 99%) 0 1px 2px 0,
      rgb(15 18 23 / 99%) 0 2px 6px 2px;

    &:focus,
    &:focus-visible {
      outline: none;
      border: 0;
    }
  }

  &__trigger-container {
    min-height: var(--input-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: var(--font-weight-medium);
    color: rgb(var(--v-theme-text-secondary));
    border: solid 1px rgb(var(--v-theme-elevation-2));
    border-radius: var(--app-border-radius);
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    outline: none;

    .xs & {
      min-width: 145px;
      border-style: none;
      background-color: inherit;
    }

    &:hover {
      color: rgb(var(--v-theme-text-primary));
      border-color: rgb(var(--v-theme-primary));
    }
  }

  &__trigger {
    padding: 10px;
    flex-grow: 1;

    .xs & {
      padding: 10px 0;
      text-align: right;
    }
  }

  &__trigger-arrow {
    padding: 0 7px;
    display: flex;
    color: rgb(var(--v-theme-text-secondary));

    &--opened {
      border-radius: 0 2px 2px 0;
    }
  }

  &__trigger-arrow-icon {
    width: 14px;
  }

  &__trigger-arrow--opened &__trigger-arrow-icon {
    transform: rotateX(180deg);
  }

  &__month-icon {
    width: 16px;
  }

  &__footer-actions {
    flex: 1;
  }
}

.history-range-picker--full-width {
  --hrp-main-width: 100%;
}
/* stylelint-enable */
</style>

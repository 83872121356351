import { useBreakpoints, Breakpoints } from '@vueuse/core';
import { computed, ComputedRef, Ref } from 'vue';

const bodyFontSize = 1;
const defaultBrowserFontSize = Number(process.env.VUE_APP_BASE_FONT_SIZE ?? 16);

export function getMediaQueryBasedOnRem(mediaQueryBreakpoint: number, rootFontSize: number) {
  return (mediaQueryBreakpoint / defaultBrowserFontSize) * rootFontSize;
}

export const getFontSize = (selector: string) => {
  const element = document.querySelector(selector);

  if (!element) {
    throw new Error(`initMq Failed, selector '${selector}' not found`);
  }

  return (Number(window.getComputedStyle(element).fontSize.split('px')[0]) || defaultBrowserFontSize) * bodyFontSize;
};

type ProBreakpoints = 'xs' | 'sm' | 'md' | 'lg';

export function getProBreakpoints(isNativeApp: boolean): Breakpoints<ProBreakpoints> {
  const selector = 'body';
  const rootFontSize = isNativeApp ? defaultBrowserFontSize : getFontSize(selector);

  return {
    xs: getMediaQueryBasedOnRem(768, rootFontSize),
    sm: getMediaQueryBasedOnRem(1024, rootFontSize),
    md: getMediaQueryBasedOnRem(1440, rootFontSize),
    lg: getMediaQueryBasedOnRem(1770, rootFontSize),
  };
}

let breakpoints: Record<ProBreakpoints, Ref<boolean>> & {
  greater(ProBreakpoints: ProBreakpoints): Ref<boolean>;
  greaterOrEqual: (k: ProBreakpoints) => Ref<boolean>;
  smaller(k: ProBreakpoints): Ref<boolean>;
  smallerOrEqual(k: ProBreakpoints): Ref<boolean>;
  between(a: ProBreakpoints, b: ProBreakpoints): Ref<boolean>;
  isGreater(k: ProBreakpoints): boolean;
  isGreaterOrEqual(k: ProBreakpoints): boolean;
  isSmaller(k: ProBreakpoints): boolean;
  isSmallerOrEqual(k: ProBreakpoints): boolean;
  isInBetween(a: ProBreakpoints, b: ProBreakpoints): boolean;
  current(): ComputedRef<string[]>;
};
// xs
let isXS: Ref<boolean>;
// sm
let isSM: Ref<boolean>;
let isSMAndDown: Ref<boolean>;
let isSMAndUp: Ref<boolean>;
// md
let isMD: Ref<boolean>;
let isMDAndDown: Ref<boolean>;
let isMDAndUp: Ref<boolean>;
// lg
let isLG: Ref<boolean>;
let isLGAndDown: Ref<boolean>;
let isLGAndUp: Ref<boolean>;
// xl
let isXL: Ref<boolean>;
let isNotMobile: ComputedRef<boolean>;
let currentMQName: ComputedRef<'xs' | 'sm' | 'md' | 'lg' | 'xl'>;

export function initMQBreakpoints(isNativeApp = false) {
  const proBreakpoints = getProBreakpoints(isNativeApp);
  const greaterThen = (breakpoint: ProBreakpoints) => (proBreakpoints[currentMQName.value] || proBreakpoints.lg) > proBreakpoints[breakpoint];
  const smallerThen = (breakpoint: ProBreakpoints) => proBreakpoints[currentMQName.value] < proBreakpoints[breakpoint];

  breakpoints = useBreakpoints(proBreakpoints);

  // xs
  isXS = breakpoints.smaller('xs');
  // sm
  isSM = breakpoints.between('xs', 'sm');
  isSMAndDown = computed(() => isSM.value || smallerThen('sm'));
  isSMAndUp = computed(() => isSM.value || greaterThen('sm'));
  // md
  isMD = breakpoints.between('sm', 'md');
  isMDAndDown = computed(() => isMD.value || smallerThen('md'));
  isMDAndUp = computed(() => isMD.value || greaterThen('md'));
  // lg
  isLG = breakpoints.between('md', 'lg');
  isLGAndDown = computed(() => isLG.value || smallerThen('lg'));
  isLGAndUp = computed(() => isLG.value || isXL.value);
  // xl
  isXL = breakpoints.greater('lg');
  isNotMobile = computed(() => !isXS.value);
  currentMQName = computed(() => {
    if (isXS.value) {
      return 'xs';
    }

    if (isSM.value) {
      return 'sm';
    }

    if (isMD.value) {
      return 'md';
    }

    if (isLG.value) {
      return 'lg';
    }

    if (isXL.value) {
      return 'xl';
    }

    return 'md';
  });
}

const useMQBreakpoints = () => {
  if (!breakpoints) {
    throw new Error('No breakpoints');
  }

  return {
    // xs
    isXS,
    // sm
    isSM,
    isSMAndDown,
    isSMAndUp,
    // md
    isMD,
    isMDAndDown,
    isMDAndUp,
    // lg
    isLG,
    isLGAndDown,
    isLGAndUp,
    // xl
    isXL,
    // other
    isNotMobile,
    currentMQName,
  };
};

export default useMQBreakpoints;

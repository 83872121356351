import { filter, fromPairs, get, identity, isEmpty, map, mapValues, negate, pipe, replace, split } from 'lodash/fp';
import { LocationQuery, RouteLocationNormalized } from 'vue-router';

function paramToString(param: string | Array<string> | undefined) {
  if (param === undefined || param === null) {
    return undefined;
  }

  return typeof param === 'string' ? param : param[0];
}

export const getAllFromRouterQuery = pipe(get('query'), mapValues(identity));

export const getFromQuery = (queryParam: string, queryObj: LocationQuery) => pipe(get(queryParam), paramToString)(queryObj);

export const getHashPairsFromRouter = pipe(get('hash'), replace(/#/g, '&'), split('&'), filter(negate(isEmpty)), map(split('=')), fromPairs);

const getFromRouterQuery = (queryParam: string, routerObj: RouteLocationNormalized): string | undefined => pipe(get(`query.${queryParam}`), paramToString)(routerObj);

export default getFromRouterQuery;

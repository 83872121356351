export enum AccountStatuses {
  WAITING_FOR_DOCUMENTS = 'WAITING_FOR_DOCUMENTS', // business user signed up but did not give us documents yet
  WAITING_FOR_DOCUMENTS_APPROVAL = 'WAITING_FOR_DOCUMENTS_APPROVAL', // compliance is reviewing documents
  APPROVED = 'APPROVED', // documents approved and user may use the app. This is default value for normal users.
}

export enum AccountTypes {
  USER = 'user',
  BUSINESS = 'business_user',
  INSTITUTIONAL = 'institutional_user',
}

export enum VerificationTypes {
  FULL = 'verified',
  LIGHT = 'documented',
  UNVERIFIED = 'unverified',
  STARTED = 'started',
  PENDING_APPROVAL = 'pending_approval',
  FRAUD = 'fraud',
}

/* eslint-disable camelcase */
export type CustomerSegment = 'INSTITUTIONAL' | 'MARKET_MAKER' | 'RETAIL';

type BEBrokerUser = {
  account_status: AccountStatuses;
  residence_country_iso: string;
  currency: string;
  email: string;
  firstname: string;
  surname: string;
  TwoFA: boolean;
  verification_date?: {
    date_iso8601: string;
    unix: string;
  };
  verified: VerificationTypes;
  segment: CustomerSegment;
};

export type BrokerUser = BEBrokerUser & { id: string };
/* eslint-enable camelcase */

export default class UserModel {
  public userData: BrokerUser;

  get verificationLevel() {
    switch (this.userData.verified) {
      case VerificationTypes.FULL:
        return 'full';

      case VerificationTypes.LIGHT:
        return 'light';

      default:
        return undefined;
    }
  }

  get isFullyVerified() {
    return this.userData.verified === VerificationTypes.FULL;
  }

  get isLightlyVerified() {
    return this.userData.verified === VerificationTypes.LIGHT;
  }

  get isRetail() {
    return this.userData.segment === 'RETAIL';
  }

  get email() {
    return this.userData.email;
  }

  get fullName() {
    if (this.userData.firstname && this.userData.surname) {
      return `${this.userData.firstname} ${this.userData.surname}`;
    }

    return this.userData.firstname ?? this.userData.surname ?? undefined;
  }

  constructor(data: {
    user: Array<{
      type: 'user';
      attributes: BEBrokerUser;
      id: string;
    }>;
  }) {
    const [user] = data.user;

    if (!user) {
      throw new Error('No user data found');
    }

    const brokerUser: BrokerUser = {
      id: user.id,
      ...user.attributes,
    };

    this.userData = brokerUser;
  }
}

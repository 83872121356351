import { capGetPlatform, capIsNativePlatform } from '@exchange/libs/utils/capacitor/src';

const base = process.env.VUE_APP_URL;
const isDev = process.env.VUE_APP_DEVELOPMENT === 'true';
const appID = 'com.bitpanda.exchange';
const cbPath = 'auth-callback';

export function getAppId() {
  const platform = capGetPlatform();

  switch (platform) {
    case 'android':
      return 'bp-pro-android';
    case 'ios':
      return 'bp-pro-ios';
    default:
      if (isDev) {
        return 'bp-pro-dev';
      }

      return 'bp-pro-web';
  }
}

export const platformOptions = {
  web: {
    redirectUrl: `${base}/web-${cbPath}`,
    silentRedirectUrl: `${base}/auth-silent-callback.html`,
    postLogoutRedirectUrl: base,
  },
  android: {
    redirectUrl: `${appID}:/${cbPath}`,
  },
  ios: {
    redirectUrl: `${appID}:/${cbPath}`,
  },
};

/**
 * idToken is optional, back-end gonna work without it
 */
export function getLogoutUrl(logoutState: string, idToken?: string) {
  const logoutBase = `${process.env.VUE_APP_OAUTH_URL}/logout?post_logout_redirect_uri=`;
  const redirectDocument = 'logout-redirect.html';
  let redirectPath = process.env.VUE_APP_URL;

  if (capIsNativePlatform()) {
    redirectPath = `${appID}:`;
  }

  const logoutUrl = new URL(`${logoutBase}${redirectPath}/${redirectDocument}`);

  logoutUrl.searchParams.set('state', logoutState);

  if (idToken) {
    logoutUrl.searchParams.set('id_token_hint', idToken);
  }

  return logoutUrl.toString();
}

import { isIOS, isAndroid, isMobileOnly } from 'mobile-device-detect';

const useInputFocusFallback = () => {
  const modalContainer = document.getElementById('modals-container-scroll-helper');
  const onlyMobileDevice = isMobileOnly && (isIOS || isAndroid);

  const onFocusCb = (wrapperElement: HTMLElement | undefined) => {
    if (onlyMobileDevice) {
      const gapFromTop = 200;
      const currentScrollPosition = modalContainer?.scrollTop || 0;
      const inputPosition = wrapperElement?.getBoundingClientRect().y || 0;
      const y = currentScrollPosition + inputPosition - gapFromTop;

      if (modalContainer) {
        modalContainer.style.paddingBottom = '100px';
        modalContainer.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  };
  const onBlurCb = () => {
    if (modalContainer && onlyMobileDevice) {
      modalContainer.style.paddingBottom = '0px';
    }
  };

  return {
    onFocusCb,
    onBlurCb,
  };
};

export default useInputFocusFallback;

<script lang="ts">
export default {
  name: 'XLogo',
  customOptions: {},
};
</script>

<script lang="ts" setup>
const cdn = process.env.VUE_APP_CDN;
</script>

<template>
  <ot-logo
    v-bind="$attrs"
    :cdn="cdn"
    class="x-logo"
  />
</template>

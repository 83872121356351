import exchangeBottomTabs from '@exchange/layouts/trading/components/bottom-tabs';
import { capIsNativePlatform } from '@exchange/libs/utils/capacitor/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';
import { checkIsCurrentBiometricRoute } from '@exchange/routing';
import router from '@exchange/routing/router';

// TODO fix it
// eslint-disable-next-line import/no-cycle
import AppsAuth from './auth.service.apps';
import WebAuth from './auth.service.web';

const handleReloadUponSignoutApps = async () => {
  if (checkIsCurrentBiometricRoute()) {
    await router.push({ name: 'exchange', query: { tab: exchangeBottomTabs.MARKETS.key } });
  }

  /**
   * when user logs out back-end sends CONNECTION_CLOSING message that triggers UI reload.
   * To avoid waiting for that reload - reload right a way.
   */
  logger.info('Reloading after Logout', new Date());
  window.location.reload();
};

const handleReloadUponSignoutWeb = async () => {
  /**
   * Web: redirect is already done by OIDC
   */
};

export const authService = capIsNativePlatform() ? new AppsAuth(handleReloadUponSignoutApps) : new WebAuth(handleReloadUponSignoutWeb);

export default authService;

import { computed, ComputedRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { getAppVersion, isProduction } from '@exchange/helpers/environment';
import { marketService } from '@exchange/libs/market/service/src';

const useAppTitle = (marketId: ComputedRef<string>) => {
  const { t } = useI18n({ useScope: 'global' });

  const { getMarket } = marketService;

  const title = computed(() => t('app.name'));
  const market = computed(() => getMarket(marketId.value));

  const appTitle = computed(() => {
    if (market.value?.lastPrice) {
      return `${market.value.lastPrice.toFixed(market.value.marketPrecision)} ${market.value.name} ${title.value}`;
    }

    return title.value;
  });

  watch(
    appTitle,
    () => {
      document.title = isProduction ? appTitle.value : `[${process.env.VUE_APP_ENV} #${getAppVersion()}] ${appTitle.value}`;
    },
    { immediate: true },
  );

  return {
    appTitle,
  };
};

export default useAppTitle;

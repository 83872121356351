import BigNumber from '@exchange/helpers/bignumber';
import numberFormat from '@exchange/helpers/number-format';

/**
 * Returns the number with the wanted precision set on and added the Symbol
 * @constructor
 * @param {number|string|BigNumber} v - The number you want to have formatted.
 * @param {number|string} precision - The precision of the desired format.
 * @param {string} symbol - The symbol that should be added in the format.
 * @param {boolean} grouping - If the grouping should be enabled.
 *
 * Attention: function will round down at the precision
 *
 * Examples:
 * value = 122.33
 * precision = 8
 * symbol = BTC
 * => 122.33000000 BTC
 */
export default function f({
  v,
  precision,
  symbol,
  grouping = false,
  locale = 'en',
}: {
  v: number | string | BigNumber;
  precision: number | string;
  symbol?: string;
  grouping?: boolean;
  locale?: string;
}): string {
  const precisionToUse = Number.isNaN(precision) ? 0 : Number(precision);

  // rounding down
  let value = new BigNumber(v).toFixed(precisionToUse);

  if (grouping) {
    value = numberFormat({
      number: +value,
      precision: precisionToUse + 1,
      grouping: true,
      locale,
    });
    value = value.slice(0, -1);
  }

  if (symbol) {
    value = `${value} ${symbol}`;
  }

  return value;
}

export const isTooSmall = (total: BigNumber, precision: number) => new BigNumber(total).comparedTo(new BigNumber(10).pow(-precision)) === -1;

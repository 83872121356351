<script lang="ts" setup>
import { capToggleStatusBar } from '@exchange/libs/utils/capacitor/src';

const onToggleInputState = (value: boolean) => {
  capToggleStatusBar(value);
};
</script>

<template>
  <ot-input-number
    class="x-input-number"
    v-bind="$attrs"
    @toggle-input-state="onToggleInputState"
  >
    <template
      v-for="(_, slot) of $slots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
  </ot-input-number>
</template>

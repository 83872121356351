import { shallowRef } from 'vue';

import ForbiddenJurisdictionModal from '@exchange/libs/jurisdiction/ForbiddenJurisdictionModal.vue';
import { modalService } from '@exchange/libs/modals/src';
import { oAuthRest } from '@exchange/libs/rest-api/personal-api';
import { NotificationKey } from '@exchange/libs/rest-api/personal-api/notification';
import PublicRest from '@exchange/libs/rest-api/public-api';
import { SimpleToast, toastManagerInstance } from '@exchange/libs/toasts/src';
import { capIsNativePlatform } from '@exchange/libs/utils/capacitor/src';
import handleNativeGoTo from '@exchange/libs/utils/capacitor/src/lib/handle-native-go-to';
import { logger } from '@exchange/libs/utils/simple-logger/src';

const handleForbiddenJurisdiction = async ({ email, residency }: { email: string; residency: string }) => {
  const jurisdictions = await PublicRest.Jurisdictions.get().catch(() => ({}));

  if (!jurisdictions[residency]) {
    return undefined;
  }

  if (jurisdictions[residency].amlRisk === 'prohibited') {
    modalService.show(
      shallowRef(ForbiddenJurisdictionModal),
      {
        canNotifyMe: !!email,
      },
      {
        'contact-support': () => {
          const supportUrl = 'https://support.onetrading.com/requests/new';

          if (capIsNativePlatform()) {
            handleNativeGoTo(supportUrl);
          } else {
            window.location.href = supportUrl;
          }
        },
        'notify-me': async () => {
          try {
            await oAuthRest.Notification.post(NotificationKey.JURISDICTION_OPENING, {
              email,
              residencyCountry: residency,
            });
            toastManagerInstance.addToast({
              content: SimpleToast,
              props: {
                variant: 'success',
                title: 'ui.forbiddenJurisdictionModal.notifyMeSuccess.title',
                message: 'ui.forbiddenJurisdictionModal.notifyMeSuccess.description',
              },
            });
          } catch (e) {
            toastManagerInstance.addToast({
              content: SimpleToast,
              props: {
                variant: 'failed',
                title: 'fundamentals.toasts.failed',
                message: 'fundamentals.error.text',
              },
            });
            logger.error(`Failed to notify about forbidden jurisdiction:`, e);
          }
        },
      },
      {
        canClose: false,
        centered: true,
      },
    );

    return true;
  }

  return false;
};

export default handleForbiddenJurisdiction;

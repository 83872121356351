import { orderBy } from 'lodash/fp';

import BigNumber from '@exchange/helpers/bignumber';
import filterBy from '@exchange/helpers/filter-by';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';

export const getSum = (value: BigNumber, sum: BigNumber) => new BigNumber(sum).plus(value.isNaN() ? 0 : value);

export const getTotalV = <
  T extends {
    totalCryptoValue: BigNumber;
    totalFiatValue: BigNumber;
    lockedFiatValue: BigNumber;
  },
>(
  arr: Array<T>,
  type: 'totalCryptoValue' | 'totalFiatValue' | 'lockedFiatValue',
) =>
  arr.reduce((sum: BigNumber, { [type]: value }): BigNumber => {
    if (value.isNaN()) {
      return sum;
    }

    return new BigNumber(sum).plus(value);
  }, new BigNumber(0));

export const getTotalInValue = (total: number | BigNumber, defaultV: number): BigNumber => {
  if (Number.isNaN(defaultV) || Number.isNaN(Number(total)) || total == null || defaultV == null) {
    return new BigNumber(NaN);
  }

  return new BigNumber(total).times(defaultV);
};

export function hasLowBalance(totalValue: BigNumber) {
  return totalValue.isNaN() || totalValue.lte(CONSTANTS.BALANCE_HIDE_EUR_VALUE);
}

export const isSearchedFor = (info: { searchStr: string; coinName?: string; currencyCode: string }) =>
  filterBy(['coinName', 'currencyCode'])(info.searchStr)({
    coinName: info.coinName || '',
    currencyCode: info.currencyCode,
  });

export const filterBySearchAndSortByPortfolioSize = <T extends { portfolioSize: number; currencyCode: string }>(
  balances: Array<T>,
  search: string | undefined,
  getCurrencyName: (currencyCode: string) => string | undefined,
) => {
  const list = orderBy(({ portfolioSize }) => portfolioSize || 0, ['desc'], balances) as Array<T>;

  if (search && search !== '') {
    const filterFn = filterBy(['currencyName', 'currencyCode'])(search);
    const extendedWithNameList = list.map((b) => ({ ...b, currencyName: getCurrencyName(b.currencyCode) }));

    return extendedWithNameList.filter(filterFn);
  }

  return list;
};

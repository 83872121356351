<script lang="ts" setup>
import { OtInputText } from '@1trading/design-system';
import { ref } from 'vue';

import { capToggleStatusBar } from '@exchange/libs/utils/capacitor/src';

import useInputFocusFallback from './useInputFocusFallback';

const inputTextRef = ref<InstanceType<typeof OtInputText> | null>();

const { onFocusCb, onBlurCb } = useInputFocusFallback();

const onFocus = () => {
  const elem = inputTextRef.value?.inputTextRef;

  if (elem) {
    onFocusCb(elem);
  }
};
const onBlur = () => {
  onBlurCb();
};
const onToggleInputState = (value: boolean) => {
  capToggleStatusBar(value);
};
</script>

<template>
  <ot-input-text
    ref="inputTextRef"
    class="x-input-text"
    v-bind="$attrs"
    @focus="onFocus"
    @blur="onBlur"
    @toggle-input-state="onToggleInputState"
  >
    <template
      v-for="(_, slot) of $slots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
  </ot-input-text>
</template>

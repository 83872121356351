/* eslint-disable @typescript-eslint/no-explicit-any */
import { ref, Ref } from 'vue';

import { toastManagerInstance, SimpleToast } from '@exchange/libs/toasts/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

export interface UsePromiseResult<T> {
  results: Ref<T | null>;
  loading: Ref<boolean>;
  error: Ref<any | null>;
  createPromise: (...args: any[]) => Promise<void>;
}

export interface UsePromiseResultThrowing<T> {
  results: Ref<T | null>;
  loading: Ref<boolean>;
  createPromise: (...args: any[]) => Promise<void>;
}

export interface UsePromiseResultWithCB<T> {
  loading: Ref<boolean>;
  error: Ref<any | null>;
  createPromise: (...args: any[]) => Promise<T>;
}

export const displayDefaultFailedToast = () => {
  toastManagerInstance.addToast({
    content: SimpleToast,
    props: {
      variant: 'failed',
      title: 'fundamentals.toasts.failed',
      message: 'fundamentals.error.text',
    },
  });
};

export default function usePromise<U>(fnPromise): UsePromiseResult<U> {
  const results = ref(null);
  const loading = ref(false);
  const error = ref<unknown | null>(null);

  const createPromise = async (...args) => {
    loading.value = true;
    error.value = null;
    results.value = null;

    try {
      results.value = await fnPromise(...args);
    } catch (err) {
      error.value = err;
    } finally {
      loading.value = false;
    }
  };

  return {
    results,
    loading,
    error,
    createPromise,
  };
}

export function usePromiseThrowing<U>(fnPromise): UsePromiseResultThrowing<U> {
  const results = ref(null);
  const loading = ref(false);

  const createPromise = async (...args) => {
    loading.value = true;
    results.value = null;

    try {
      results.value = await fnPromise(...args);
    } finally {
      loading.value = false;
    }
  };

  return {
    results,
    loading,
    createPromise,
  };
}

export function usePromiseWithCB<T>(fnPromise, withDefaultFailedToast = false, fnError: (err: unknown) => void = () => {}): UsePromiseResultWithCB<T> {
  const loading = ref(false);
  const error = ref(null);

  const createPromise = async (...args) => {
    loading.value = true;
    error.value = null;

    return fnPromise(...args)
      .catch((err) => {
        error.value = err;
        logger.log(err);

        if (withDefaultFailedToast) {
          displayDefaultFailedToast();
        }

        fnError?.(err);
      })
      .finally(() => {
        loading.value = false;
      });
  };

  return {
    loading,
    error,
    createPromise,
  };
}

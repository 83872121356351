import forOwn from 'lodash/forOwn';

// eslint-disable-next-line @typescript-eslint/ban-types
function inPlaceReplace<T>(obj: object, pred: (key: T) => boolean, update: (value: T) => T): void {
  forOwn(obj, (_v, k) => {
    if (typeof obj[k] === 'object') {
      inPlaceReplace(obj[k], pred, update);
    } else if (pred(obj[k])) {
      // eslint-disable-next-line no-param-reassign
      obj[k] = update(obj[k]);
    }
  });
}

export default inPlaceReplace;

import { debounce } from 'lodash/fp';

const useResize = () => {
  function setCssCustomVarVh() {
    /** Getting the viewport height and multiplying it by 1% to get a value for a vh unit */
    const vh = window.innerHeight * 0.01;

    /* Setting the value in the --vh custom property to the root of the document */
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  const debouncedSetCssCustomVarVh = debounce(400, setCssCustomVarVh);

  const startListeningForResize = () => {
    debouncedSetCssCustomVarVh();
    window.addEventListener('resize', debouncedSetCssCustomVarVh);
  };

  const stopListeningForResize = () => {
    window.removeEventListener('resize', debouncedSetCssCustomVarVh);
  };

  return {
    debouncedSetCssCustomVarVh,
    startListeningForResize,
    stopListeningForResize,
  };
};

export default useResize;

import { ref, shallowRef, watchEffect } from 'vue';
import type { RouteLocationNormalized } from 'vue-router';

import { authService } from '@exchange/libs/auth/service/src';
import { modalService } from '@exchange/libs/modals/src';
import WebRest from '@exchange/libs/rest-api/web-api';
import { settingsService } from '@exchange/libs/settings/service/src';
import { launchdarkly } from '@exchange/libs/utils/launchdarkly/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';
import { checkIsBiometricRoute } from '@exchange/routing';

import GeneralToSModal from './GeneralToSModal.vue';
import TermsAndConditionsModal from './TermsAndConditionsModal.vue';

export const useGlobalConfirmationModals = (route: RouteLocationNormalized) => {
  const modalGeneralToSKey = ref<string>();
  const modalTermAndConditionsKey = ref<string>();

  let showGeneralToSModalPromise = Promise.resolve();
  let showTermAndConditionsModalPromise = Promise.resolve();

  const resolveGeneralToS = () => {
    showGeneralToSModalPromise = Promise.resolve();

    return showGeneralToSModalPromise;
  };
  const hideGeneralToSModal = () => {
    if (modalGeneralToSKey.value) {
      modalService.hide(modalGeneralToSKey.value);
      modalGeneralToSKey.value = undefined;
    }
  };
  const showGeneralToSModal = async () => {
    const { showRiskConfirmation } = settingsService.complianceInfo;

    if (showRiskConfirmation === false) {
      if (modalGeneralToSKey.value) {
        hideGeneralToSModal();
      }

      return resolveGeneralToS();
    }

    if (showRiskConfirmation === undefined || (showRiskConfirmation && modalGeneralToSKey.value)) {
      return resolveGeneralToS();
    }

    return new Promise<void>((resolve) => {
      modalGeneralToSKey.value = modalService.show(
        shallowRef(GeneralToSModal),
        {
          accept: () => {
            settingsService.setSettings({
              path: 'riskAgreement',
              value: new Date().toString(),
            });
            hideGeneralToSModal();
            resolveGeneralToS();
            resolve();
          },
        },
        {},
        { canClose: false },
      );
    });
  };
  const handleGeneralToS = () => {
    showGeneralToSModalPromise = showGeneralToSModalPromise.then(showGeneralToSModal);

    return showGeneralToSModalPromise;
  };

  const resolveTermAndConditionsModal = () => {
    showTermAndConditionsModalPromise = Promise.resolve();

    return showTermAndConditionsModalPromise;
  };
  const hideTermAndConditionsModal = () => {
    if (modalTermAndConditionsKey.value) {
      modalService.hide(modalTermAndConditionsKey.value);
      modalTermAndConditionsKey.value = undefined;
    }
  };
  const showTermAndConditionsModal = async () => {
    // There is a flag in launchdarkly for the terms and conditions date
    // { publishedDate: '2024-11-05', effectiveDate: '2024-12-07' }
    // We only need to check for an existing agreement if we're before the effective date
    // If we're after the effective date, we don't need to check for an existing agreement
    // If we're on or after the effective date, we show the modal, and store that the user has acknowledged

    const termsConfig = await launchdarkly.getWhenReady('terms-and-conditions-date');
    if (!termsConfig?.publishedDate || !termsConfig?.effectiveDate) {
      logger.warn('No terms date configuration available from LaunchDarkly');
      return resolveTermAndConditionsModal();
    }

    const currentDate = new Date();
    const effectiveDate = new Date(termsConfig.effectiveDate);

    // Skip if we're after the effective date
    if (currentDate > effectiveDate) {
      return resolveTermAndConditionsModal();
    }

    // Check for existing agreement
    const existingAgreement = await WebRest.Customer.Agreement.getTermsAgreement(termsConfig.publishedDate);
    if (existingAgreement) {
      return resolveTermAndConditionsModal();
    }

    return new Promise<void>((resolve) => {
      modalTermAndConditionsKey.value = modalService.show(
        shallowRef(TermsAndConditionsModal),
        {
          accept: async () => {
            try {
              await WebRest.Customer.Agreement.setTermsAgreement(termsConfig.publishedDate);
              hideTermAndConditionsModal();
              resolveTermAndConditionsModal();
              resolve();
            } catch (e) {
              logger.error('Accepting T&C failed: ', e);
            }
          },
          effectiveDate: termsConfig.effectiveDate,
        },
        {},
        { canClose: false },
      );
    });
  };
  const handleTermAndConditionsModal = () => {
    showTermAndConditionsModalPromise = showTermAndConditionsModalPromise.then(showTermAndConditionsModal);

    return showTermAndConditionsModalPromise;
  };

  watchEffect(async () => {
    if (checkIsBiometricRoute(route)) {
      return;
    }

    try {
      if (authService.isAuthenticated) {
        await handleTermAndConditionsModal();
      }

      if (settingsService.complianceInfo.showRiskConfirmation) {
        await handleGeneralToS();
      }

      if (settingsService.complianceInfo.showRiskConfirmation === false) {
        resolveGeneralToS();
        hideGeneralToSModal();
      }
    } catch (error) {
      logger.error('Error in global confirmation modals:', error);
    }
  });
};

export default useGlobalConfirmationModals;

import type { Bytes } from 'firebase/firestore';
import isEmpty from 'lodash/isEmpty';
import { isMobileOnly } from 'mobile-device-detect';
import { reactive, ref, type Ref, watch } from 'vue';

import fulfillWithTimeLimit from '@exchange/helpers/fulfill-with-time-limit';
import persistanceHelper from '@exchange/helpers/persistance-helper';
import { accountService } from '@exchange/libs/account/service/src';
import { authService } from '@exchange/libs/auth/service/src';
import { firebaseService } from '@exchange/libs/firebase/src';
import { marketService, filterMarketBasedOnSearchStr } from '@exchange/libs/market/service/src';
import type { PricetickModel } from '@exchange/libs/price-ticks/service/src';
import { priceTicksService } from '@exchange/libs/price-ticks/service/src';
import { toastManagerInstance, SimpleToast } from '@exchange/libs/toasts/src';
import { capIsNativePlatform } from '@exchange/libs/utils/capacitor/src';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';
import { langsInfoService } from '@exchange/libs/utils/langs-info/src';
import { launchdarkly } from '@exchange/libs/utils/launchdarkly/src';
import { retryService } from '@exchange/libs/utils/retry/src';
import { serverClientTimeService } from '@exchange/libs/utils/server-client-time/src';
import { logger, nonProdConsoleInfo } from '@exchange/libs/utils/simple-logger/src';
import { widget as TradingViewWidget } from '@exchange-public/charting_library';
// eslint-disable-next-line max-len
import type { ResolutionString, SubscribeEventsMap } from '@exchange-public/charting_library/charting_library.d'; // please be aware https://github.com/tradingview/charting_library/issues/5187#issuecomment-667097792
import tvPrefixes from '@exchange-public/charting_library/tv-prefix';

import { candlesticksSubsIdentifier, granularityToMilliseconds, mapResolution, ProSubsIdentifier } from './candlesticks-model';
import { hackChartData, prepareChartDataForSaving, prepareChartDataForApplying, type RowDrawings } from './drawing-helpers';
import { createCandle, CurrentCandle, getCandles, updateCandle } from './helpers';
import SymbolInfo, { chartIntervals, datafeedConfiguration } from './model';
import getWidgetOptions, { getOverrides, TV_CHART_CONTAINER } from './widget-options';

type ChartProcessedData = {
  data: undefined | RowDrawings;
  solicitedIfPreferOldColors: null | boolean;
};

type RestoreSavedChartsResult = {
  data: undefined | ChartProcessedData;
  successful: boolean;
};

type TVSubsIdentifier = string;
type RealTimeCallback = {
  subscriberUID: string;
  onRealtimeCallback: TradingView.SubscribeBarsCallback;
  ticker: string;
  identifier: ProSubsIdentifier;
};

interface SubscriberData {
  priceTickCache: Array<PricetickModel>;
  currentCandle: CurrentCandle | undefined;
  realTimeCallbacks: RealTimeCallback | undefined;
  resetCacheNeededCallback: (() => void) | undefined;
  unsubscribePriceticks: (() => void) | undefined;
  stopWatching: (() => void) | undefined;
}

type MarketId = string;

type CreateWidgetParams = {
  marketId: string;
  isXSmallScreen: boolean;
  theme: TradingView.ThemeName;
};

type UpdateSolicited = (i: { solicitedOnly: boolean }) => Promise<void>;

class TvChart {
  private readonly logPrefix = 'TvChart:';

  public dialWithResettingDrawingColors: undefined | ((f: UpdateSolicited) => Promise<void>) = undefined;

  public widget: TradingView.IChartingLibraryWidget | null = null;

  public widgetIsReady = ref(false);

  public savedDrawingsInfo = reactive({
    exist: false,
    restorationComplete: false,
    restoredSuccessfully: false,
  });

  private savedDrawingsData: ChartProcessedData = {
    data: undefined,
    solicitedIfPreferOldColors: null,
  };

  public dataIsLoading = ref<Record<MarketId, boolean>>({});

  private intervalInternal: ResolutionString = persistanceHelper.getObjFromJSON(CONSTANTS.TV_CHART_INTERVAL_KEY, chartIntervals.d.value) as ResolutionString;

  private subscribersData: Record<ProSubsIdentifier, SubscriberData | undefined> = {};

  private subscribersMap: Record<TVSubsIdentifier, ProSubsIdentifier | undefined> = {};

  private get interval() {
    return this.intervalInternal;
  }

  private set interval(v: ResolutionString) {
    this.intervalInternal = v;
    persistanceHelper.localstorageSet(CONSTANTS.TV_CHART_INTERVAL_KEY, v);
  }

  private get timeGranularity() {
    return mapResolution(this.interval);
  }

  private saveChartToServerPromise = Promise.resolve();

  private getDefaultSubscriberData = () => ({
    priceTickCache: [],
    currentCandle: undefined,
    realTimeCallbacks: undefined,
    resetCacheNeededCallback: undefined,
    unsubscribePriceticks: undefined,
    stopWatching: undefined,
  });

  private getCurrentSubscriber = (identifier: ProSubsIdentifier) => {
    let curSub = this.subscribersData[identifier];

    if (!curSub) {
      curSub = this.getDefaultSubscriberData();
      this.subscribersData[identifier] = curSub;
    }

    return curSub;
  };

  private setDataIsLoading = (marketId: string, value: boolean) => {
    this.dataIsLoading.value[marketId] = value;
  };

  private updateSolicited: UpdateSolicited = async ({ solicitedOnly }) => {
    this.savedDrawingsData.solicitedIfPreferOldColors = true;

    try {
      const subaccountId = await accountService.getAccountId({ useSubaccount: true });

      if (solicitedOnly || !this.savedDrawingsData.data) {
        await firebaseService.getChartsCollection().update(`${subaccountId}.solicited`, true);
      } else {
        const data = prepareChartDataForSaving(this.savedDrawingsData.data);

        await firebaseService.getChartsCollection().update(subaccountId, {
          data: data.data,
          solicited: true,
        });
      }
    } catch (e) {
      logger.error(`${this.logPrefix} error during color reset`, e);
    }
  };

  /**
   * Is called when ChL first loads, pass datafeed configuration options to cb
   * https://github.com/tradingview/charting_library/wiki/JS-Api#onreadycallback
   *
   * Events subscriptions
   * https://github.com/tradingview/charting_library/wiki/Widget-Methods#subscribing-to-chart-events
   */
  private onWidgetReady(callback) {
    this.widgetIsReady.value = true;

    return setTimeout(() => {
      callback(datafeedConfiguration);

      this.widget?.onChartReady(async () => {
        if (this.savedDrawingsData.solicitedIfPreferOldColors === null) {
          this.widget?.applyOverrides(getOverrides());

          if (this.savedDrawingsInfo.restoredSuccessfully && !this.savedDrawingsInfo.exist) {
            this.savedDrawingsData.solicitedIfPreferOldColors = true;
          }

          if (this.savedDrawingsInfo.restoredSuccessfully && this.savedDrawingsInfo.exist && this.dialWithResettingDrawingColors) {
            await this.dialWithResettingDrawingColors(this.updateSolicited);
          }
        }

        if (this.savedDrawingsInfo.restoredSuccessfully) {
          this.widget?.subscribe('onAutoSaveNeeded', this.saveChartToServer); /** https://github.com/tradingview/charting_library/wiki/Widget-Methods#subscribeevent-callback */
        }

        this.widget
          ?.chart()
          .onIntervalChanged()
          .subscribe(null, (newInterval: ResolutionString) => {
            /** https://github.com/tradingview/charting_library/wiki/Chart-Methods#onintervalchanged */
            this.interval = newInterval;
          });
      });
    });
  }

  private async restoreSavedCharts(marketId: string, accountId: string, attempt = 0): Promise<RestoreSavedChartsResult> {
    try {
      const subaccountId = await accountService.getAccountId({ useSubaccount: true });
      const savedDrawings = await firebaseService.getChartsCollection().get({ subaccountId });

      const defaultEmptyState = {
        data: {
          data: undefined,
          solicitedIfPreferOldColors: null,
        },
        successful: true,
      };

      if (!savedDrawings || isEmpty(savedDrawings)) {
        return defaultEmptyState;
      }

      const accountSavedDrawings = prepareChartDataForApplying(savedDrawings, subaccountId);

      if (!isEmpty(accountSavedDrawings.data)) {
        const data = hackChartData(accountSavedDrawings.data, marketId);

        return {
          data: {
            data,
            solicitedIfPreferOldColors: accountSavedDrawings.solicited,
          },
          successful: true,
        };
      }

      return defaultEmptyState;
    } catch (e) {
      logger.error(`${this.logPrefix} restore charts error; attempt ${attempt}`, e);

      if (attempt < 3) {
        await retryService.waitForNextRetryTick();
        return this.restoreSavedCharts(marketId, accountId, attempt + 1);
      }

      toastManagerInstance.addToast({
        content: SimpleToast,
        props: {
          variant: 'failed',
          title: 'fundamentals.error.title',
          message: 'modules.charting.restoreSavedCharts.failed.text',
        },
      });

      return {
        data: undefined,
        successful: false,
      };
    }
  }

  /**
   * Is called when ChL needs to get SymbolInfo by symbol name, pass symbolInfo object to onSymbolResolvedCallback
   * https://github.com/tradingview/charting_library/wiki/JS-Api#resolvesymbolsymbolname-onsymbolresolvedcallback-onresolveerrorcallback
   */
  private async resolveSymbol(symbolName: string, onResolve: TradingView.ResolveCallback) {
    const markets = await marketService.awaitMarkets();
    const marketId = symbolName.replace('/', '_').replace(/\s/g, '').replace(/:/g, '');
    const market = markets[marketId];

    if (!market) {
      logger.error(`${this.logPrefix} there is no market info for ${symbolName} symbol`);
      return;
    }

    const symbol = new SymbolInfo(market);

    setTimeout(() => {
      try {
        onResolve(symbol);
      } catch (e) {
        if ((e as Error).message === "Cannot read property 'seriesTurnaround' of null") {
          // This happens when user switched the Mobile tab and Tradingview is gone already;
          return;
        }

        logger.error(`${this.logPrefix} Resolve symbol error:`, e);
        throw e;
      }
    });
  }

  /**
   * Is called when the ChL needs a history fragment defined by dates range, pass array of ohlcv objects with UTC time in milliseconds to onHistoryCallback
   * https://github.com/tradingview/charting_library/wiki/JS-Api#getbarssymbolinfo-resolution-from-to-onhistorycallback-onerrorcallback-firstdatarequest
   */
  private async getBars(
    symbolInfo: TradingView.LibrarySymbolInfo,
    resolution: TradingView.ResolutionString,
    periodParams: TradingView.PeriodParams,
    onHistoryCallback: TradingView.HistoryCallback,
    onErrorCallback: TradingView.ErrorCallback,
  ) {
    const { from, to, countBack, firstDataRequest } = periodParams;
    const { ticker: marketId } = symbolInfo;

    if (!marketId) {
      throw new Error(`${this.logPrefix} getBars - market ticker undefined`);
    }

    try {
      const granularity = mapResolution(resolution);
      const identifier = candlesticksSubsIdentifier.getIdentifier({
        instrument_code: marketId,
        time_granularity: granularity,
      });

      const curSub = this.getCurrentSubscriber(identifier);

      const { candles, noData, nextTime } = await getCandles({
        marketId,
        from,
        to,
        countBack,
        granularity,
        firstDataRequest,
      });

      onHistoryCallback(candles, {
        noData, // noData should be set if there is no data in the requested period.
        // eslint-disable-next-line max-len
        nextTime, // nextTime is a time of the next bar in the history. It should be set if the requested period represents a gap in the data. Hence there is available data prior to the requested period.
      });

      if (firstDataRequest) {
        // we cannot have a live chart if there is only one candle
        if (candles.length < 2) {
          // don't set the currentCandle to disable live updating chart
          logger.warn(`${this.logPrefix} Insufficient Historical Data: Live chart disabled`);

          return;
        }

        const timeframe = granularityToMilliseconds(granularity);
        const lc = candles[candles.length - 1];

        if (lc) {
          const endAt = lc.time + timeframe - 1;

          curSub.currentCandle = { ...lc, endAt };
        }
      }

      (curSub.priceTickCache || [])
        .sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime())
        .forEach((priceTick) => this.addPriceTickToCandle(identifier, priceTick));

      curSub.priceTickCache = [];
    } catch (error) {
      const message = `Fetching candles for ${symbolInfo.ticker} failed`;

      let e = error;

      try {
        e = JSON.stringify(error);
      } catch (_) {
        // ignore
      }

      logger.error(
        message,
        `marketId: ${marketId}`,
        `resolution: ${resolution}`,
        `from: ${new Date(from)}`,
        `to: ${new Date(to)}`,
        `firstDataRequest: ${firstDataRequest}`,
        `error: ${e}`,
      );
      onErrorCallback(message);
    } finally {
      if (marketId) {
        this.setDataIsLoading(marketId, false);
      }
    }
  }

  private subscribeToPriceTicks = async (identifier: ProSubsIdentifier, ticker: string) => {
    try {
      const curSub = this.getCurrentSubscriber(identifier);

      await new Promise<void>((res, rej) => {
        const unsubscribePriceticks = priceTicksService.subscribe(ticker, {
          success: (data: { priceTicks: Ref<PricetickModel[]> }) => {
            if (curSub && curSub.stopWatching) {
              curSub.stopWatching();
            }

            curSub.stopWatching = watch(data.priceTicks, (priceTicks) => {
              const priceTick = priceTicks[0];

              if (priceTick) {
                this.addPriceTickToCandle(identifier, priceTick);
              } else {
                logger.warn(`${this.logPrefix} first pricetick was undefined`);
              }
            });
            res();
          },
          fail: (reason) => rej(reason),
        });

        curSub.unsubscribePriceticks = unsubscribePriceticks;
      });
    } catch (e) {
      logger.error(`${this.logPrefix} subscribe failed`, e);
      await retryService.waitForNextRetryTick();
      await this.subscribeToPriceTicks(identifier, ticker);
    }
  };

  /**
   * Is called when the chart symbol or resolution is changed, or whenever the chart needs to subscribe to a new symbol.
   * https://github.com/tradingview/charting_library/wiki/JS-Api#subscribebarssymbolinfo-resolution-onrealtimecallback-subscriberuid-onresetcacheneededcallback
   */
  private async subscribeBars(
    symbolInfo: TradingView.LibrarySymbolInfo,
    resolution: string,
    onRealtimeCallback: TradingView.SubscribeBarsCallback,
    subscriberUID: string,
    onResetCacheNeededCallback: () => void,
  ) {
    const { ticker } = symbolInfo;

    if (!ticker) {
      throw new Error(`${this.logPrefix} subscribeBars - market ticker undefined`);
    }

    const timeGranularity = mapResolution(resolution);

    const identifier = candlesticksSubsIdentifier.getIdentifier({
      instrument_code: ticker,
      time_granularity: timeGranularity,
    });

    this.subscribersMap[subscriberUID] = identifier;
    const curSub = this.getCurrentSubscriber(identifier);

    curSub.resetCacheNeededCallback = onResetCacheNeededCallback;
    curSub.realTimeCallbacks = {
      subscriberUID,
      onRealtimeCallback,
      ticker,
      identifier,
    };

    await this.subscribeToPriceTicks(identifier, ticker);
  }

  /**
   * Is called when ChL doesn't want to receive updates for this subscriber any more
   * https://github.com/tradingview/charting_library/wiki/JS-Api#unsubscribebarssubscriberuid
   */
  private unsubscribeBars(subscriberUID: TVSubsIdentifier) {
    const identifier = this.subscribersMap[subscriberUID];

    if (!identifier) {
      logger.info(`${this.logPrefix} cannot unsubscribe from ${subscriberUID}, unknown identifier`);

      return;
    }

    const curSub = this.subscribersData[identifier];

    if (!curSub) {
      if (launchdarkly.flags.getLogging().tv) {
        logger.info(`${this.logPrefix} cannot unsubscribe from ${subscriberUID}, no such subscriber ${identifier}`);
      }

      return;
    }

    curSub.priceTickCache = [];
    curSub.currentCandle = undefined;

    curSub.stopWatching?.();
    curSub.unsubscribePriceticks?.();

    delete this.subscribersData[identifier];
  }

  /**
   * Is called when ChL needs to know the server time, time is provided without milliseconds.
   * https://github.com/tradingview/charting_library/wiki/JS-Api#getservertimecallback
   */

  private async getServerTime(callback: (unixTime: number) => void) {
    try {
      const getReputedlyServerTime = await serverClientTimeService.getReputedlyServerTime();

      callback(getReputedlyServerTime / 1000);
    } catch (e) {
      logger.log(`${this.logPrefix} Obtaining time failed:`, e);
      await retryService.waitForNextRetryTick();
      this.getServerTime(callback);
    }
  }

  /**
   * This call is intended to provide the list of symbols that match the user's search query.
   * https://github.com/tradingview/charting_library/wiki/JS-Api#searchsymbolsuserinput-exchange-symboltype-onresultreadycallback
   */
  private async searchSymbols(userInput: string, _exchange, _symbolType, onResultReadyCallback) {
    function nonNullable<T>(value: T): value is NonNullable<T> {
      return value !== null && value !== undefined;
    }

    const { markets } = marketService;
    const allMarketsList = Object.values(markets.value).filter(nonNullable);
    let filteredMarkets = allMarketsList;

    if (allMarketsList) {
      const searchFilter = filterMarketBasedOnSearchStr(userInput);

      filteredMarkets = allMarketsList.filter(searchFilter);
    }

    const formattedSymbols = filteredMarkets.map((symbol) => {
      const s = new SymbolInfo(symbol);

      return {
        symbol: s.name,
        full_name: s.full_name,
        description: s.description,
        exchange: s.exchange,
        type: s.type,
      };
    });

    onResultReadyCallback(formattedSymbols);
  }

  private addPriceTickToCandle(identifier: ProSubsIdentifier, priceTick: PricetickModel) {
    const { instrumentCode } = priceTick;

    const curSub = this.getCurrentSubscriber(identifier);

    const { currentCandle } = curSub;

    if (!currentCandle || this.dataIsLoading.value[instrumentCode]) {
      // wait for the first snapshot and cache all the candles
      curSub.priceTickCache.push(priceTick);

      return;
    }

    if (currentCandle.last_sequence >= priceTick.sequence) {
      if (launchdarkly.flags.getLogging().tv) {
        // ignore sequence numbers that are already in the candle snapshot
        logger.warn(`${this.logPrefix}ignored pricetick for ${identifier} because of sequence ${priceTick.sequence}`);
      }

      return;
    }

    const state = {
      currentCandle,
      timeGranularity: this.timeGranularity,
      instrumentCode,
    };

    const priceTickIsInCurrentTimeFrame = priceTick.tzTime <= currentCandle.endAt;
    const candle = priceTickIsInCurrentTimeFrame
      ? updateCandle(priceTick.price, state, priceTick.amount, priceTick.sequence)
      : createCandle(state, priceTick.tzTime, priceTick.price, priceTick.amount, priceTick.sequence);

    nonProdConsoleInfo(
      // eslint-disable-next-line max-len
      `${this.logPrefix} addPriceTickToCandle\n\ntick: ${JSON.stringify(priceTick)}\n\nInCurrentFrame: ${priceTickIsInCurrentTimeFrame} => ${priceTickIsInCurrentTimeFrame ? 'UPDATE' : 'CREATE NEW'}\n\nCurC: ${JSON.stringify({ ...currentCandle, hTime: new Date(currentCandle.time), hEndAt: new Date(currentCandle.endAt) })}\n\nToSendC: ${JSON.stringify(
        {
          time: candle.time,
          hTime: new Date(candle.time),
          hEndAt: new Date(candle.endAt),
          close: candle.close,
          open: candle.open,
          high: candle.high,
          low: candle.low,
          volume: candle.volume,
        },
      )}`,
    );

    curSub.realTimeCallbacks?.onRealtimeCallback({
      time: candle.time,
      close: candle.close,
      open: candle.open,
      high: candle.high,
      low: candle.low,
      volume: candle.volume,
    });

    curSub.currentCandle = candle;
  }

  private deferredSetSymbol?: () => void;

  /**
   * https://github.com/tradingview/charting_library/wiki/Widget-Methods#setsymbolsymbol-interval-callback
   */
  public setSymbol = (marketId: string, isXSmallScreen: boolean, theme: TradingView.ThemeName) => {
    if (this.dataIsLoading.value[marketId]) {
      // We want to run this function after the current setSymbol is done
      this.deferredSetSymbol = () => this.setSymbol(marketId, isXSmallScreen, theme);
      return;
    }

    // at some point callback function was not called
    this.setDataIsLoading(marketId, true);

    const identifier = candlesticksSubsIdentifier.getIdentifier({
      instrument_code: marketId,
      time_granularity: this.timeGranularity,
    });

    const curSubscriber = this.subscribersData[identifier];

    if (curSubscriber) {
      curSubscriber.priceTickCache = [];
      curSubscriber.resetCacheNeededCallback?.();
    } else {
      this.subscribersData[identifier] = this.getDefaultSubscriberData();
    }

    try {
      this.widget?.activeChart().setSymbol(marketId, () => {
        this.setDataIsLoading(marketId, false);
        this.deferredSetSymbol?.();
        this.deferredSetSymbol = undefined;
      });
    } catch (error) {
      this.removeWidget();
      this.createWidget({
        marketId,
        isXSmallScreen,
        theme,
      });
    }
  };

  private resetSubscribersData = (and = { unsubscribeFromPriceticks: false }) => {
    Object.keys(this.subscribersData).forEach((identifier) => {
      const curSub = this.subscribersData[identifier];

      if (curSub) {
        curSub.priceTickCache = [];
        curSub.currentCandle = undefined;
      }

      curSub?.resetCacheNeededCallback?.();
      curSub?.stopWatching?.();

      if (and.unsubscribeFromPriceticks) {
        curSub?.unsubscribePriceticks?.();
      }
    });
  };

  /**
   * https://github.com/tradingview/charting_library/wiki/Chart-Methods#resetdata
   */
  public resetDataAndReRequestDataFromTheDataFeed = () => {
    const widgetIsReady = this.widgetIsReady.value;

    logger.info(`${this.logPrefix} Data reset requested; Is widget ready: ${widgetIsReady}`);

    this.resetSubscribersData();

    if (widgetIsReady && this.widget) {
      this.widget?.activeChart()?.resetData();
    }
  };

  public createWidget = async ({ marketId, isXSmallScreen, theme }: CreateWidgetParams): Promise<void> => {
    this.setDataIsLoading(marketId, true);
    let savedData: RestoreSavedChartsResult = {
      data: undefined,
      successful: false,
    };

    this.savedDrawingsData.data = undefined;
    this.savedDrawingsInfo.exist = Boolean(savedData.data);
    this.savedDrawingsInfo.restoredSuccessfully = false;
    this.savedDrawingsInfo.restorationComplete = false;

    if (authService.isAuthenticated && authService.accessTokenSubaccountData.accountId) {
      const sid = authService.accessTokenSubaccountData.accountId;

      savedData = await fulfillWithTimeLimit<RestoreSavedChartsResult>(this.restoreSavedCharts(marketId, sid), 7000, {
        data: undefined,
        successful: false,
      });

      this.savedDrawingsData.solicitedIfPreferOldColors = savedData.data?.solicitedIfPreferOldColors ?? null;
      this.savedDrawingsData.data = savedData.data?.data;
      this.savedDrawingsInfo.exist = Boolean(savedData.data?.data);
      this.savedDrawingsInfo.restoredSuccessfully = savedData.successful;
      this.savedDrawingsInfo.restorationComplete = true;

      if (savedData.successful && !this.savedDrawingsInfo.exist && authService.idTokenData.bitpandaId && sid === authService.idTokenData.bitpandaId) {
        logger.info(`${this.logPrefix} Restoring charts succeeded, but there is no saved data. Setting initial data for ${sid}`);
        firebaseService.getChartsCollection().set({ [sid]: { data: null, solicited: true } }, { subaccountId: sid });
      }

      if (!savedData.successful) {
        logger.info(`${this.logPrefix} Restoring charts did not succeed, chart-auto-save feature will be disabled`);
      }
    }

    const datafeed = {
      onReady: this.onWidgetReady.bind(this),
      resolveSymbol: this.resolveSymbol.bind(this),
      getBars: this.getBars.bind(this),
      subscribeBars: this.subscribeBars.bind(this),
      unsubscribeBars: this.unsubscribeBars.bind(this),
      getServerTime: this.getServerTime.bind(this),
      searchSymbols: this.searchSymbols.bind(this),
    };

    const coreLang = langsInfoService.language.value;
    const tvLocale = tvPrefixes.includes(coreLang) ? coreLang : langsInfoService.fallbackLang;

    const wSetting = {
      datafeed,
      ...getWidgetOptions({
        market: marketId,
        interval: this.interval,
        locale: tvLocale as TradingView.LanguageCode,
        isMobileOnly,
        isNativeApp: capIsNativePlatform(),
        debug: launchdarkly.flags.getLogging().tv,
        enableGoTo: launchdarkly.flags.getChartingLibraryEnableGoTo(),
        isXSmallScreen,
        theme,
      }),
      container: TV_CHART_CONTAINER,
      saved_data: savedData.data?.data,
    };

    const identifier = candlesticksSubsIdentifier.getIdentifier({
      instrument_code: marketId,
      time_granularity: this.timeGranularity,
    });

    if (!document.getElementById(TV_CHART_CONTAINER)) {
      nonProdConsoleInfo('There is no DOM element to attach TradingView to. Aborting creation.');
      return;
    }

    this.removeWidget();
    this.widget = new TradingViewWidget(wSetting);
    this.subscribersData[identifier] = this.getDefaultSubscriberData();

    priceTicksService.onPriceTicksChannelReconnectAdd(this.resetDataAndReRequestDataFromTheDataFeed);
  };

  public removeWidget = () => {
    priceTicksService.onPriceTicksChannelReconnectDelete(this.resetDataAndReRequestDataFromTheDataFeed);

    this.widget?.remove();
    this.widget = null;
    this.widgetIsReady.value = false;
    this.resetSubscribersData({ unsubscribeFromPriceticks: true });
    this.subscribersData = {};
  };

  private saveChartData = async (subaccountId: string, v: { data: Bytes }) => {
    try {
      await firebaseService.getChartsCollection().update(subaccountId, {
        data: v.data,
        solicited: this.savedDrawingsData.solicitedIfPreferOldColors ?? null,
      });
    } catch (e) {
      logger.error(`${this.logPrefix} Saving chart failed`, e);
      toastManagerInstance.addToast({
        content: SimpleToast,
        props: {
          variant: 'failed',
          title: 'fundamentals.error.title',
          message: 'modules.charting.saveChartToServer.failed.text',
        },
      });
      await retryService.waitForNextRetryTick();
      await this.saveChartData(subaccountId, v);
    }
  };

  public saveChartToServer: SubscribeEventsMap['onAutoSaveNeeded'] = () => {
    if (!this.savedDrawingsInfo.restoredSuccessfully) {
      return;
    }

    this.widget?.save(async (state) => {
      const subaccountId = await accountService.getAccountId({ useSubaccount: true });
      const v = prepareChartDataForSaving(state as RowDrawings);

      this.saveChartToServerPromise = this.saveChartToServerPromise.then(() => this.saveChartData(subaccountId, v));
    });
  };
}

const tvChart = new TvChart();

export default tvChart;

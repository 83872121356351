<script lang="ts" setup>
import { type OtSelectOption } from '@1trading/design-system';
import { useVuelidate } from '@vuelidate/core';
import { required as requiredValidator } from '@vuelidate/validators';
import { computed, reactive, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  disabled: { type: Boolean, default: false },
  required: { type: Boolean, default: true },
  value: { type: Array as PropType<Array<OtSelectOption>>, required: true },
});

const { t } = useI18n({ useScope: 'global' });

const dummyCheck = () => true;
const validationRules = {
  internalVModel: { required: props.required ? requiredValidator : dummyCheck },
};

const v = useVuelidate(validationRules, reactive({ internalVModel: props.value }));
const validationErrorMessages = computed(() => ({
  internalVModel: {
    required: t('fundamentals.multiSelect.required'),
  },
}));
</script>

<template>
  <ot-input-error-wrapper
    :error="v.internalVModel"
    :error-messages="validationErrorMessages.internalVModel"
    :disabled="disabled"
  >
    <ot-select
      v-bind="$attrs"
      v-model:value="v.internalVModel.$model"
      class="x-select-multi"
      :disabled="disabled"
      :multiple="true"
    />
  </ot-input-error-wrapper>
</template>

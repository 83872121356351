<script lang="ts" setup>
import { computed, inject } from 'vue';

import { CONSTANTS } from '@exchange/libs/utils/constants/src';

const props = defineProps({
  time: {
    type: [Date, String, Number],
    required: false,
  },
  format: {
    type: String,
  },
});

const utcToLocal = inject<(utcDateString: string | Date | number, formatString?: string) => string>('utcToLocal');
const formattedTime = computed(() => (props.time ? utcToLocal?.(props.time, props.format || CONSTANTS.DATE_FORMAT) || '' : ''));
</script>

<template>
  <span class="local-time">{{ formattedTime }}</span>
</template>

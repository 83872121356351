const helpers = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getObjFromJSON<T>(key: string, defaultValue: T, possibleValues?: any): T {
    const value = localStorage.getItem(key);

    if (value !== null && value !== 'undefined') {
      const parsedValue = JSON.parse(value);

      if (!possibleValues || Object.values(possibleValues).includes(parsedValue)) {
        return parsedValue;
      }
    }

    return defaultValue;
  },

  localstorageSet(key, value) {
    return localStorage.setItem(key, JSON.stringify(value));
  },

  localStorageDelete(key) {
    localStorage.removeItem(key);
  },
};

export const persistValue = (key: string) => (newValue: string | undefined, oldValue?: string) => {
  if (newValue !== oldValue) {
    helpers.localstorageSet(key, newValue);
  }
};

export const syncWithStorage = <T>(key: string, defaultValue: T) => {
  const value = helpers.getObjFromJSON(key, undefined);

  if (value) {
    return value;
  }

  helpers.localstorageSet(key, defaultValue);

  return defaultValue;
};

export default helpers;

import { format } from 'date-fns';

import { capDirectory, capFilesystem, capIsNativePlatform, capShare, capHttp } from '@exchange/libs/utils/capacitor/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

export const blobToBase64 = (blob: Blob): Promise<string | ArrayBuffer | null> => {
  const reader = new FileReader();

  reader.readAsDataURL(blob);

  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

const downloadWeb = ({ url }: { fileName: string; url: string }) => {
  if (!url) {
    return;
  }

  const tempLink = document.createElement('a');

  tempLink.setAttribute('href', url);
  document.body.appendChild(tempLink);
  tempLink.click();
  tempLink.remove();
};

const getBlobDataFromCSVUrl = async (url: string) => {
  try {
    const response = await capHttp.get({
      url,
      responseType: 'blob',
    });

    return response.data;
  } catch (e) {
    logger.error(`Unable get data from ${url}`, e);
    throw e;
  }
};

const downloadApps = async ({ fileName, url }: { fileName: string; url: string }) => {
  if (!url) {
    return;
  }

  const base64Data = await getBlobDataFromCSVUrl(url);

  if (!base64Data) {
    return;
  }

  const result = await capFilesystem
    .writeFile({
      path: fileName,
      data: base64Data,

      directory: capDirectory.External,
      recursive: true,
    })
    .catch((e) => {
      logger.error(`Unable to write ${fileName}`, e);
    });

  if (!result) {
    throw new Error(`Unable to write ${fileName}`);
  }

  await capShare
    .share({
      url: result.uri,
      dialogTitle: fileName,
    })
    .catch((e) => {
      logger.error(`Unable to share file ${result.uri}`, e);
      throw new Error(`Unable to share file ${result.uri}`);
    });
};

export default async function csvDownloader({ url, name, suffix, formatString }: { url: string; name: string; suffix?: string | number | boolean; formatString?: string }) {
  let fileName = name;

  if (fileName.indexOf('.csv') < 0) {
    fileName = `${fileName}.csv`;
  }

  if (suffix) {
    const defaultFormatString = 'MMM dd, yyyy';

    fileName =
      typeof suffix === 'string' || typeof suffix === 'number'
        ? fileName.replace('.csv', ` ${suffix}.csv`)
        : fileName.replace('.csv', ` ${format(new Date(), formatString ?? defaultFormatString)}.csv`);
  }

  const downloadParams: {
    fileName: string;
    url: string;
  } = {
    fileName,
    url,
  };

  if (capIsNativePlatform()) {
    return downloadApps(downloadParams);
  }

  return downloadWeb(downloadParams);
}

import memoize from 'lodash/memoize';

// https://en.wikipedia.org/wiki/Dijkstra%27s_algorithm

/* eslint-disable no-param-reassign */
const findLowestCostNode = (costs: { [key: string]: number }, processed: Array<string>): string | null =>
  Object.keys(costs).reduce((lowest: string | null, node) => {
    if (lowest === null && !processed.includes(node)) {
      lowest = node;
    }

    const cn = costs[node];
    // @ts-ignore TODO fix complaints
    const cl = costs[lowest];

    if (lowest !== null && cn !== undefined && cl !== undefined && cn < cl && !processed.includes(node)) {
      lowest = node;
    }

    return lowest;
  }, null);
/* eslint-enable no-param-reassign */

export type DijkstraGraph = { [key: string]: { [key: string]: number } };
export interface DijkstraResult {
  distance: number;
  path: Array<string>;
}

type DijkstraFn = (graph: DijkstraGraph, graphHash: string, start: string, finish: string) => DijkstraResult;

const resolver: (...args: Parameters<DijkstraFn>) => string = (_graph, graphHash, start, finish) => `${graphHash}//${start}//${finish}`;

const dijkstra = memoize<DijkstraFn>((graph, _graphHash, start, finish): DijkstraResult => {
  // track lowest cost to reach each node
  const trackedCosts = { [finish]: Infinity, ...graph[start] };

  // track paths
  const trackedParents: { [key: string]: string | null } = { [finish]: null };

  Object.keys(graph[start] || []).forEach((child) => {
    trackedParents[child] = start;
  });

  // track nodes that have already been processed
  const processedNodes: Array<string> = [];

  // set initial node. Pick lowest cost node.
  let node = findLowestCostNode(trackedCosts, processedNodes);

  while (node) {
    const costToReachNode = trackedCosts[node];
    const childrenOfNode = graph[node];

    if (childrenOfNode) {
      // eslint-disable-next-line no-loop-func, @typescript-eslint/no-loop-func
      Object.keys(childrenOfNode).forEach((child) => {
        const costFromNodetoChild = childrenOfNode[child];

        if (costToReachNode !== undefined && costFromNodetoChild !== undefined) {
          const costToChild = costToReachNode + costFromNodetoChild;
          const tcc = trackedCosts[child];

          if (!tcc || tcc > costToChild) {
            trackedCosts[child] = costToChild;
            trackedParents[child] = node;
          }
        }
      });
    }

    processedNodes.push(node);

    node = findLowestCostNode(trackedCosts, processedNodes);
  }

  const optimalPath = [finish];
  let parent = trackedParents[finish];

  while (parent) {
    optimalPath.push(parent);

    if (start === parent) {
      break;
    }

    parent = trackedParents[parent];
  }
  optimalPath.reverse();

  const results = {
    distance: trackedCosts[finish],
    path: optimalPath,
  };

  // @ts-ignore TODO fix complaints
  return results;
}, resolver);

export default dijkstra;

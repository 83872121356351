import { serverClientTimeService } from '@exchange/libs/utils/server-client-time/src';

enum AuthErrorCode {
  'invalid_request' = 'invalid_request',
  'unauthorized_client' = 'unauthorized_client',
  'access_denied' = 'access_denied',
  'unsupported_response_type' = 'unsupported_response_type',
  'invalid_scope' = 'invalid_scope',
  'server_error' = 'server_error',
  'temporarily_unavailable' = 'temporarily_unavailable',
  'interaction_required' = 'interaction_required',
  'login_required' = 'login_required',
  'account_selection_required' = 'account_selection_required',
  'consent_required' = 'consent_required',
  'invalid_request_uri' = 'invalid_request_uri',
  'invalid_request_object' = 'invalid_request_object',
  'request_not_supported' = 'request_not_supported',
  'request_uri_not_supported' = 'request_uri_not_supported',
  'registration_not_supported' = 'registration_not_supported',
  'invalid_client' = 'invalid_client',
  'invalid_grant' = 'invalid_grant',
  'unsupported_grant_type' = 'unsupported_grant_type',
}

export const capAuth2NoToastAuthenticateErrors = [
  /** https://github.com/moberwasserlechner/capacitor-oauth2/blob/c69621a93a58e21ab304742841f30c53bd988152/README.md#authenticate */ 'USER_CANCELLED',
  'ERR_NO_AUTHORIZATION_CODE',
  'ERR_STATES_NOT_MATCH',
  'ABNORMAL_CLIENT_TIME' /** does not come from capacitor-oauth2; can be thrown by auth service upon access token checks */,
];

export const AuthErrorNeedsLogin: Array<string> = [AuthErrorCode.invalid_grant, AuthErrorCode.login_required, AuthErrorCode.access_denied];

export type AuthErrorCodeUnion = `${AuthErrorCode}` | string;
export interface AuthError {
  /** web & ios. Represent auth error */
  error?: AuthErrorCodeUnion;
  /** android & ios. Same as `error` */
  message?: AuthErrorCodeUnion;
  /** android & ios
   * android: https://github.com/openid/AppAuth-Android/blob/ceb253b8118e481f3fe6f648772a8d16179a11fe/library/java/net/openid/appauth/AuthorizationException.java
   * iso: https://github.com/OAuthSwift/OAuthSwift/blob/master/Sources/OAuthSwiftError.swift
   */
  code?: number;
  /** web & ios. Represent auth error description */
  // eslint-disable-next-line camelcase
  error_description?: string;
}

export const AuthErrorClaimIssuedInFuture = 'iat is in the future';

export const checkErrorAndReactOnClaimIssueTime = ({ message, error }: { message?: AuthErrorCodeUnion; error?: AuthErrorCodeUnion }) => {
  if (message?.includes(AuthErrorClaimIssuedInFuture) || error?.includes(AuthErrorClaimIssuedInFuture)) {
    serverClientTimeService.showWrongClientTimeModal();
  }
};

export const isLocalStorageQuoteExceeded = (error) => error instanceof Error && error.name === 'QuotaExceededError';

export default AuthErrorCode;

<script lang="ts" setup>
import { type PropType } from 'vue';

import usePromise from '@exchange/libs/composables/shared/src/lib/usePromise';
import { modalVariant, type ModalVariant } from '@exchange/libs/modals/src';

const props = defineProps({
  accept: { type: Function as PropType<() => Promise<void>>, required: true },
  variant: { type: String as PropType<ModalVariant>, default: modalVariant.dark },
});

const { loading: accepting, createPromise: acceptNow } = usePromise(props.accept);
</script>

<template>
  <x-modal
    class="capital-gains-tax"
    :can-close="false"
    :wide="true"
    :variant="variant"
    :title="$t('ui.capitalGainsTax.title')"
  >
    <template #content>
      <p class="capital-gains-tax__message">{{ $t('ui.capitalGainsTax.message') }}</p>
    </template>
    <template #buttons>
      <x-button
        variant="primary"
        :loading="accepting"
        :disabled="accepting"
        @click="acceptNow"
        >{{ $t('fundamentals.agree') }}</x-button
      >
    </template>
  </x-modal>
</template>

export default async function fulfillWithTimeLimit<T>(task: Promise<T>, timeLimit: number, failureValue: T) {
  let timeout;
  const timeoutPromise = new Promise<T>((resolve) => {
    timeout = setTimeout(() => {
      resolve(failureValue);
    }, timeLimit);
  });
  const response = await Promise.race([task, timeoutPromise]);

  if (timeout) {
    clearTimeout(timeout);
  }

  return response;
}

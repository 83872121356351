/* eslint-disable @typescript-eslint/no-explicit-any */

export const getFirstItemsInChunks = (arr: Array<any>, partsCount = 6) => {
  if (arr.length === 0) {
    return [];
  }

  if (arr.length === 1) {
    return [arr[0]];
  }

  const chunkSize = arr.length / partsCount;
  const firstItems: Array<any> = [];

  for (let i = Math.ceil(chunkSize); i < arr.length; i += Math.ceil(chunkSize)) {
    const firstItem = arr[i];

    firstItems.push(firstItem);
  }

  return firstItems;
};

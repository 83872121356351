import { addMonths, endOfDay, startOfDay, subMonths } from 'date-fns';

export const endOfCurrentDay = (): string => endOfDay(new Date()).toISOString();

export function addNumberOfMonths(offset: number, date: number | Date): Date {
  return addMonths(date, offset);
}

export function subNumberOfMonths(offset: number, date: number | Date): Date {
  return subMonths(date, offset);
}

export const startOfCurrentDayMinus = (numberOfMonth: number): string => startOfDay(subNumberOfMonths(numberOfMonth, new Date())).toISOString();

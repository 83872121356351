<script lang="ts" setup>
import { OtLinkExternal } from '@1trading/design-system';

import { capIsNativePlatform } from '@exchange/libs/utils/capacitor/src';

import { nativeOpenLink } from './link-external-helpers';

const props = defineProps({
  href: { type: String, required: true },
  useSystemBrowser: { type: Boolean, default: false } /** Native only: Use to determine whether system or in-app browser is used. Ignored on web. */,
});
const emit = defineEmits<{
  (e: 'click', value: Event): void;
}>();

const isNative = capIsNativePlatform();

const onClick = (e: Event) => {
  emit('click', e);
  nativeOpenLink(props.href, props.useSystemBrowser);
};
</script>

<template>
  <ot-link-external
    class="x-link-external"
    :href="href"
    :is-native="isNative"
    :use-system-browser="useSystemBrowser"
    v-bind="$attrs"
    @click="onClick"
  >
    <slot />
  </ot-link-external>
</template>

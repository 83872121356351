/**
 * Generates a string with a number and their minimum increment..
 * @constructor
 * @param {number|string} precision - The precision of the desired number.
 *
 * Examples:
 * precision = 2 => generats 0.01
 * precision = 8 => generats 0.00000001
 */
export default function minimumIncrementForPrecision(precision: string | number): string {
  let zeros = '';

  for (let i = 1; i < +precision; i += 1) {
    zeros += '0';
  }

  return `0.${zeros}1`;
}

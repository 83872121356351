<script lang="ts">
export const OtSliderColorVariants = {
  default: 'default',
  primary: 'primary',
  secondary: 'secondary',
} as const;
export type OtSliderColorVariant = (typeof OtSliderColorVariants)[keyof typeof OtSliderColorVariants];
</script>

<script lang="ts" setup>
import { onMounted, ref, type PropType } from 'vue';
import VueSlider from 'vue-slider-component/dist/vue-slider-component.umd.min.js';

defineProps({
  fitTooltipMaxValue: { type: Boolean, default: false },
  colorVariant: { type: String as PropType<OtSliderColorVariant>, default: 'default' },
});

const sliderElem = ref();

onMounted(() => {
  /**
   * https://github.com/NightCatSama/vue-slider-component/issues/263
   */
  sliderElem.value?.$el?.getElementsByClassName('vue-slider-dot')[0]?.setAttribute('tabindex', '-1');
});
</script>

<template>
  <vue-slider
    ref="sliderElem"
    class="x-slider"
    v-bind="$attrs"
    :class="[`x-slider--${colorVariant}`, { 'x-slider--fit-tooltip-max': fitTooltipMaxValue }]"
  />
</template>

<style lang="scss">
@import '~vue-slider-component/theme/antd.css';

.x-slider {
  --ots-color: rgb(var(--v-theme-text-tertiary));

  &.vue-slider.vue-slider-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.vue-slider {
    --dot-handle-size: 6px;
    --dot-handle-border-color: var(--ots-color);
    --dot-handle-shadow-color: rgb(var(--v-theme-elevation-1));
    --dot-handle-box-shadow: 0 0 0 2px var(--dot-handle-shadow-color);
  }

  &.vue-slider:hover {
    --dot-handle-size: 10px;
    --dot-handle-border-color: rgb(var(--v-theme-text-primary));
    --dot-handle-shadow-color: var(--ots-color);
    --dot-handle-box-shadow: 0 0 6px 0 var(--dot-handle-shadow-color);
  }

  .vue-slider-mark-step-active {
    background-color: var(--ots-color);
  }

  // stylelint-disable-next-line no-descending-specificity
  &.vue-slider,
  &.vue-slider:hover {
    .vue-slider-rail {
      background-color: rgb(var(--v-theme-elevation-2));
    }

    .vue-slider-process {
      border-radius: 12px;
      background-color: var(--ots-color);
    }

    .vue-slider-dot-handle {
      width: var(--dot-handle-size);
      height: var(--dot-handle-size);
      flex: 0 0 var(--dot-handle-size);
      border: 2px solid var(--dot-handle-border-color);
      border-radius: 50%;
      background-color: var(--ots-color);
      box-shadow: var(--dot-handle-box-shadow);
      cursor: pointer;

      &.vue-slider-dot-handle-disabled {
        background-color: rgb(var(--v-theme-elevation-3));
        cursor: not-allowed;
        /* stylelint-disable */
        box-shadow: none;
        border: 0;
        /* stylelint-enable */
      }
    }

    .vue-slider-dot-handle-focus {
      border: 2px solid rgb(var(--v-theme-text-primary));
      background-color: var(--ots-color);
      box-shadow: 0 0 6px 0 var(--ots-color);
    }

    .vue-slider-mark-step-active {
      box-shadow: 0 0 0 2px rgb(var(--v-theme-elevation-1));
    }
  }

  .vue-slider-marks {
    cursor: pointer;
  }

  .vue-slider-mark-step {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgb(var(--v-theme-elevation-3));
    box-shadow: 0 0 0 2px rgb(var(--v-theme-elevation-1));
  }

  .vue-slider-mark-label {
    cursor: pointer;
    font-size: var(--font-size-sm);
    white-space: nowrap;
  }

  .vue-slider-mark {
    z-index: 4;

    &:first-child {
      .vue-slider-mark-label {
        left: 0;
        transform: translateX(-2px);
      }
    }

    &:last-child {
      .vue-slider-mark-label {
        left: 0;
        transform: translateX(-75%);
      }
    }
  }

  .vue-slider-dot {
    display: flex;
    align-items: center;
    justify-content: center;

    .xs & {
      padding: 12px;
    }
  }

  .vue-slider-dot-tooltip-inner {
    min-width: 18px;
    padding: 2px 4px;
    border: 1px solid var(--tooltip-background-color);
    border-radius: 5px;
    background-color: var(--tooltip-background-color);
    color: var(--tooltip-text-color);
    font-size: var(--font-size-xs);
    text-align: center;
    white-space: nowrap;
  }
}

.x-slider--primary {
  --ots-color: rgb(var(--v-theme-primary));
}

.x-slider--secondary {
  --ots-color: rgb(var(--v-theme-secondary));
}

.x-slider--fit-tooltip-max {
  .vue-slider-dot-tooltip-top {
    left: 30%;
  }

  .vue-slider-dot-tooltip-inner-top::after {
    left: 55%;
  }
}
</style>

<script lang="ts">
export default {
  name: 'XTransitionSlide',
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { isMobileSafari } from 'mobile-device-detect';
import { computed } from 'vue';

const props = defineProps({
  disableAnimation: { type: Boolean, required: false, default: false },
  isRouteAnimation: { type: Boolean, required: false, default: false },
  isXs: { type: Boolean, required: true },
  moveBack: { type: Boolean, required: false, default: false },
  push: { type: Boolean, required: false, default: false },
  triggeredByBackBtn: { type: Boolean, required: false, default: false },
});
const emit = defineEmits<{
  (e: 'transition-enter-started'): void;
  (e: 'transition-enter-finished'): void;
  (e: 'transition-finished', v: { detail: { goBack: boolean } }): void;
}>();

const animation = computed(() => {
  if (!props.isXs || props.disableAnimation) {
    return '';
  }

  // disable animation for route back on mobile safari (to avoid double animation)
  if (isMobileSafari && props.moveBack && props.isRouteAnimation && !props.triggeredByBackBtn) {
    return '';
  }

  if (props.push) {
    return props.moveBack ? 'push-prev' : 'push-next';
  }

  return props.moveBack ? 'prev' : 'next';
});

const onEnter = () => {
  emit('transition-enter-started');
};
const onAfterEnter = () => {
  emit('transition-enter-finished');
};
const onEnterCancelled = () => {
  emit('transition-enter-finished');
};
const onLeave = () => {
  const eventData = {
    detail: {
      goBack: false,
    },
  };

  emit('transition-finished', eventData);
};
</script>

<template>
  <div class="x-transition-slide">
    <transition
      :name="animation"
      :css="isXs"
      @enter="onEnter"
      @after-enter="onAfterEnter"
      @enter-cancelled="onEnterCancelled"
      @leave="onLeave"
    >
      <slot />
    </transition>
  </div>
</template>

<style lang="scss">
$transition-settings: 0.3s both linear;

.x-transition-slide {
  height: 100%;
  flex: 1;

  .xs & {
    /* to fix safari scroll */
    position: relative;
    display: grid;
    height: 100%;
    flex: 1;
    backface-visibility: hidden;
    grid-template: 'main' 100% / 100%;
    pointer-events: auto;
    transform: translateZ(0);
    will-change: transform;

    > * {
      /* Transition: make sections overlap on same cell */
      position: relative;
      background-color: var(--x-transition-slide-background-color, rgb(var(--v-theme-elevation-0)));
      grid-area: main;

      &:first-child {
        z-index: 1;

        /* Prevent flickering on first frame when transition classes not added yet */
      }
    }
  }
}

.next-leave-active {
  z-index: 0;
  animation: leave-to-left $transition-settings;
}

.next-enter-active {
  z-index: 1;
  animation: enter-from-right $transition-settings;
}

.prev-leave-active {
  z-index: 1;
  animation: leave-to-right $transition-settings;
}

.prev-enter-active {
  z-index: 0;
  animation: enter-from-left $transition-settings;
}

@keyframes leave-to-left {
  from {
    transform: translateX(0);
  }
}

@keyframes enter-from-left {
  to {
    transform: translateX(0);
  }
}

@keyframes leave-to-right {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100vw);
  }
}

@keyframes enter-from-right {
  from {
    transform: translateX(100vw);
  }

  to {
    transform: translateX(0);
  }
}

.push-next-leave-active {
  z-index: 0;
  animation: push-to-left $transition-settings;
}

.push-next-enter-active {
  z-index: 1;
  animation: push-from-right $transition-settings;
}

.push-prev-leave-active {
  z-index: 1;
  animation: push-to-right $transition-settings;
}

.push-prev-enter-active {
  z-index: 0;
  animation: push-from-left $transition-settings;
}

@keyframes push-to-left {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100vw);
  }
}

@keyframes push-from-right {
  from {
    transform: translateX(100vw);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes push-to-right {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100vw);
  }
}

@keyframes push-from-left {
  from {
    transform: translateX(-100vw);
  }

  to {
    transform: translateX(0);
  }
}
</style>

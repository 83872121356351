import { feesService } from '@exchange/libs/fees/service/src';
import { marketService } from '@exchange/libs/market/service/src';
import { currencyService } from '@exchange/libs/utils/currency/src';
import { serverClientTimeService } from '@exchange/libs/utils/server-client-time/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

const essentialFetchList = [
  /** TODO take into account eotc page  */
  {
    fn: () => {
      serverClientTimeService.establishClientServerTimeDiff();
    },
    type: 'establishClientServerTimeDiff',
  },
  { fn: () => marketService.fetchMarkets(), type: 'fetchMarkets' },
  { fn: () => currencyService.fetchCurrencies(), type: 'fetchCurrencies' },
  { fn: () => feesService.fetchPublicFees(), type: 'fetchPublicFees' },
];

export const requestEssentialData = async (caller: string) => {
  logger.info('Requesting essential data', caller);

  try {
    await Promise.all(essentialFetchList.map((p) => p.fn()));
  } catch (e) {
    logger.error(`Fetching essential data requested by ${caller} failed`, e);
  }
};

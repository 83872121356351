import { Bytes } from 'firebase/firestore';
import pako from 'pako';

import inPlaceReplace from '@exchange/helpers/in-place-replace';

export const getBinaryString = (obj) => pako.deflate(JSON.stringify(obj), { to: 'string' });
export const restoreFromBinary = (bstr) => JSON.parse(pako.inflate(bstr, { to: 'string' }));

export type RowDrawings = Dictionary<unknown>;
export type ChartDataPerAccount = { [accountId: string]: { data: null | Bytes; solicited?: boolean } };
export type ChartData = ChartDataPerAccount;
export type ChartPreprocessedData = { data: null | Bytes; solicited: null | boolean };

const buggyColor = 'rgba(39,209,127,';

export const isBuggyColor = (buggyColorString: string) => (value: unknown) => {
  if (typeof value === 'string') {
    return value.startsWith(`${buggyColorString},`);
  }

  return false;
};

export const updateBuggyColor = (value: string) => value.replace(`${buggyColor},`, buggyColor);

export function hackChartData(chartData, marketId: string): RowDrawings {
  // eslint-disable-next-line no-param-reassign
  chartData.charts = chartData?.charts?.map((chart) => {
    // eslint-disable-next-line no-param-reassign
    chart.panes = chart.panes.map((pane) => {
      const mainSeries = pane.sources.find((source) => source.type === 'MainSeries');

      if (mainSeries) {
        mainSeries.state.symbol = marketId;
      }

      return pane;
    });

    return chart;
  });

  const isPrimaryColorBuggy = isBuggyColor(buggyColor);

  inPlaceReplace(chartData, isPrimaryColorBuggy, updateBuggyColor);

  return chartData;
}

export const prepareChartDataForSaving = (data: RowDrawings | null) => {
  const zipped = getBinaryString(data);

  return { data: Bytes.fromUint8Array(zipped) };
};

export const prepareChartDataForApplying = (data: ChartData, subaccountId: string) => {
  const emptyState = {
    data: null,
    solicited: null,
  };

  const subaccountData = data[subaccountId];

  if (subaccountData) {
    return {
      data: subaccountData.data ? restoreFromBinary(subaccountData.data.toUint8Array()) : null,
      solicited: subaccountData.solicited ?? null,
    };
  }

  return emptyState;
};

/**
 * legacy => zoned date time value compliant with ISO8601
 * fast => nanoseconds since epoch
 */

const getTimestampNumber = (date: string | number) => {
  let stringDate = date;

  if (typeof stringDate !== 'string') {
    stringDate = stringDate.toString();
  }

  if (stringDate.slice(-1) === 'Z') {
    return new Date(stringDate).getTime();
  }

  const nanoToMs = -6;

  return Number(stringDate.slice(0, nanoToMs));
};

export default getTimestampNumber;

export default class CookieHelper {
  /**
   * Create cookie.
   * @param name Name of the cookie to create.
   * @param value The cookies value.
   * @param days For how many days the cookie should be stored.
   * @param domain The cookies domain.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static create(name: string, value: any, days?: number, domain?: string) {
    let cookie = `${name}=${value}`;

    if (domain) {
      cookie = `${cookie};domain=${domain}`;
    }

    if (days) {
      const date = new Date();

      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

      cookie = `${cookie};expires=${date.toUTCString()}`;
    }

    cookie = `${cookie};path=/`;
    document.cookie = cookie;
  }

  /**
   * Read cookie value.
   * @param name Name of the cookie to read.
   */
  static read(name: string) {
    const nameEquals = `${name}=`;
    const cookiesArray = document.cookie.split(';');

    // eslint-disable-next-line no-restricted-syntax
    for (let cookie of cookiesArray) {
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1, cookie.length);
      }

      if (cookie.indexOf(nameEquals) === 0) {
        return cookie.substring(nameEquals.length, cookie.length);
      }
    }

    return null;
  }

  /**
   * Erase a cookie.
   * @param name Name of the cookie to remove.
   */
  static erase(name: string) {
    CookieHelper.create(name, '', -1);
  }
}

import { isBefore, isAfter } from 'date-fns';

export const dateIsInTimeRange = (from: string, to: string, now: Date) => {
  const notInRangeStart = isBefore(now, new Date(from));
  const notInRangeEnd = isAfter(now, new Date(to));

  return !notInRangeStart && !notInRangeEnd;
};

export default dateIsInTimeRange;

/* eslint-disable consistent-return */
import { reactive, watch } from 'vue';

import { isProduction } from '@exchange/helpers/environment';
import persistanceHelper from '@exchange/helpers/persistance-helper';
import { secureStorageGet, secureStorageReset, secureStorageSet } from '@exchange/helpers/secure-storage-helpers';
import { accountService } from '@exchange/libs/account/service/src';
import { usePromiseThrowing } from '@exchange/libs/composables/shared/src/lib/usePromise';
import { firebaseService } from '@exchange/libs/firebase/src';
import oAuthRest from '@exchange/libs/rest-api/oauth2';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';
import { launchdarkly } from '@exchange/libs/utils/launchdarkly/src';
import { retryService } from '@exchange/libs/utils/retry/src';
import { serverClientTimeService } from '@exchange/libs/utils/server-client-time/src';
import { logger, nonProdConsoleInfo } from '@exchange/libs/utils/simple-logger/src';
import { checkIsCurrentAccountRoute, checkIsCurrentBiometricRoute, getNextPathUponLogin } from '@exchange/routing';

import AuthErrorCode, { AuthError, AuthErrorNeedsLogin, checkErrorAndReactOnClaimIssueTime } from './auth-errors';
import { getJWTPayloadExpirationTime, parseJwt, type IdToken, type AccessToken } from './token-helpers';

export type SignAdditionalParameters = KeyMap<string>;

export const getCurrentClientTime = (time?: number) => (time ? new Date(time) : new Date()).toString();

const handleReloadUponAuthReset = (params: { isUserInteraction?: boolean }, reloadReasonMessage: string) => {
  const isCurrentAccountRoute = checkIsCurrentAccountRoute();
  const isCurrentBiometricRoute = checkIsCurrentBiometricRoute();
  const shouldRedirectToHome = !params.isUserInteraction && isCurrentAccountRoute;

  nonProdConsoleInfo(
    'Handle reload upon auth reset:',
    'was it user interaction',
    params.isUserInteraction,
    'is current route "account" route',
    isCurrentAccountRoute,
    'is current route "biometric" route',
    isCurrentBiometricRoute,
    'should redirect to home',
    shouldRedirectToHome,
  );

  if (shouldRedirectToHome) {
    logger.info(reloadReasonMessage, getCurrentClientTime());
    window.location.href = '/';
  }
};
export interface AccessTokenData {
  token: string | undefined;
  expiringTimeout?: number;
  expiredTimeout?: number;
}
export interface AccessTokenSubaccountData {
  accountId: string | undefined;
  token: string | undefined;
  expiresIn: number | undefined;
}

export interface IdTokenData {
  token: {
    get: () => Promise<string | undefined>;
    set: (value: string) => Promise<{ value: boolean } | void>;
    clear: () => Promise<{ value: boolean } | void>;
  };
  earlyAdopter: boolean;
  bitpandaId: string | undefined;
  firstLogin: number | undefined;
}
export abstract class AuthService {
  readonly logPrefix = 'AuthService: ';

  readonly storageActiveRouteName = 'PRO_active_route';

  readonly storageLoginProcess = 'PRO_login_process';

  abstract signInInternal(additionalParameters?: SignAdditionalParameters): Promise<void>;

  abstract signInCallbackInternal(): Promise<void>;

  abstract signOutInternal(): Promise<void>;

  abstract resetAuthInternal(): Promise<void>;

  public abstract triggerSilentAuthorizationInternal(additionalParameters?: SignAdditionalParameters): Promise<void>;

  public abstract isRefreshTokeExpired(): Promise<boolean>;

  public accessTokenHolderData = reactive<AccessTokenData>({
    token: undefined,
    expiringTimeout: undefined,
    expiredTimeout: undefined,
  });

  public accessTokenSubaccountData = reactive<AccessTokenSubaccountData>({
    accountId: persistanceHelper.getObjFromJSON(CONSTANTS.LS_SUBACCOUNT_ID_KEY, undefined),
    token: undefined,
    expiresIn: undefined,
  });

  public idTokenData = reactive<IdTokenData>({
    token: {
      get: () =>
        secureStorageGet<string | undefined>(
          'idToken',
          (info) => JSON.parse(info.value),
          () => undefined,
        ),
      set: (value) => secureStorageSet('idToken', JSON.stringify(value)),
      clear: () => secureStorageReset('idToken'),
    },
    earlyAdopter: false,
    bitpandaId: undefined,
    firstLogin: undefined,
  });

  public isLoginProcess = reactive({
    value: false,
  });

  public waitForLoginProcessDone = (): Promise<void> => {
    if (!this.isLoginProcess.value) {
      return Promise.resolve();
    }

    return new Promise((resolve) => {
      const watchStopHandle = watch(this.isLoginProcess, (newValue) => {
        if (!newValue) {
          watchStopHandle();
          return resolve();
        }
      });
    });
  };

  constructor(public handleReloadUponSignout: () => Promise<void>) {}

  private logIfNeeded = (...args) => {
    if (launchdarkly.flags.getLogging().auth) {
      logger.log(this.logPrefix, ...args, getCurrentClientTime(), new Date().toISOString());
    }
  };

  private checkAbnormalTime = async ({ exp, iat }: { exp: number; iat: number }) => {
    const isAbnormalClaimTime = serverClientTimeService.checkJWTClaimAbnormalTime({ msExp: exp * 1000, msIat: iat * 1000 });
    const isAbnormalClientTime = await serverClientTimeService.checkClientAbnormalTime().catch((e) => {
      logger.error(`${this.logPrefix}abnormal time check failed`, e);

      return false;
    });

    this.logIfNeeded(`iat ${iat}; exp ${exp}; now ${Date.now()}; claims ${isAbnormalClaimTime};  client time ${isAbnormalClientTime}`);

    return isAbnormalClaimTime || isAbnormalClientTime;
  };

  private setAccessAndIdTokens = async ({ idToken, accessToken }: { idToken: string; accessToken: string }) => {
    const idTokenFields = parseJwt<IdToken>(idToken);
    const accessTokeFields = parseJwt<AccessToken>(accessToken);
    const { exp, iat } = accessTokeFields;

    const isAbnormalTime = await this.checkAbnormalTime({ exp, iat });

    if (isAbnormalTime) {
      serverClientTimeService.showWrongClientTimeModal();
      logger.error(`${this.logPrefix}${idTokenFields.bitpanda_id} abnormal time; iat ${iat}; exp ${exp}; now ${Date.now()};`);

      throw new Error('ABNORMAL_CLIENT_TIME');
    }

    this.accessTokenHolderData.token = accessToken;
    await this.idTokenData.token.set(idToken);
    this.idTokenData.bitpandaId = idTokenFields.bitpanda_id;
    this.idTokenData.earlyAdopter = idTokenFields.early_adopter || false;
    this.idTokenData.firstLogin = idTokenFields.first_login;

    this.logIfNeeded('access and id tokens are set; exp are', exp, idTokenFields.exp, 'access iat', iat);
  };

  public clearAccessAndIdTokens = () => {
    this.accessTokenHolderData.token = undefined;
    this.idTokenData.earlyAdopter = false;
    this.idTokenData.bitpandaId = undefined;
    this.idTokenData.firstLogin = undefined;

    this.logIfNeeded('access and id tokens are cleared');
  };

  public setSubaccountTokenData = ({ accountId, token, expiresIn }) => {
    if (accountId) {
      this.accessTokenSubaccountData.accountId = accountId;
      persistanceHelper.localstorageSet(CONSTANTS.LS_SUBACCOUNT_ID_KEY, accountId);
    }

    this.accessTokenSubaccountData.token = token;
    this.accessTokenSubaccountData.expiresIn = expiresIn;

    this.logIfNeeded(`subaccount access token is ${token ? 'set' : 'cleared'}`);
  };

  public get accessTokenHolder() {
    return this.accessTokenHolderData.token;
  }

  public get accessTokenSubaccount() {
    return this.accessTokenSubaccountData.token;
  }

  private isJWTPayloadExpired = (jwt: string) => serverClientTimeService.isInPastTime(getJWTPayloadExpirationTime(jwt), Date.now());

  public get isAuthenticated() {
    return !!this.accessTokenSubaccount && !this.isJWTPayloadExpired(this.accessTokenSubaccount);
  }

  public get isAuthenticatedHolder() {
    return !!this.accessTokenHolder && !this.isJWTPayloadExpired(this.accessTokenHolder);
  }

  public getToken = (useSubaccount = true) => (useSubaccount ? this.accessTokenSubaccount : this.accessTokenHolder);

  public getRefreshedToken = async () => {
    if (this.isAuthenticated) {
      return this.getToken();
    }

    await this.triggerSilentAuthorization();

    return this.getToken();
  };

  public authIsCheckedPrivate = reactive({
    holder: false,
  });

  private setAuthIsCheckedPrivate = (value = true) => {
    nonProdConsoleInfo(`${this.logPrefix}Account holder auth is checked`, value);

    this.authIsCheckedPrivate.holder = value;
  };

  public get initialLoadAuthIsChecked() {
    return this.authIsCheckedPrivate.holder;
  }

  public get hasBeenAuthenticated() {
    return Boolean(this.idTokenData.bitpandaId);
  }

  public switchToSubaccount = async (accountId: string) => {
    try {
      const { access_token: token, expires_in: expiresIn } = await oAuthRest.SwitchAccount.request(accountId);

      this.setSubaccountTokenData({
        accountId,
        token,
        expiresIn,
      });

      if (!this.accessTokenSubaccountData.token || !this.idTokenData.bitpandaId) {
        throw new Error(`${this.logPrefix}Cannot proceed without the following data. Subaccount access token or Holder id - ${this.idTokenData.bitpandaId}`);
      }

      this.accountSet({
        accessToken: this.accessTokenSubaccountData.token,
        accessTokenExpiresIn: this.accessTokenSubaccountData.expiresIn,
        earlyAdopter: this.idTokenData.earlyAdopter || false,
        accountId,
        accountIdHolder: this.idTokenData.bitpandaId,
      });
    } catch (e) {
      if ((e as { status: number }).status === 403 && this.idTokenData.bitpandaId && accountId !== this.idTokenData.bitpandaId) {
        return this.switchToSubaccount(this.idTokenData.bitpandaId);
      }

      logger.error(`${this.logPrefix}switch account request failed:`, e, getCurrentClientTime());
      throw e;
    }
  };

  public processAuthenticatedHolder = async ({ accessToken, idToken }) => {
    await this.setAccessAndIdTokens({ accessToken, idToken });

    if (!this.idTokenData.bitpandaId) {
      throw new Error(`${this.logPrefix}No idToken data to proceed with switching to main account`);
    }

    this.loginToFirebase(); /** do not await as it might lead to the blank black screen */
    await this.switchToSubaccount(this.accessTokenSubaccountData.accountId || this.idTokenData.bitpandaId);
  };

  private accountSet = (params: { accessToken: string; accessTokenExpiresIn: number | undefined; earlyAdopter: boolean; accountId: string; accountIdHolder: string }) => {
    const getTokenData = () => ({
      subaccountData: parseJwt<AccessToken>(params.accessToken),
      holderData: this.accessTokenHolderData.token ? parseJwt<AccessToken>(this.accessTokenHolderData.token) : undefined,
    });

    if (!isProduction) {
      logger.info(
        `%cHolder id:%c ${params.accountIdHolder}`,
        'background: #67A7EF; padding: 1px; border-radius: 3px 0 0 3px; color: #fff;',
        'background:#ffffff ; padding: 1px; color: #67A7EF',
      );
      logger.info(
        `%cHolder token:%c ${this.accessTokenHolderData.token}`,
        'background: #67A7EF; padding: 1px; border-radius: 3px 0 0 3px; color: #fff;',
        'background:#ffffff ; padding: 1px; color: #67A7EF',
      );

      const { subaccountData, holderData } = getTokenData();

      if (holderData) {
        logger.info(
          `%cHolder token iat:%c ${getCurrentClientTime(holderData.iat * 1000)}`,
          'background: #67A7EF; padding: 1px; border-radius: 3px 0 0 3px; color: #fff;',
          'background:#ffffff ; padding: 1px; color: #67A7EF',
        );
        logger.info(
          `%cHolder token exp:%c ${getCurrentClientTime(holderData.exp * 1000)}`,
          'background: #67A7EF; padding: 1px; border-radius: 3px 0 0 3px; color: #fff;',
          'background:#ffffff ; padding: 1px; color: #67A7EF',
        );
      }

      logger.info(
        `%cSub-account id:%c ${params.accountId}`,
        'background: #67A7EF; padding: 1px; border-radius: 3px 0 0 3px; color: #fff;',
        'background:#ffffff ; padding: 1px; color: #67A7EF',
      );
      logger.info(
        `%cSub-account token:%c ${params.accessToken}`,
        'background: #67A7EF; padding: 1px; border-radius: 3px 0 0 3px; color: #fff;',
        'background:#ffffff ; padding: 1px; color: #67A7EF',
      );
      logger.info(
        `%cSub-account token expires in:%c ${params.accessTokenExpiresIn}`,
        'background: #67A7EF; padding: 1px; border-radius: 3px 0 0 3px; color: #fff;',
        'background:#ffffff ; padding: 1px; color: #67A7EF',
      );
      logger.info(
        `%cSub-account token iat:%c ${getCurrentClientTime(subaccountData.iat * 1000)}`,
        'background: #67A7EF; padding: 1px; border-radius: 3px 0 0 3px; color: #fff;',
        'background:#ffffff ; padding: 1px; color: #67A7EF',
      );
      logger.info(
        `%cSub-account token exp:%c ${getCurrentClientTime(subaccountData.exp * 1000)}`,
        'background: #67A7EF; padding: 1px; border-radius: 3px 0 0 3px; color: #fff;',
        'background:#ffffff ; padding: 1px; color: #67A7EF',
      );
    } else {
      logger.info(`${this.logPrefix}Holder id: ${params.accountIdHolder}`);

      const { subaccountData, holderData } = getTokenData();

      this.logIfNeeded(
        'Holder token exp',
        holderData ? (holderData.exp, 'client time:', getCurrentClientTime(holderData.exp * 1000)) : 'no holder data',
        ' Sub-account token exp',
        subaccountData.exp,
        'client time:',
        getCurrentClientTime(subaccountData.exp * 1000),
      );
    }

    /** do not await this call cause it contains broker requests that frequently fail and the function setup the way it retries until eventually all requests are finished */
    accountService.set(
      {
        earlyAdopter: params.earlyAdopter,
        accountId: params.accountId,
        accountIdHolder: params.accountIdHolder,
      },
      this.getRefreshedToken,
    );
  };

  public resetAuth = async (params: { isUserInteraction: boolean; unsubscribeAccountHistory: boolean }) => {
    logger.info(`${this.logPrefix}resetting auth, isUserInteraction: ${params.isUserInteraction}, unsubscribeAccountHistory: ${params.unsubscribeAccountHistory}`);

    this.clearAccessAndIdTokens();
    this.setSubaccountTokenData({
      accountId: undefined,
      token: undefined,
      expiresIn: undefined,
    });
    accountService.reset({ unsubscribeFromAccountHistory: params.unsubscribeAccountHistory });

    await this.resetAuthInternal();

    handleReloadUponAuthReset(params, `${this.logPrefix}Reloading after resetAuth`);
  };

  public loginToFirebase = async () => {
    try {
      await firebaseService.login();
      logger.info(`${this.logPrefix}Login to Firebase succeeded`, getCurrentClientTime());
    } catch (error) {
      logger.error(`${this.logPrefix}Login to Firebase failed:`, error, getCurrentClientTime());
      await retryService.waitForNextRetryTick();
      await this.loginToFirebase();
    }
  };

  public logoutFromFirebase = async () => {
    await firebaseService.logout().catch((e) => {
      logger.error(`${this.logPrefix} Firebase sign out failed:`, e, getCurrentClientTime());
    });
  };

  public signOut = async () => {
    try {
      await this.signOutInternal();
    } catch (e) {
      logger.error(`${this.logPrefix}Sign out failed:`, e, getCurrentClientTime());
      throw e;
    } finally {
      await this.handleReloadUponSignout();
    }
  };

  private getActiveRoute = () => {
    const path = document.location.pathname;

    return `${path}${document.location.search || ''}${document.location.hash || ''}`;
  };

  public signIn = async () => {
    try {
      this.setAuthIsCheckedPrivate(false);
      this.clearAccessAndIdTokens();
      this.setSubaccountTokenData({
        accountId: undefined,
        token: undefined,
        expiresIn: undefined,
      });
      let activeRoute = this.getActiveRoute();
      // TODO remove this one markets are launched
      const activeRouteInfo = activeRoute.split(/_/s);

      if (activeRouteInfo.length === 2) {
        activeRoute = '/trade';
      }

      sessionStorage.setItem(this.storageActiveRouteName, activeRoute);

      this.isLoginProcess.value = true;
      accountService.reset();
      await this.resetAuthInternal();
      await this.signInInternal();

      return await getNextPathUponLogin();
    } catch (e) {
      logger.error(`${this.logPrefix}Sign in failed:`, e, getCurrentClientTime());
      this.isLoginProcess.value = false;
      throw e;
    } finally {
      this.setAuthIsCheckedPrivate(true);
    }
  };

  public signInCallback = async () => {
    const returnToUrl = sessionStorage.getItem(this.storageActiveRouteName) || '/';

    await this.signInCallbackInternal().finally(this.setAuthIsCheckedPrivate);

    return returnToUrl;
  };

  public checkRouteAccess = async ({
    noAuthRequired,
    isNativePlatform,
    trackingId,
  }: {
    noAuthRequired: boolean;
    isNativePlatform: boolean;
    trackingId?: string;
  }): Promise<boolean> => {
    nonProdConsoleInfo(trackingId, 'Checking route access: ', 'noAuthRequired', noAuthRequired, 'isAuthenticated', this.isAuthenticated);

    if (noAuthRequired) {
      /** public routes */
      if (!this.isAuthenticated) {
        const sa = this.triggerSilentAuthorization({ logError: false }).catch(() => {
          /** ignore on public routes */
        });

        if (isNativePlatform) {
          await sa;
        }
      }

      return true;
    }

    if (!this.isAuthenticated) {
      /** private routes */
      try {
        await this.triggerSilentAuthorization();

        return this.isAuthenticated;
      } catch (e) {
        // do not log out, just reset FE data
        this.resetAuth({ isUserInteraction: false, unsubscribeAccountHistory: false });

        return false;
      }
    }

    return true;
  };

  public waitForAuthIsChecked = async ({ useSubaccount = true } = {}): Promise<void> => {
    const isHolderDone = () => this.authIsCheckedPrivate.holder || this.accessTokenHolder;
    const waitForAuthIsCheckedHolder = (): Promise<void> => {
      if (isHolderDone()) {
        return Promise.resolve();
      }

      return new Promise((resolve) => {
        const watchStopHandle = watch(
          () => isHolderDone(),
          (newValue) => {
            if (newValue) {
              watchStopHandle();
              return resolve();
            }
          },
        );
      });
    };

    const waitForSubaccountToken = (): Promise<void> => {
      if (this.accessTokenSubaccount) {
        return Promise.resolve();
      }

      return new Promise((resolve) => {
        const watchStopHandle = watch(
          () => this.accessTokenSubaccount,
          (newValue) => {
            if (newValue) {
              watchStopHandle();
              return resolve();
            }
          },
        );
      });
    };

    if (useSubaccount) {
      if (isHolderDone()) {
        return waitForSubaccountToken();
      }

      return waitForAuthIsCheckedHolder().then(() => this.waitForAuthIsChecked({ useSubaccount }));
    }

    return waitForAuthIsCheckedHolder();
  };

  private triggerSilentAuthorizationWrapperFunc = async ({ logError = true, attempt = 0 }: { logError?: boolean; attempt?: number } = {}): Promise<void> => {
    try {
      this.logIfNeeded('doing silent authorization');
      /*
      this.accessTokenHolderData.token = undefined;
      this.setSubaccountTokenData({
        accountId: undefined,
        token: undefined,
        expiresIn: undefined,
      });
      */
      await this.triggerSilentAuthorizationInternal();

      return undefined;
    } catch (e) {
      if (logError || !isProduction) {
        logger.info(
          `${this.logPrefix}Silent authorization attempt #${attempt} failed:`,
          e,
          'message: ',
          (e as AuthError)?.message,
          'error: ',
          (e as AuthError)?.error,
          'error_description: ',
          (e as AuthError)?.error_description,
          new Date(),
        );
      }

      if (
        attempt < 3 &&
        ((e as AuthError).error === AuthErrorCode.server_error ||
          (e as AuthError).message === AuthErrorCode.server_error ||
          (e as AuthError).error === AuthErrorCode.temporarily_unavailable ||
          (e as AuthError).message === AuthErrorCode.temporarily_unavailable)
      ) {
        return this.triggerSilentAuthorizationWrapperFunc({ logError, attempt: attempt + 1 });
      }

      checkErrorAndReactOnClaimIssueTime({ error: (e as AuthError)?.error, message: (e as AuthError)?.message });

      throw e;
    }
  };

  private triggerSilentAuthorizationWrapper = usePromiseThrowing(this.triggerSilentAuthorizationWrapperFunc);

  public waitForSilentAuthorizationNotRunning: () => Promise<{ wasRunning: boolean }> = () =>
    new Promise((resolve) => {
      if (!this.triggerSilentAuthorizationWrapper.loading.value) {
        resolve({ wasRunning: false });
        return;
      }

      const watchStopHandle = watch(this.triggerSilentAuthorizationWrapper.loading, (value) => {
        if (!value) {
          watchStopHandle();
          resolve({ wasRunning: true });
        }
      });
    });

  public triggerSilentAuthorization = async (params?: { logError?: boolean }): Promise<void> => {
    const { wasRunning } = await this.waitForSilentAuthorizationNotRunning();

    if (wasRunning) {
      return;
    }

    await this.triggerSilentAuthorizationWrapper.createPromise(params).finally(this.setAuthIsCheckedPrivate);
  };

  public checkAndTryToUpdateAuthUponReturningToFromBeingInactive = async (unsubscribeOnReset = false) => {
    if (!this.isAuthenticated) {
      await this.triggerSilentAuthorization().catch((e) => {
        if (!AuthErrorNeedsLogin.includes(e?.message)) {
          logger.info(`${this.logPrefix}Upon returning from being inactive: Silent authorization failed:`, e, getCurrentClientTime());
        }
      });
    }

    nonProdConsoleInfo(`${this.logPrefix}Upon returning from being inactive: isAuthenticated:`, this.isAuthenticated);

    if (!this.isAuthenticated) {
      await this.resetAuth({ isUserInteraction: false, unsubscribeAccountHistory: unsubscribeOnReset });
    }
  };
}

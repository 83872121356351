const searchRegExp = (str: string) => new RegExp(str.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&'), 'gi');

export const filterBy =
  <T extends string>(props: Array<T>) =>
  (str: string) =>
  (item: { [key in T]: string | undefined }): boolean => {
    const inputPattern = searchRegExp(str);

    return props.reduce((acc: boolean, prop) => acc || inputPattern.test(item[prop] || ''), false);
  };

export default filterBy;

<script lang="ts" setup>
import InfoModal from '@exchange/libs/modals/src/lib/common/InfoModal.vue';

defineProps({
  phase: { type: Number, required: true },
});

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'go-to-form'): void;
}>();
</script>

<template>
  <info-modal
    testid="uk-certification-modal"
    variant="dark"
    :no-icon="true"
    action="ui.ukSelfCertification.primaryAction"
    action-test-id="go-to-form"
    :action-secondary="phase === 0 ? 'ui.ukSelfCertification.secondaryAction' : undefined"
    action-secondary-test-id="later"
    content-test-id="uk-certification-modal-content"
    title="ui.ukSelfCertification.title"
    :message="phase === 0 ? $t('ui.ukSelfCertification.description.before') : $t('ui.ukSelfCertification.description.after')"
    @action-clicked="emit('go-to-form')"
    @action-secondary-clicked="emit('close')"
  />
</template>

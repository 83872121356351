import { capAppLauncher, capBrowser, capIsNativePlatform } from '@exchange/libs/utils/capacitor/src';

/**
 * Opens given url if called in a native application
 * @param url the url to open
 * @param useSystemBrowser opens the url using system browser if true, in-app browser otherwise
 */
export const nativeOpenLink = (url: string, useSystemBrowser = false) => {
  const isNative = capIsNativePlatform();

  if (!isNative) {
    return;
  }

  if (useSystemBrowser) {
    capAppLauncher.openUrl({ url });
  } else {
    capBrowser.open({ url });
  }
};

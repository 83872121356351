<script lang="ts" setup>
import { type PropType } from 'vue';

import usePromise from '@exchange/libs/composables/shared/src/lib/usePromise';
import { modalVariant, type ModalVariant } from '@exchange/libs/modals/src';

const props = defineProps({
  accept: { type: Function as PropType<() => Promise<void>>, required: true },
  variant: { type: String as PropType<ModalVariant>, default: modalVariant.dark },
});

const { loading: accepting, createPromise: acceptNow } = usePromise(props.accept);
</script>

<template>
  <x-modal
    class="general-tos-modal"
    :can-close="false"
    :variant="variant"
    :title="$t('ui.riskWarningModal.title')"
  >
    <template #content>
      <p>{{ $t('ui.riskWarningModal.message1') }}</p>
      <i18n-t
        keypath="ui.riskWarningModal.message.text"
        tag="p"
        scope="global"
      >
        <template #linkText>
          <x-link-external :href="$t('ui.riskWarningModal.message.linkHref')">
            {{ $t('ui.riskWarningModal.message.linkText') }}
          </x-link-external>
        </template>
      </i18n-t>
    </template>
    <template #buttons>
      <x-button
        variant="primary"
        :loading="accepting"
        :disabled="accepting"
        @click="acceptNow"
      >
        {{ $t('ui.riskWarningModal.agreeButton') }}
      </x-button>
    </template>
  </x-modal>
</template>

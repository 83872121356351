import BigNumber from '@exchange/helpers/bignumber';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';

export const percentFormatting = (number: string | number | BigNumber, precision: number = CONSTANTS.PRECISION.DEFAULT_PERCENTAGE) => {
  const bigV = new BigNumber(number);

  if (bigV.eq(-100) || bigV.eq(0) || bigV.eq(100)) {
    return Number(bigV).toFixed(precision);
  }

  const minIncrement = new BigNumber(10).pow(-precision);
  const v = Number(bigV).toFixed(precision);

  if (+v === -100) {
    return new BigNumber(-100).plus(minIncrement).toString();
  }

  if (+v === 0) {
    return new BigNumber(0).plus(minIncrement).toString();
  }

  if (+v === 100) {
    return new BigNumber(100).minus(minIncrement).toString();
  }

  return v;
};

export default percentFormatting;

import { logger } from '@exchange/libs/utils/simple-logger/src';
import { themingInstance } from '@exchange/libs/utils/theming/src';
import { ResolutionString } from '@exchange-public/charting_library/charting_library.d';

import { tradingViewTimeZones } from './model';

export const TV_CHART_CONTAINER = 'tv-chart-container';

function getTradingViewTimeZone(): TradingView.Timezone {
  const offsetInHours = (new Date().getTimezoneOffset() / 60) * -1;
  const tradingViewTimeZone = (Object.keys(tradingViewTimeZones) as Array<TradingView.Timezone>).find((timeZone) => tradingViewTimeZones[timeZone] === offsetInHours);

  if (!tradingViewTimeZone) {
    logger.warn('Cannot find supported Timezone - using UTC');

    return 'Etc/UTC';
  }

  return tradingViewTimeZone;
}

export const getOverrides = () => {
  /** https://github.com/tradingview/charting_library/wiki/Overrides */
  const getColor = themingInstance.getColor.bind(themingInstance);

  const colorPrimary = getColor('--v-theme-primary');
  const colorPrimaryWithOpacity = getColor('--primary-lightest');
  const colorSecondary = getColor('--v-theme-secondary');
  const colorBackground2 = getColor('--v-theme-elevation-1');
  const colorBackground5 = getColor('--v-theme-elevation-2');
  const white = 'rgba(255, 255, 255, 1)';

  return {
    'paneProperties.legendProperties.showStudyArguments': true,
    'paneProperties.legendProperties.showStudyTitles': true,
    'paneProperties.legendProperties.showStudyValues': true,
    'paneProperties.legendProperties.showSeriesTitle': true,
    'paneProperties.legendProperties.showSeriesOHLC': true,
    'paneProperties.legendProperties.showLegend': true,

    'paneProperties.background': colorBackground2,
    'paneProperties.vertGridProperties.color': colorBackground5,
    'paneProperties.vertGridProperties.style': 0,
    'paneProperties.horzGridProperties.color': colorBackground5,
    'paneProperties.horzGridProperties.style': 0,
    'paneProperties.crossHairProperties.color': getColor('--v-theme-text-secondary'),
    'paneProperties.crossHairProperties.width': 1,
    'paneProperties.crossHairProperties.style': 2,

    'scalesProperties.fontSize': 10,
    'scalesProperties.textColor': getColor('--v-theme-text-primary'),
    'scalesProperties.lineColor': getColor('--v-theme-elevation-3'),
    'scalesProperties.axisHighlightColor': colorPrimaryWithOpacity,
    'scalesProperties.axisLineToolLabelBackgroundColorCommon': colorPrimary,

    'sessions.vertlines.sessBreaks.color': colorPrimary,

    'mainSeriesProperties.baseLineColor': colorPrimary,
    'mainSeriesProperties.showCountdown': true,
    'mainSeriesProperties.showPrevClosePriceLine': true,

    // Candles styles
    'mainSeriesProperties.candleStyle.upColor': colorPrimary,
    'mainSeriesProperties.candleStyle.downColor': colorSecondary,
    'mainSeriesProperties.candleStyle.wickUpColor': colorPrimary,
    'mainSeriesProperties.candleStyle.wickDownColor': colorSecondary,
    'mainSeriesProperties.candleStyle.drawWick': true,
    'mainSeriesProperties.candleStyle.drawBorder': true,
    'mainSeriesProperties.candleStyle.borderColor': colorBackground2,
    'mainSeriesProperties.candleStyle.borderUpColor': colorPrimary,
    'mainSeriesProperties.candleStyle.borderDownColor': colorSecondary,

    // Hollow Candles styles
    'mainSeriesProperties.hollowCandleStyle.upColor': colorPrimary,
    'mainSeriesProperties.hollowCandleStyle.downColor': colorSecondary,
    'mainSeriesProperties.hollowCandleStyle.wickColor': colorPrimary,
    'mainSeriesProperties.hollowCandleStyle.wickUpColor': colorPrimary,
    'mainSeriesProperties.hollowCandleStyle.wickDownColor': colorSecondary,
    'mainSeriesProperties.hollowCandleStyle.drawWick': true,
    'mainSeriesProperties.hollowCandleStyle.drawBorder': true,
    'mainSeriesProperties.hollowCandleStyle.borderColor': colorBackground2,
    'mainSeriesProperties.hollowCandleStyle.borderUpColor': colorPrimary,
    'mainSeriesProperties.hollowCandleStyle.borderDownColor': colorSecondary,

    // Heikin Ashi styles
    'mainSeriesProperties.haStyle.upColor': colorPrimary,
    'mainSeriesProperties.haStyle.downColor': colorSecondary,
    'mainSeriesProperties.haStyle.borderColor': colorBackground2,
    'mainSeriesProperties.haStyle.borderUpColor': colorPrimary,
    'mainSeriesProperties.haStyle.borderDownColor': colorSecondary,
    'mainSeriesProperties.haStyle.wickColor': colorPrimary,
    'mainSeriesProperties.haStyle.wickUpColor': colorPrimary,
    'mainSeriesProperties.haStyle.wickDownColor': colorSecondary,

    // Bar styles
    'mainSeriesProperties.barStyle.upColor': colorPrimary,
    'mainSeriesProperties.barStyle.downColor': colorSecondary,

    // Line styles
    'mainSeriesProperties.lineStyle.color': colorPrimary,

    // Area styles
    'mainSeriesProperties.areaStyle.color1': colorPrimary,
    'mainSeriesProperties.areaStyle.color2': colorPrimary,
    'mainSeriesProperties.areaStyle.linecolor': colorPrimary,

    // Baseline styles
    'mainSeriesProperties.baselineStyle.baselineColor': colorPrimary,
    'mainSeriesProperties.baselineStyle.topFillColor1': colorPrimary,
    'mainSeriesProperties.baselineStyle.topFillColor2': colorPrimary,
    'mainSeriesProperties.baselineStyle.bottomFillColor1': colorSecondary,
    'mainSeriesProperties.baselineStyle.bottomFillColor2': colorSecondary,
    'mainSeriesProperties.baselineStyle.topLineColor': colorPrimary,
    'mainSeriesProperties.baselineStyle.bottomLineColor': colorSecondary,

    // Hi-Lo styles
    'mainSeriesProperties.hiloStyle.color': colorPrimary,
    'mainSeriesProperties.hiloStyle.borderColor': colorPrimary,
    'mainSeriesProperties.hiloStyle.labelColor': colorPrimary,

    // Renko styles
    'mainSeriesProperties.renkoStyle.upColor': colorPrimary,
    'mainSeriesProperties.renkoStyle.downColor': colorSecondary,
    'mainSeriesProperties.renkoStyle.borderUpColor': colorPrimary,
    'mainSeriesProperties.renkoStyle.borderDownColor': colorSecondary,
    'mainSeriesProperties.renkoStyle.upColorProjection': colorPrimary,
    'mainSeriesProperties.renkoStyle.downColorProjection': colorSecondary,
    'mainSeriesProperties.renkoStyle.borderUpColorProjection': colorPrimary,
    'mainSeriesProperties.renkoStyle.borderDownColorProjection': colorSecondary,
    'mainSeriesProperties.renkoStyle.wickUpColor': colorPrimary,
    'mainSeriesProperties.renkoStyle.wickDownColor': colorSecondary,

    // Kagi styles
    'mainSeriesProperties.kagiStyle.upColor': colorPrimary,
    'mainSeriesProperties.kagiStyle.downColor': colorSecondary,
    'mainSeriesProperties.kagiStyle.upColorProjection': colorPrimary,
    'mainSeriesProperties.kagiStyle.downColorProjection': colorSecondary,

    // Point&Figure styles
    'mainSeriesProperties.pnfStyle.upColor': colorPrimary,
    'mainSeriesProperties.pnfStyle.downColor': colorSecondary,
    'mainSeriesProperties.pnfStyle.upColorProjection': colorPrimary,
    'mainSeriesProperties.pnfStyle.downColorProjection': colorSecondary,

    // Range styles
    'mainSeriesProperties.rangeStyle.upColor': colorPrimary,
    'mainSeriesProperties.rangeStyle.downColor': colorSecondary,
    'mainSeriesProperties.rangeStyle.upColorProjection': colorPrimary,
    'mainSeriesProperties.rangeStyle.downColorProjection': colorSecondary,
    'linetoolicon.color': colorPrimary,

    'linetooltrendline.linecolor': white,
    'linetooltrendline.linewidth': 2.0,

    'linetoolinfoline.linecolor': white,
    'linetoolinfoline.linewidth': 2.0,
    'linetoolinfoline.textcolor': colorPrimary,

    'linetoolarrow.linecolor': getColor('--v-theme-yellow'),
    'linetoolarrow.linewidth': 4.0,

    'linetoolbrush.linecolor': white,
    'linetoolbrush.linewidth': 4.0,

    'linetoolextended.linecolor': white,

    'linetoolheadandshoulders.color': white,
    'linetoolheadandshoulders.textcolor': colorPrimary,
    'linetoolheadandshoulders.backgroundColor': getColor('--elevation-light-0'),

    'linetoolriskrewardlong.stopBackground': getColor('--secondary-lightest'),
    'linetoolriskrewardlong.profitBackground': getColor('--primary-lightest'),

    'linetoolriskrewardshort.stopBackground': getColor('--secondary-lightest'),
    'linetoolriskrewardshort.profitBackground': getColor('--primary-lightest'),

    'linetooltext.color': colorPrimary,

    'linetoolfibretracement.horzLabelsAlign': 'right',
    'linetoolfibretracement.transparency': 80,

    'linetoolfibchannel.transparency': 80,

    'linetooldateandpricerange.linecolor': colorPrimary,
  };
};

/**
 *
 * https://github.com/tradingview/charting_library/wiki/Widget-Constructor
 */
export default function getWidgetOptions({
  market,
  interval,
  locale,
  isMobileOnly,
  isNativeApp,
  debug,
  enableGoTo,
  isXSmallScreen,
  theme,
}: {
  market: string;
  interval: ResolutionString;
  locale: TradingView.LanguageCode;
  isMobileOnly: boolean;
  isNativeApp: boolean;
  debug: boolean;
  enableGoTo: boolean;
  isXSmallScreen: boolean;
  theme: TradingView.ThemeName;
}) {
  const { getColor, appTheme } = themingInstance;

  const disabledFeatures = [
    /**  https://github.com/tradingview/charting_library/wiki/Featuresets */ ...(!enableGoTo ? ['go_to_date'] : []),
    ...(isMobileOnly || isNativeApp ? ['header_fullscreen_button'] : []),
    ...(isNativeApp ? ['header_screenshot'] : []),
    ...(isXSmallScreen ? ['header_compare'] : []),
    'border_around_the_chart',
    'display_market_status',
    'header_saveload',
    'header_symbol_search',
    'symbol_info',
    'symbol_search_hot_key',
  ];

  const enabledFeatures = ['charting_library_debug_mode', 'hide_last_na_study_output', 'remove_library_container_border'];

  const colorBackground10 = getColor('--v-theme-elevation-3');

  return {
    auto_save_delay: 5,
    autosize: true,
    custom_css_url: `../../${process.env.VUE_APP_EXCHANGE_CHART_CSS_NAME}` /** should be an absolute or relative path to the static folder */,
    custom_font_family: appTheme.getCurrentThemeConfig().font['font-family'],
    debug,
    disabled_features: disabledFeatures,
    enabled_features: enabledFeatures,
    fullscreen: false,
    interval,
    library_path: '/charting_library/',
    loading_screen: {
      backgroundColor: getColor('--v-theme-elevation-1'),
      foregroundColor: getColor('--v-theme-elevation-1'),
    },
    locale,
    overrides: getOverrides(),
    studies_overrides: {
      /** https://github.com/tradingview/charting_library/wiki/Studies-Overrides */ 'volume.volume.color.0': colorBackground10,
      'volume.volume.color.1': colorBackground10,
      'volume.show ma': false,
    },
    symbol: market,
    theme,
    timezone: getTradingViewTimeZone(),
    time_frames: [
      { text: '3y', resolution: '1W' as TradingView.ResolutionString },
      { text: '1y', resolution: '1W' as TradingView.ResolutionString },
      { text: '6m', resolution: '240' as TradingView.ResolutionString },
      { text: '3m', resolution: '60' as TradingView.ResolutionString },
      { text: '5d', resolution: '5' as TradingView.ResolutionString },
      { text: '1d', resolution: '1' as TradingView.ResolutionString },
    ],
    toolbar_bg: getColor('--v-theme-elevation-1'),
  };
}

import { Log, OidcClientSettings, UserManager } from 'oidc-client';

import { logger } from '@exchange/libs/utils/simple-logger/src';

import { createOIDCUserManager, OIDCEventsCallbacks, setOIDCEvents } from './oidc';
import { type AuthError, AuthErrorNeedsLogin, checkErrorAndReactOnClaimIssueTime } from '../auth-errors';
import { type SignAdditionalParameters } from '../auth.service.common';

const setService = (mgr: UserManager, logPrefix: string) => {
  function signIn(additionalParameters?: SignAdditionalParameters) {
    /** Redirect to the authorization endpoint */
    const params = additionalParameters
      ? {
          extraQueryParams: additionalParameters,
        }
      : undefined;

    return mgr.signinRedirect(params);
  }

  function signOut() {
    /** Redirect to the end session endpoint */
    return mgr.signoutRedirect();
  }

  async function signInCallback() {
    try {
      return await mgr.signinRedirectCallback();
    } catch (err) {
      logger.log(`${logPrefix}Signing in redirect failed:`, err);
      checkErrorAndReactOnClaimIssueTime({ error: (err as AuthError)?.message });

      return undefined;
    }
  }

  async function iframeSignin(additionalParameters?: SignAdditionalParameters) {
    /** Renew the token manually */
    try {
      const params = additionalParameters
        ? {
            extraQueryParams: additionalParameters,
          }
        : undefined;
      const user = await mgr.signinSilent(params);

      if (user === null) {
        signIn(additionalParameters);

        return undefined;
      }

      return user;
    } catch (error) {
      const { message, error: errorMsg, error_description: errorDescription } = (error ?? {}) as { message?: string; error?: string; error_description?: string };

      if ((message && AuthErrorNeedsLogin.includes(message)) || (errorMsg && AuthErrorNeedsLogin.includes(errorMsg))) {
        return undefined;
      }

      logger.log(`${logPrefix}Signing in silent failed:`, error, errorDescription);
      throw error;
    }
  }

  return {
    iframeSignin,
    signIn,
    signOut,
    signInCallback,
  };
};

const useOidcClientAuth = ({ config, eventCbs, logPrefix }: { config: OidcClientSettings; eventCbs: OIDCEventsCallbacks; logPrefix: string }) => {
  Log.logger = logger;
  Log.level = Log.WARN;
  const manager = createOIDCUserManager(config);

  manager.clearStaleState().catch((error) => logger.warn(`${logPrefix}Clearing state failed:`, error));

  setOIDCEvents(manager, eventCbs);

  const { iframeSignin, signIn, signOut, signInCallback } = setService(manager, logPrefix);

  return {
    iframeSignin,
    signIn,
    signInCallback,
    signOut,
  };
};

export default useOidcClientAuth;

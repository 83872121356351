const getBaseQuote = (market: string, separator = '_') => {
  const [base, quote] = market.split(separator) as [string, string];

  return {
    base,
    quote,
  };
};

export default getBaseQuote;

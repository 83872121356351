import { User } from 'oidc-client';

import { logger, nonProdConsoleInfo } from '@exchange/libs/utils/simple-logger/src';

import { AuthService, getCurrentClientTime, type SignAdditionalParameters } from './auth.service.common';
import useOidcClientAuth from './oidc';
import oidcSettings from './oidc/oidc.config';

export default class AuthServiceWeb extends AuthService {
  private getOIDCEventsCallbacks = () => {
    const addAccessTokenExpiring = () => {
      logger.info(`${this.logPrefix}Access token expiring`, getCurrentClientTime());

      this.triggerSilentAuthorizationInternal().catch((e) => {
        nonProdConsoleInfo(`${this.logPrefix}Silent attempt failed`, e);
      });
    };

    const addSilentRenewError = (error: Error) => {
      logger.info(`${this.logPrefix}Silent renewal error`, error, getCurrentClientTime());

      if (error.message === 'login_required') {
        this.resetAuth({
          isUserInteraction: false,
          unsubscribeAccountHistory: false,
        });
      }
    };

    const addAccessTokenExpired = (...args) => {
      logger.info(`${this.logPrefix}Access token expired`, ...args, getCurrentClientTime());
    };

    return {
      addSilentRenewError,
      addAccessTokenExpired,
      addAccessTokenExpiring,
    };
  };

  private OIDCService = useOidcClientAuth({
    config: oidcSettings,
    eventCbs: this.getOIDCEventsCallbacks(),
    logPrefix: this.logPrefix,
  });

  public signInInternal = async (additionalParameters?: SignAdditionalParameters): Promise<void> => {
    await this.OIDCService.signIn(additionalParameters);
    await new Promise((r) => {
      /** wait for redirect to happen */
      setTimeout(r, 1_000);
    });
  };

  private processUserInternal = async (user: User | undefined) => {
    if (!user) {
      return;
    }

    nonProdConsoleInfo(`${this.logPrefix}Account holder loaded： `, JSON.stringify(user.profile), `Token expires at ${user.expires_at} (in ${user.expires_in})`);

    await this.processAuthenticatedHolder({
      accessToken: user.access_token,
      idToken: user.id_token,
    });
  };

  public signInCallbackInternal = async (): Promise<void> => {
    const user = await this.OIDCService.signInCallback();

    await this.processUserInternal(user);
    this.isLoginProcess.value = false;
  };

  public triggerSilentAuthorizationInternal = async (additionalParameters?: SignAdditionalParameters) => {
    const user = await this.OIDCService.iframeSignin(additionalParameters);

    await this.processUserInternal(user);
  };

  public signOutInternal = async () => {
    await this.logoutFromFirebase();
    await this.OIDCService.signOut();
  };

  public isRefreshTokeExpired = () => {
    // not used in web
    logger.error(`${this.logPrefix}Method not implemented.`);

    return Promise.resolve(true);
  };

  public resetAuthInternal = () => Promise.resolve();
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { OidcClientSettings, UserManager, UserManagerEvents, WebStorageStateStore } from 'oidc-client';

export interface OIDCEventsCallbacks {
  /** Subscribe to events raised when user session has been established (or re-established) */
  addUserLoaded?: UserManagerEvents.UserLoadedCallback;
  removeUserLoaded?: UserManagerEvents.UserLoadedCallback;

  /** Subscribe to events raised when a user session has been terminated */
  addUserUnloaded?: UserManagerEvents.UserUnloadedCallback;
  removeUserUnloaded?: UserManagerEvents.UserUnloadedCallback;

  /** Subscribe to events raised prior to the access token expiring */
  addAccessTokenExpiring?: (...ev: any[]) => void;
  removeAccessTokenExpiring?: (...ev: any[]) => void;

  /** Subscribe to events raised after the access token has expired */
  addAccessTokenExpired?: (...ev: any[]) => void;
  removeAccessTokenExpired?: (...ev: any[]) => void;

  /** Subscribe to events raised when the automatic silent renew has failed */
  addSilentRenewError?: UserManagerEvents.SilentRenewErrorCallback;
  removeSilentRenewError?: UserManagerEvents.SilentRenewErrorCallback;

  /** Subscribe to events raised when the user's sign-in status at the OP has changed */
  addUserSignedOut?: UserManagerEvents.UserSignedOutCallback;
  removeUserSignedOut?: UserManagerEvents.UserSignedOutCallback;

  /** When `monitorSession` subscribe to events raised when the user session changed */
  addUserSessionChanged?: UserManagerEvents.UserSessionChangedCallback;
  removeUserSessionChanged?: UserManagerEvents.UserSessionChangedCallback;
}

export const createOIDCUserManager = (config: OidcClientSettings): UserManager =>
  new UserManager({
    userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    ...config,
  });

export const setOIDCEvents = (mgr: UserManager, cbs: OIDCEventsCallbacks) => {
  // User Loaded
  mgr.events.addUserLoaded((user) => {
    if (cbs.addUserLoaded) {
      cbs.addUserLoaded(user);
    }
  });
  mgr.events.removeUserLoaded((user) => {
    if (cbs.removeUserLoaded) {
      cbs.removeUserLoaded(user);
    }
  });

  // User Unloaded
  mgr.events.addUserUnloaded(() => {
    if (cbs.addUserUnloaded) {
      cbs.addUserUnloaded();
    }
  });
  mgr.events.removeUserUnloaded(() => {
    if (cbs.removeUserUnloaded) {
      cbs.removeUserUnloaded();
    }
  });

  // Token Expiring
  mgr.events.addAccessTokenExpiring((...args) => {
    if (cbs.addAccessTokenExpiring) {
      cbs.addAccessTokenExpiring(args);
    }
  });
  mgr.events.removeAccessTokenExpiring((...args) => {
    if (cbs.removeAccessTokenExpiring) {
      cbs.removeAccessTokenExpiring(args);
    }
  });

  // Token Expired
  mgr.events.addAccessTokenExpired((...args) => {
    if (cbs.addAccessTokenExpired) {
      cbs.addAccessTokenExpired(args);
    }
  });
  mgr.events.removeAccessTokenExpired((...args) => {
    if (cbs.removeAccessTokenExpired) {
      cbs.removeAccessTokenExpired(args);
    }
  });

  // Silent Renew Error
  mgr.events.addSilentRenewError((error: Error) => {
    if (cbs.addSilentRenewError) {
      cbs.addSilentRenewError(error);
    }
  });
  mgr.events.removeSilentRenewError((error: Error) => {
    if (cbs.removeSilentRenewError) {
      cbs.removeSilentRenewError(error);
    }
  });

  // User Signed Out
  mgr.events.addUserSignedOut(() => {
    if (cbs.addUserSignedOut) {
      cbs.addUserSignedOut();
    }

    mgr.removeUser().then(mgr.clearStaleState).then(mgr.signoutRedirect);
  });
  mgr.events.removeUserSignedOut(() => {
    if (cbs.removeUserSignedOut) {
      cbs.removeUserSignedOut();
    }
  });
};
